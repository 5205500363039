// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".QsrSidebar-module__sidebarTitle___1pcTo {\n  display: flex;\n  align-items: center;\n  justify-content: center; }\n", ""]);
// Exports
exports.locals = {
	"sidebarTitle": "QsrSidebar-module__sidebarTitle___1pcTo"
};
module.exports = exports;
