import {
  DiffOutlined,
  NotificationOutlined,
  UnorderedListOutlined,
  PushpinOutlined,
  PictureOutlined,
  StarOutlined,
  EnvironmentOutlined,
  HomeOutlined,
} from "@ant-design/icons";

import { MenuGroups, MenuItem } from "./types";

export const menuGroups: MenuGroups = {
  campaigns: { title: "Campaigns", icon: "C" },
  stores: { title: "Stores", icon: "S" },
  offerCreation: { title: "Offer Creation", icon: "O" },
};

export const homeMenuItem = {
  path: "/app",
  icon: <HomeOutlined />,
  text: "Home",
};

export const campaignMenuItems: MenuItem[] = [
  {
    path: "/app/create",
    icon: <NotificationOutlined />,
    text: "Create Campaign",
  },
  {
    path: "/app/manage",
    icon: <DiffOutlined />,
    text: "Manage Campaigns",
  },
  {
    path: "/app/map",
    icon: <EnvironmentOutlined />,
    text: "Live Map",
  },
];

export const storeMenuItems: MenuItem[] = [
  {
    path: "/app/create-store",
    icon: <PushpinOutlined />,
    text: "Create Store",
  },
  {
    path: "/app/stores",
    icon: <UnorderedListOutlined />,
    text: "View Stores",
  },
];

export const offerMenuItems: MenuItem[] = [
  {
    path: "/app/create-offer",
    icon: <StarOutlined />,
    text: "Create Offer",
  },
  {
    path: "/app/image-gallery",
    icon: <PictureOutlined />,
    text: "Image Gallery",
  },
];
