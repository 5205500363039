/* eslint-disable linebreak-style */
let DashboardAPI;
const env = process.env.TARGET_ENV;
if (env === "development") {
  DashboardAPI = "https://api.dev.ev.pebble.tech";
  // DashboardAPI = "https://portal.dev.2132qsr.com";
} else if (env === "staging") {
  // DashboardAPI = "https://portal.staging.2132qsr.com";
  DashboardAPI = "https://api.staging.ev.pebble.tech";
} else if (env === "production") {
  DashboardAPI = "https://portal.ev.pebble.tech";
} else if (env === "local") {
  DashboardAPI = "http://localhost:3002";
}

const config = {
  PACKAGE: "20.127.0-qsr-d",
  KEYS: {
    mapbox:
      "pk.eyJ1IjoiY2xvc2Vjb21tcyIsImEiOiJjajFuYWwwYXkwMDE0MzJvdHFpenU0dGV2In0.N_YdpyhxEKuSPu80uYgTYw",
    kepler:
      "pk.eyJ1IjoiMjEzMiIsImEiOiJja3NpenprcXkwZ3ZkMnBsNHBjcnhpNGdxIn0.BUQo6_52HZZE46gbLD6Nbg",
  },
  API: {
    dashboard: {
      baseUrl: DashboardAPI,
      auth: {
        login: `${DashboardAPI}/auth/dash`,
        checkEmail: `${DashboardAPI}/registration/user-validation/:accountEmail`,
        resetPassRequest: `${DashboardAPI}/registration/reset-password`,
        confirmNewPass: `${DashboardAPI}/registration/confirm/reset-password`,
        addNewUser: `${DashboardAPI}/registration/create-system-user`,
        refresh: `${DashboardAPI}/auth/dash/refresh`,
        category: `${DashboardAPI}/poi/categories/client`,
        me: `${DashboardAPI}/system-user/me`,
      },
      stores: {
        getClientStores: `${DashboardAPI}/stores`,
        getStore: `${DashboardAPI}/store`,
        createClientStore: `${DashboardAPI}/store`,
        createBusinessStore: `${DashboardAPI}/business-store`,
        getBusinessStore: `${DashboardAPI}/business-stores`,
        getBusinessStoreLocations: `${DashboardAPI}/business-store-locations`,
        editClientStore: `${DashboardAPI}/store`,
        toggleActiveClientStore: `${DashboardAPI}/store/update-status`,
        storesBySubgroup: `${DashboardAPI}/subgroups/all-stores`,
      },
      locations: {
        clientStores: `${DashboardAPI}/locations/all`,
        competitorStores: `${DashboardAPI}/poi/client/competitor/stores`,

        client: `${DashboardAPI}/locations/all`,
        find: `${DashboardAPI}/locations/getlocation`,
        select: `${DashboardAPI}/location/%ID%`,
        post: `${DashboardAPI}/location`,
        toggleStore: `${DashboardAPI}/locations/updatestatus`,
        toggleGroups: `${DashboardAPI}/groups/update-status`,
        toggleSubgroup: `${DashboardAPI}/subgroups/update-status`,
        openClose: `${DashboardAPI}/store/openingclosing/%ID%`,
        unpair: `${DashboardAPI}/unpair/location/%LOCATION_ID%/beacon/%BEACON_ID%`,
        activeGroups: `${DashboardAPI}/groups`,
        activeSubgroups: `${DashboardAPI}/group/%ID%/subgroups`,
        groups: `${DashboardAPI}/all/groups`,
        subgroups: `${DashboardAPI}/groups/all-subgroups`,
        storesBySubgroup: `${DashboardAPI}/subgroups/all-stores`,
        editSubgroup: `${DashboardAPI}/group/subgroup`,
        addSubgroup: `${DashboardAPI}/group/subgroup`,
        findGroup: `${DashboardAPI}/group`,
        findSubgroup: `${DashboardAPI}/subgroup/%ID%`,
        addGroup: `${DashboardAPI}/group`,
        countries: `${DashboardAPI}/common-data/countries`,
        timezones: `${DashboardAPI}/common-data/timezones`,
        fromGroups: `${DashboardAPI}/analytics/report/get-stores-for-group`,
        competitors: `${DashboardAPI}/client/competitor/stores`,
        usersInArea: `${DashboardAPI}/device-location/users-in-proximity`,
      },
      feedback: {
        get: `${DashboardAPI}/feedback`,
        reply: `${DashboardAPI}/feedback/%ID%/reply`,
        delete: `${DashboardAPI}/feedback/%ID%`,
      },
      analytics: {
        impressions: {
          combined: `${DashboardAPI}/impressions/combined?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
          ios: `${DashboardAPI}/impressions/ios?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
          android: `${DashboardAPI}/impressions/android?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
        },
        beacon: {
          campaigns: `${DashboardAPI}/beacon/campaignallstores/%DATE_FROM%/%DATE_TO%`,
          offerList: `${DashboardAPI}/beacon/clientoffers`,
          offer: `${DashboardAPI}/beacon/offerreportsbytype/%ID%/%DATE_FROM%/%DATE_TO%`,
        },
        competitors: {
          dwellByDay: `${DashboardAPI}/poi/reports/place`, // /poi/reports/place/:id/dwell?date_from=:date_from&date_to=:date_to
          store: {
            footfall: `${DashboardAPI}/poi/reports/place/location/%ID%/footfall?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
            dwellByDay: `${DashboardAPI}/poi/reports/place/location`, // /poi/reports/place/location/:id/dwell/?date_from=:date_from&date_to=:date_to
          },

          list: `${DashboardAPI}/poi/categories/places`,
          getCompetitorId: `${DashboardAPI}/poi/categories/competitor`,
          client: `${DashboardAPI}/poi/reports/competitor/client-summary?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
          footfall: `${DashboardAPI}/poi/reports/place/footfall?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%&placeIds=%PLACE_IDS%`,
          summary: `${DashboardAPI}/poi/reports/competitor/competitor-summary?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
        },
        poi: {
          dayDwellByCategory: `${DashboardAPI}/poi/reports/category`, // /poi/reports/category/:id/dwell/

          footfall: `${DashboardAPI}/poi/reports/place/location/%ID%/footfall?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
          places: `${DashboardAPI}/poi/categories/places`,
          visits: `${DashboardAPI}/poi/reports/category/stats/total_visits/%ORDER%/%LIMIT%?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
          dwell: `${DashboardAPI}/poi/reports/category/stats/average_dwell_time/%ORDER%/%LIMIT%?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
          dwellByDay: `${DashboardAPI}/poi/reports/category/%ID%/dwell/%DAY%?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
        },
        visits: `${DashboardAPI}/analytics/report/visitors?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
        devicesByBrand: `${DashboardAPI}/analytics/devices/brand?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
        usageByDay: `${DashboardAPI}/analytics/report/page-impressions?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
        installsByDay: `${DashboardAPI}/analytics/report/installs?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
        acquisition: `${DashboardAPI}/analytics/metrics/user-acquisition-attrition?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
        kpi: `${DashboardAPI}/analytics/report/overview/essential-kpi?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
        // poi: `${DashboardAPI}/poitypes?poi_type_count=0&date_from=%DATE_FROM%&date_to=%DATE_TO%`,
        heatmap: `${DashboardAPI}/device-location?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
      },
      campaign: {
        sendInstant: `${DashboardAPI}/offer/instant`,

        post: `${DashboardAPI}/campaigns`,
        timezones: `${DashboardAPI}/app-user/tz/timezones?country=%COUNTRY%`,
        timezonesByCountry: `${DashboardAPI}/app-user/tz/timezones`,
        countries: `${DashboardAPI}/app-user/tz/countries`,
        conversion: `${DashboardAPI}/analytics/report/campaign/conversion?date_from=%DATE_FROM%&date_to=%DATE_TO%`,
        redeemed: `${DashboardAPI}/analytics/report/campaign/all-sent-counts-by-date?date_from=%DATE_FROM%&date_to=%DATE_TO%&cid=0`,
        send: `${DashboardAPI}/campaign-offer/presence-offer-send-out`,
        fromStores: `${DashboardAPI}/analytics/report/get-campaigns-for-subgroups`,
        get: `${DashboardAPI}/analytics/report/campaign/sent-counts-by-date?date_from=%DATE_FROM%&date_to=%DATE_TO%&offer_type=1&cid=%ID%`,
        filteredCampaigns: `${DashboardAPI}/analytics/report/get-filtered-campaign-report-data/%DATE_FROM%/%DATE_TO%`,
        list: `${DashboardAPI}/campaign-offer/campaigns/all-status`,
        wizard: `${DashboardAPI}/campaign-offer/campaign`,
        wizardCampaignStatus: `${DashboardAPI}/campaign-offer/campaign/update-status`,
        allWizardCampaigns: `${DashboardAPI}/campaign-offer/campaigns`,
        deleteWizardCampaign: `${DashboardAPI}/campaign-offer/campaign/delete`,
        qc: {
          getAll: `${DashboardAPI}/campaign-offer/simple-wizard/all`,
          create: `${DashboardAPI}/campaign-offer/simple-wizard`,
        },
      },
      ev: {
        getChargerGroups: `${DashboardAPI}/ev/charger/groups`,
        getEvLocation: `${DashboardAPI}/ev/charger/groups`,
        editChargerGroup: `${DashboardAPI}/ev/charger/groups`,
        createChargerGroup: `${DashboardAPI}/ev/charger/groups`,
        toggleActiveChargerGroup: `${DashboardAPI}/ev/charger/groups/update-status`,
        evChargers: `${DashboardAPI}/ev/chargers`,
        startEvCharge: `${DashboardAPI}/ev/charger/session/start`,
        stopEvCharge: `${DashboardAPI}/ev/charger/session/stop`,
        getTopChargerVisits: `${DashboardAPI}/analytics/network/top-charger-visits?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%`,
        getChargerVisitsPerDay: `${DashboardAPI}/analytics/network/visits-per-day?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%&networks=%NETWORKS%`,
        getChargerDwellPerDay: `${DashboardAPI}/analytics/network/dwell-time-per-day?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%&networks=%NETWORKS%`,
        getNetworkChargers: `${DashboardAPI}/ev/network/all-brands`,
        getEvMapLocations: `${DashboardAPI}/ev/chargers/coordinates?lat=%LAT%&lng=%LNG%&radius=:radius`,
      },
      businessUser: {
        getUserPaidStatus: `${DashboardAPI}/registration/user-payment-status/:userId`,
        getBusinessUserDetails: `${DashboardAPI}/registration/business-user-details/:userEmail`,
        postPaymentIntent: `${DashboardAPI}/payment/create-intent`,
        postPaymentSession: `${DashboardAPI}/payment/create-checkout-session`,
        postLoginTime: `${DashboardAPI}/registration/save-login-session`,
        updatePassword: `${DashboardAPI}/registration/password`,
        editDetails: `${DashboardAPI}/registration/update-business-account-details`,
        getPaymentInfo: `${DashboardAPI}/payment/order?email=:systemUserEmail`,

        getImages: `${DashboardAPI}/media/images?type=:imageType`,
        deleteImage: `${DashboardAPI}/media/image`,
        generateImage: `${DashboardAPI}/media/image/generate`,

        postTempAccount: `${DashboardAPI}/registration/sales/business-account-user`,
      },
      wifi: {
        views: `${DashboardAPI}/analytics/wifi/views?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%`,
        connections: `${DashboardAPI}/analytics/wifi/connections?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%`,
        connexDates: `${DashboardAPI}/analytics/wifi/connections-per-day?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%`,
        viewDates: `${DashboardAPI}/analytics/wifi/views-per-day?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%`,
      },
      sales: {
        getStripeVouchers: `${DashboardAPI}/voucher/coupons`,
        getPromoCodes: `${DashboardAPI}/voucher/promo-codes`,
        postPromoRequest: `${DashboardAPI}/voucher/promo-code`,
        postStore: `${DashboardAPI}/sales/store`,
        getAllTypes: `${DashboardAPI}/main-type/all`,
        getKeyProducts: `${DashboardAPI}/key-product/by-main-type`,
        postNewProduct: `${DashboardAPI}/key-product`,
        getOfferPreview: `${DashboardAPI}/media/image/sales/generate-offer-preview`,
        postCampaignOffer: `${DashboardAPI}/sales/campaign/offer`,
      },
      bussinessAnalytics: {
        getAllBusinessAccounts: `${DashboardAPI}/analytics/business/active-account-names`,

        getAccountLoginsByDay: `${DashboardAPI}/analytics/business/logins-by-day?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%&businessId=:businessId`,
        getNewAccountsByDay: `${DashboardAPI}/analytics/business/new-registrations-per-day?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%`,
        getTopFiveBusiness: `${DashboardAPI}/analytics/business/top-active?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%`,
        getBottomFiveBusiness: `${DashboardAPI}/analytics/business/least-active?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%`,

        getNewAccounts: `${DashboardAPI}/analytics/business/new?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%`,
        getActiveAccounts: `${DashboardAPI}/analytics/business/active-accounts?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%`,
        getOffersSent: `${DashboardAPI}/analytics/business/offers-sent?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%`,

        getInactiveBusinesses: `${DashboardAPI}/analytics/business/inactive`,
        getRegisteredAccounts: `${DashboardAPI}/analytics/business/registered-accounts`,
        getActiveStores: `${DashboardAPI}/analytics/business/total-active-stores`,
        getLapsedAccounts: `${DashboardAPI}/analytics/business/lapsed`,
        getActiveCampaigns: `${DashboardAPI}/analytics/business/total-active-campaigns`,

        getDetailedRegAccounts: `${DashboardAPI}/analytics/business/registered-accounts-details`,
        getDetailedActiveStores: `${DashboardAPI}/analytics/business/stores-details`,
        getDetailedOfferSent: `${DashboardAPI}/analytics/business/offer-sent-details?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%`,
        getDetailedNewAccounts: `${DashboardAPI}/analytics/business/new-accounts-details?dateFrom=%DATE_FROM%&dateTo=%DATE_TO%`,
        getDetailedCampaigns: `${DashboardAPI}/analytics/business/active-campaigns-details`,
        getDetailedInactiveCampaigns: `${DashboardAPI}/analytics/business/inactive-campaigns-details`,

        getStoreDetails: `${DashboardAPI}/store-info/:storeId`,
      },
      homecard: {
        image: `${DashboardAPI}/homecard/image`,
        list: `${DashboardAPI}/homecards/all`,
        post: `${DashboardAPI}/homecard`,
      },
      offer: {
        getAll: `${DashboardAPI}/offers`,
        archived: `${DashboardAPI}/offers?archived=true`,
        visible: `${DashboardAPI}/offers?archived=false`,
        getOne: `${DashboardAPI}/offer`,
        active: `${DashboardAPI}/offer`, // offer/:id/active-toggle
        archive: `${DashboardAPI}/offer`, // offer/:id/archived-status

        all: `${DashboardAPI}/offers/all`,
        image: `${DashboardAPI}/media/image`,
        templateImage: `${DashboardAPI}/media/image?template=true`,
        post: `${DashboardAPI}/generaloffer`,
        list: {
          active: `${DashboardAPI}/offers/active`,
        },
        hidden: `${DashboardAPI}/offers/hidden`,
        current: `${DashboardAPI}/offers/all`,
        edit: `${DashboardAPI}/offer`,
        create: `${DashboardAPI}/offer`,
      },
    },
    beacon: {
      baseUrl: "https://portal.2132qsr.com",
    },
    app: {
      baseUrl: "https://api.2132qsr.com",
    },
  },
  THEME: {
    colors: {
      primary: "#009947",
      secondary: "#ffca08",
      success: "#66BB6A",
      info: "#01BCD4",
      infoAlt: "#948aec",
      warning: "#ffc53d",
      danger: "#941400",
      text: "#3D4051",
      gray: "#EDF0F1",
    },
    settings: {
      // Default states
      navExpanded: true,
      theme: "dark",
    },
  },
  CAMPAIGNS: {
    "Flash Deal": {
      enabled: true,
    },
    Competitor: {
      enabled: true,
    },
    "First Visit": {
      enabled: true,
    },
    Periodic: {
      enabled: true,
    },
    Rolling: {
      enabled: true,
    },
    Visits: {
      enabled: true,
    },
    Homecard: {
      enabled: true,
    },
    TYPES: {
      competitor: "Competitor",
      first_visit: "First Visit",
      visits: "Visits",
      rolling: "Rolling",
      scheduled: "Scheduled",
      homecard: "Homecard",
    },
    campaignSettings: {
      PROXIMITY_OPTIONS: {
        instore: "Venue",
        "100m": "100m",
        "200m": "200m",
        "300m": "300m",
        "400m": "400m",
        "500m": "500m",
      },
      RECURRING_MILESTONE: {
        recurring: "Recurring",
        milestone: "Milestone",
      },
      CAMPAIGN_DURATION: {
        campaign_duration: "Campaign Duration",
        all_time: "All Time",
      },
      PUSH_ON_FIRST_VISIT: {
        0: "No",
        1: "Yes",
      },
    },
    offerSettings: {
      TIME_FRAME: {
        minutes: "Minute(s)",
        hours: "Hour(s)",
        days: "Day(s)",
        weeks: "Week(s)",
        months: "Month(s)",
      },
    },
    target: {
      ENGAGEMENT: {
        push: "push",
        email: "email",
        sms: "sms",
        social: "social",
      },
      AGE_GROUPS: {
        all: "all",
        under_18: "under 18",
        "18_21": "18 - 21",
        over_21: "over 21",
      },
      DWELL_TIME: {
        all: "all",
        lt_10_min: "< 10 minutes",
        "10_20_min": "10 - 20 minutes",
        "20_30_min": "20 - 30 minutes",
        gt_30_min: "> 30 minutes",
      },
      WEEKLY_SPEND: {
        all: "all",
        lt_10_dollars: "< $10",
        "11_20_dollars": "$11 - $20",
        "21_30_dollars": "$21 - $30",
        gt_31_dollars: "> $31",
      },
    },
  },
  LOGOS: [
    "Applebees.png",
    "Arbys.png",
    "Board-and-Brew.png",
    "Boston-Market.png",
    "Boudin-Bakery.png",
    "Bronx-Sandwich-Company.png",
    "Bruxie.png",
    "Burger-King.png",
    "BurgerFi.png",
    "Beef-O-Bradys.png",
    "Carls-Junior.png",
    "Chick-fil-A.png",
    "Chipotle.png",
    "Corner-Bakery.png",
    "CloseComms-Demo.png",
    "CloseComms.png",
    "Dairy-Queen.png",
    "Del-Taco.png",
    "Dennys.png",
    "Dominos.png",
    "Dunkin-Donuts.png",
    "Earl-of-Sandwich.png",
    "Firehouse-Subs.png",
    "Five-Guys.png",
    "In-&-Out.png",
    "Jack-in-the-Box.png",
    "Jersey-Mikes.png",
    "Jimmy-Johns.png",
    "KFC.png",
    "McDonalds.png",
    "Moes-Southwest-Grill.png",
    "Nandos.png",
    "Nathans-Famous.png",
    "Panda-Express.png",
    "Panera-Bread.png",
    "Panini-Kabob-Grill.png",
    "Papa-Johns.png",
    "Phillys-Best.png",
    "Piadina.png",
    "Pizza-Hut.png",
    "Popeyes.png",
    "Portillos.png",
    "Quiznos.png",
    "Raising-Canes.png",
    "Sbarro.png",
    "Schlotzskys.png",
    "Shake-Shack.png",
    "Smash-Burger.png",
    "Sonic.png",
    "Starbucks.png",
    "Subway.png",
    "Taco-Bell.png",
    "Tesco-Test.png",
    "The-Hat.png",
    "The-Melt.png",
    "Tim-Hortons.png",
    "Togos.png",
    "Wendys.png",
    "Which-Which.png",
    "Wing-Shop.png",
  ],
  LOCALES: [
    {
      name: "English",
      iso: "en",
      locale: "en_GB",
      default: true,
      flag: "gb",
    },
    {
      name: "Hindi",
      iso: "hi",
      locale: "hi_IN",
      flag: "in",
    },
  ],
  GRAPH: {
    line: {
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        line: {
          fill: false,
          // tension: 0
        },
      },
      title: {
        display: false,
      },
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          scaleLabel: {
            display: false,
          },
          gridLines: {
            display: true,
          },
        },
        y: {
          display: true,
          type: "logarithmic",
          scaleLabel: {
            display: false,
          },
          ticks: {
            display: false,
            beginAtZero: true,
            mirror: false,
            suggestedMin: 0,
          },
          gridLines: {
            display: true,
          },
        },
      },
    },
    bar: {
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 20,
          bottom: 0,
        },
      },
      scales: {
        x: {
          scaleLabel: {
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
        y: {
          display: true,
          scaleLabel: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            mirror: false,
            suggestedMin: 0,
          },
          gridLines: {
            display: false,
          },
        },
      },
      responsive: true,
      title: {
        display: false,
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    },
    pie: {
      cutoutPercentage: 65,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
    },
    wordcloud: {
      colors: [
        "#1f77b4",
        "#ff7f0e",
        "#2ca02c",
        "#d62728",
        "#9467bd",
        "#8c564b",
      ],
      enableTooltip: true,
      deterministic: false,
      fontFamily: "arial",
      fontSizes: [5, 60],
      fontStyle: "normal",
      fontWeight: "normal",
      padding: 4,
      rotations: 0,
      scale: "sqrt",
      spiral: "archimedean",
      transitionDuration: 1000,
    },
  },
  MAP: {
    center: [51.5910314, -2.9924318], // Newport
    zoom: 10,
    url: "https://api.mapbox.com/styles/v1/closecomms/cjui3br6i1u661fmoid0sy1sz/tiles/256/{z}/{x}/{y}@2x?access_token=%TOKEN%",
    attr: "Maps provided by <a target='_blank' href='https://2132.io/'>2132.io</a>",
  },
  _cached: {
    // Dynamic variables for system use only
    BRANDS: false,
    MAP: false,
  },
};

export default config;
