import { FC } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";

// import styles from './Card.module.scss';

const Card: FC = ({ children, ...rest }) => {
  const { interfaceStore } = useStores();

  return (
    <section
      style={
        interfaceStore.theme === "light"
          ? {
              background: "var(--color-white)",
              borderRadius: "3px",
              padding: "1rem",
              position: "relative",
              width: "inhertit",
            }
          : {
              background: "var(--color-light-grey)",
              borderRadius: "3px",
              padding: "1rem",
              position: "relative",
              width: "inhertit",
            }
      }
      {...rest}
    >
      {children}
    </section>
  );
};
export default observer(Card);
