import { FC } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import QsrSidebar from "../QsrSidebar/QsrSidebar";
import QcSidebar from "../QcSidebar/QcSidebar";
import SalesSideBar from "../SalesSideBar/SalesSideBar";
import QcTempSidebar from "../QcTempSidebar/QcTempSidebar";

const SidebarSwitch: FC = () => {
  const { userStore } = useStores();

  let sidebar = null;

  switch (userStore.roleId) {
    case 1:
      sidebar = <QsrSidebar />;
      break;
    case 3:
      if (userStore.legitBusinessAcc === false) {
        sidebar = <QcTempSidebar />;
      } else {
        sidebar = <QcSidebar />;
      }
      break;
    case 5:
      sidebar = <SalesSideBar />;
      break;

    default:
      break;
  }

  return <>{sidebar}</>;
};

export default observer(SidebarSwitch);
