// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoginMainForm-module__errorMessage___pTQC6 {\n  padding: 0;\n  margin: 0;\n  padding-top: 20px;\n  display: flex;\n  text-align: center;\n  font-size: 0.8rem;\n  color: red; }\n", ""]);
// Exports
exports.locals = {
	"errorMessage": "LoginMainForm-module__errorMessage___pTQC6"
};
module.exports = exports;
