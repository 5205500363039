export const stringToColor = (string) => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
    // eslint-disable-next-line no-bitwise
    hash &= hash;
  }
  const shortened = hash % 360;
  const colorHSL = `hsl(${shortened},63%,53%)`;
  return colorHSL;
};

export const azSortByProp = (prop) => (a, b) => {
  if (a[`${prop}`] > b[`${prop}`]) {
    return 1;
  }
  return 0;
};

export const range = (x, z) => {
  const isNumbers = typeof x === "number" && typeof z === "number";
  const numbers = [];
  if (isNumbers) {
    for (let i = x; i <= z; i++) {
      numbers.push(i);
    }
  }
  return numbers;
};

export const getStringParams = (string) => {
  const arrIdx = string.indexOf("(");
  const params = string.slice(arrIdx).replace("(", "[");
  return JSON.parse(`${params.substring(0, params.length - 1)}]`);
};

export const capitalize = (str) => {
  str = str.split(" ");
  for (let i = 0, x = str.length; i < x; i++) {
    str[i] = str[i][0].toUpperCase() + str[i].substr(1);
  }
  return str.join(" ");
};

export const reduceSpecials = ({ string }) => {
  const spRegExp =
    /\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:|\s|\-/g;
  const xtRegExp = /\.[^/.]+$/;
  return string.replace(xtRegExp, "").replace(spRegExp, "").toLowerCase();
};

export const randomColor = () => {
  const hexLetters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += hexLetters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getImageDimensions = (file) =>
  new Promise((resolve, reject) => {
    const i = new Image();
    i.onload = () => {
      resolve({
        w: i.width,
        h: i.height,
      });
    };
    i.src = file;
  });

export const findMatchInArray = (array, value) => {
  const check = (arrayItem) => arrayItem === value;

  return array.some(check);
};

export const consoleLog = (arg) => {
  console.log(JSON.parse(JSON.stringify(arg)));
};

export const shallowCopy = (arg) => JSON.parse(JSON.stringify(arg));
