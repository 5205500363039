// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoginPassForm-module__errorMessage___ZghF4 {\n  padding: 0;\n  margin: 0;\n  padding-top: 20px;\n  display: flex;\n  text-align: center;\n  font-size: 0.8rem;\n  color: red; }\n\n.LoginPassForm-module__forgotPassContainer___1kiBs {\n  display: flex;\n  width: 100%;\n  justify-content: center;\n  align-items: center; }\n  .LoginPassForm-module__forgotPassContainer_msg___3zOOE {\n    color: var(--color-primary);\n    font-size: 0.85rem;\n    font-weight: 400;\n    margin: 0; }\n    .LoginPassForm-module__forgotPassContainer_msg___3zOOE:hover {\n      cursor: pointer; }\n", ""]);
// Exports
exports.locals = {
	"errorMessage": "LoginPassForm-module__errorMessage___ZghF4",
	"forgotPassContainer": "LoginPassForm-module__forgotPassContainer___1kiBs",
	"forgotPassContainer_msg": "LoginPassForm-module__forgotPassContainer_msg___3zOOE"
};
module.exports = exports;
