/* eslint-disable camelcase */
import config from "../../../../qsr.config";
import axios from "../../axios";
import { IAddNewUser, IConfirmNewPass, IResetPassRequest } from "./userTypes";

export const getEmailStatus = async (accountEmail: any) => {
  const url = config.API.dashboard.auth.checkEmail.replace(
    ":accountEmail",
    accountEmail
  );
  const { data }: { data: any } = await axios.get(url);

  return data;
};

// --------------- Queries ------------- //

export const postLogin = async (store: any) => {
  const url = config.API.dashboard.auth.login;

  return axios.post(url, store);
};
export const resetPassRequest = async (store: IResetPassRequest) => {
  const url = config.API.dashboard.auth.resetPassRequest;

  return axios.post(url, store);
};

export const confirmNewPass = async (store: IConfirmNewPass) => {
  const url = config.API.dashboard.auth.confirmNewPass;

  return axios.post(url, store);
};
export const addNewUser = async (store: IAddNewUser) => {
  const url = config.API.dashboard.auth.addNewUser;

  return axios.post(url, store);
};
