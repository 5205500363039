import { FC } from 'react';
import { Pagination, Space } from 'antd';

const Paginations: FC = () => (
  <Space size={16} direction="vertical">
    <Pagination
      total={85}
      showSizeChanger
      showQuickJumper
      showTotal={total => `Total ${total} items`}
    />

    <Pagination
      defaultCurrent={10}
      total={50}
    />

    <Pagination
      defaultCurrent={10}
      total={50}
      responsive
    />

    <Pagination
      defaultCurrent={10}
      total={50}
      disabled
    />
  </Space>
);

export default Paginations;
