import { FC } from "react";
import { Space, Spin } from "antd";

import Buttons from "./Buttons/Buttons";
import Paginations from "./Paginations/Paginations";
import Tables from "./Tables/Tables";
import Inputs from "./Inputs/Inputs";
import InputNumbers from "./InputNumbers/InputNumbers";
import Selects from "./Selects/Selects";
import Switches from "./Switches/Switches";
import Checkboxes from "./Checkboxes/Checkboxes";
import Radios from "./Radios/Radios";
import DatePickers from "./DatePickers/DatePickers";
import TimePickers from "./TimePickers/TimePickers";
import Forms from "./Forms/Forms";

const Styleguide: FC = () => (
  <>
    <Space direction="vertical" size={16}>
      <Buttons />

      <Paginations />

      <Tables />

      <Inputs />

      <InputNumbers />

      <Selects />

      <Spin size="large" />

      <Switches />

      <Checkboxes />

      <Radios />

      <DatePickers />

      <TimePickers />

      <Forms />
    </Space>
  </>
);

export default Styleguide;
