// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AddUser-module__header___3BJav {\n  padding-top: 25px;\n  margin-bottom: -10px; }\n\n.AddUser-module__formContainer___3DzN8 {\n  width: 100%;\n  padding-bottom: 5px; }\n\n.AddUser-module__section___2L_zZ {\n  width: 100%;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  grid-gap: 1rem; }\n\n.AddUser-module__messageContainerLight___NlwGk {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  font-size: 1.1rem;\n  font-weight: 400;\n  color: var(--color-light-black); }\n  .AddUser-module__messageContainerLight_msg___1-Riz {\n    width: 80%; }\n\n.AddUser-module__messageContainer___pRhpG {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  font-size: 1.1rem;\n  font-weight: 400;\n  color: var(--color-white); }\n  .AddUser-module__messageContainer_msg___3-9fp {\n    width: 80%; }\n", ""]);
// Exports
exports.locals = {
	"header": "AddUser-module__header___3BJav",
	"formContainer": "AddUser-module__formContainer___3DzN8",
	"section": "AddUser-module__section___2L_zZ",
	"messageContainerLight": "AddUser-module__messageContainerLight___NlwGk",
	"messageContainerLight_msg": "AddUser-module__messageContainerLight_msg___1-Riz",
	"messageContainer": "AddUser-module__messageContainer___pRhpG",
	"messageContainer_msg": "AddUser-module__messageContainer_msg___3-9fp"
};
module.exports = exports;
