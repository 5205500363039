/* eslint-disable quotes */
import { makeAutoObservable, runInAction } from "mobx";
import moment from "moment";
import axios from "../../utils/axios";
import { Request, responseFail, configStore } from "../index";
import formatDate from "../../utils/dateTime/formatDate";
import { randomColor } from "../../utils";
import presetDates from "../../utils/dateTime/presetDates";

/**
 * @description Fetch & Store - Competitors, users, app & other general analytics
 */
class AnalyticsStore {
  constructor(rootStore) {
    makeAutoObservable(this);

    this.rootStore = rootStore;
  }

  acquisition = {
    date: null,
    stats: null,
    loading: true,
  };

  essentialTiles = {
    date: null,
    stats: null,
    error: null,
    loading: false,
  };

  pointsOfInterest = {
    visits: {
      cloud: {
        loading: true,
        date: null,
        stats: null,
      },
      top: {
        loading: true,
        date: null,
        stats: null,
      },
      bottom: {
        loading: true,
        date: null,
        stats: null,
      },
    },
    dwell: {
      cloud: {
        loading: true,
        date: null,
        stats: null,
      },
      top: {
        loading: true,
        date: null,
        stats: null,
      },
      bottom: {
        loading: true,
        date: null,
        stats: null,
      },
    },
  };

  dwellByDay = {
    date: null,
    stats: null,
    id: null,
    name: null,
    loading: false,
    error: false,
  };

  installsByDay = {
    date: null,
    stats: null,
    labels: null,
    loading: true,
  };

  visitsInstalls = {
    date: null,
    data: null,
    loading: true,
  };

  usageByDay = {
    loading: true,
    date: null,
    labels: null,
  };

  devicesByBrand = {
    date: null,
    stats: null,
    loading: true,
  };

  impressionStats = {
    ios: {
      loading: true,
      date: null,
      stats: null,
    },
    android: {
      loading: true,
      date: null,
      stats: null,
    },
    combined: {
      loading: true,
      stats: null,
      date: null,
    },
  };

  competitorTiles = {
    date: null,
    stats: null,
    me: null,
    loading: false,
    error: null,
  };

  competitorDwell = {
    loading: true,
    competitor: {
      id: null,
      stats: null,
    },
    date: null,
    client: {
      id: null,
      stats: null,
    },
  };

  competitorFootfall = {
    loading: true,
    competitor: {
      id: null,
      stats: null,
    },
    date: null,
    client: {
      id: null,
      stats: null,
    },
  };

  manyCompetitorFootfall = {
    date: null,
    stats: null,
    loading: false,
    select: {
      options: null,
      selected: null,
    },
  };

  competitorStoreFootfall = {
    loading: true,
    competitor: {
      id: null,
      store: null,
      stats: null,
    },
    date: null,
    client: {
      id: null,
      store: null,
      stats: null,
    },
  };

  competitorStoreDwell = {
    loading: true,
    competitor: {
      id: null,
      stats: null,
    },
    date: null,
    client: {
      id: null,
      stats: null,
    },
  };

  masterDate = presetDates.default;

  setMasterDate = (dates) => {
    this.masterDate = dates;
  };

  getKPI = async (dateFrom, dateTo) => {
    const url = this.rootStore.configStore.API.dashboard.analytics.kpi
      .replace("%DATE_FROM%", formatDate(dateFrom))
      .replace("%DATE_TO%", formatDate(dateTo));
    this.essentialTiles.error = null;

    try {
      this.essentialTiles.loading = true;

      const response = await axios.get(url);

      runInAction(() => {
        const stats = response.data;
        this.essentialTiles.stats = stats;
        this.essentialTiles.date = [dateFrom, dateTo];
        this.essentialTiles.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.essentialTiles.loading = false;
        const statusCode = error.response.data.statusCode;
        const message = error.response.data.message;
        this.essentialTiles.error = {
          statusCode,
          message,
        };

        throw new Error(this.essentialTiles.error);
      });
    }
  };

  *getPoiCloud({ dateFrom, dateTo, refresh = false, type }) {
    if (this.pointsOfInterest[type].cloud.stats && !refresh) {
      return this.pointsOfInterest[type].cloud;
    }
    this.pointsOfInterest[type].cloud.loading = true;
    const url = configStore.API.dashboard.analytics.poi[type]
      .replace("%LIMIT%", 60)
      .replace("%ORDER%", "desc")
      .replace("%DATE_FROM%", formatDate(dateFrom))
      .replace("%DATE_TO%", formatDate(dateTo));
    const res = yield Request(url);
    if (responseFail(res)) {
      throw {
        statusCode: res.statusCode || 400,
      };
    }
    this.pointsOfInterest[type].cloud.date = [dateFrom, dateTo];
    this.pointsOfInterest[type].cloud.stats = res;

    this.pointsOfInterest[type].cloud.loading = false;
    return this.pointsOfInterest[type].cloud;
  }

  *getTopPoi({ dateFrom, dateTo, refresh = false, type }) {
    if (this.pointsOfInterest[type].top.stats && !refresh) {
      return this.pointsOfInterest[type].top;
    }
    this.pointsOfInterest[type].top.loading = true;

    const url = configStore.API.dashboard.analytics.poi[type]
      .replace("%LIMIT%", 5)
      .replace("%ORDER%", "desc")
      .replace("%DATE_FROM%", formatDate(dateFrom))
      .replace("%DATE_TO%", formatDate(dateTo));
    const res = yield Request(url);
    if (responseFail(res)) {
      throw {
        statusCode: res.statusCode || 400,
      };
    }
    this.pointsOfInterest[type].top.date = [dateFrom, dateTo];
    this.pointsOfInterest[type].top.stats = res;
    this.pointsOfInterest[type].top.loading = false;

    return this.pointsOfInterest[type].top;
  }

  *getBottomPoi({ dateFrom, dateTo, refresh = false, type }) {
    if (this.pointsOfInterest[type].bottom.stats && !refresh) {
      return this.pointsOfInterest[type].bottom;
    }
    this.pointsOfInterest[type].bottom.loading = true;

    const url = configStore.API.dashboard.analytics.poi[type]
      .replace("%LIMIT%", 5)
      .replace("%ORDER%", "asc")
      .replace("%DATE_FROM%", formatDate(dateFrom))
      .replace("%DATE_TO%", formatDate(dateTo));
    const res = yield Request(url);
    if (responseFail(res)) {
      throw {
        statusCode: res.statusCode || 400,
      };
    }
    this.pointsOfInterest[type].bottom.date = [dateFrom, dateTo];
    this.pointsOfInterest[type].bottom.stats = res;
    this.pointsOfInterest[type].bottom.loading = false;

    return this.pointsOfInterest[type].bottom;
  }

  *getCompetitorDwell({
    id,
    day,
    dateFrom,
    dateTo,
    force = false,
    refresh = false,
  }) {
    const cachedId = this.competitorDwell.competitor.id;
    if (cachedId && id === cachedId && !force) {
      return this.competitorDwell;
    }
    if (refresh) {
      this.competitorDwell.competitor.stats = [];
      this.competitorDwell.loading = true;
    }
    const url = configStore.API.dashboard.analytics.competitors.dwellByDay
      .replace("%ID%", id)
      .replace("%DAY%", day)
      .replace("%DATE_FROM%", formatDate(dateFrom))
      .replace("%DATE_TO%", formatDate(dateTo));
    const res = yield Request(url);
    if (responseFail(res)) {
      throw {
        statusCode: res.statusCode || 400,
      };
    }
    const entry = {
      day,
      data: res,
    };
    if (!this.competitorDwell.competitor.stats) {
      this.competitorDwell.competitor.stats = [entry];
    } else {
      const idx = this.competitorDwell.competitor.stats.findIndex(
        (stat) => stat.day === day
      );
      idx === -1
        ? this.competitorDwell.competitor.stats.push(entry)
        : (this.competitorDwell.competitor.stats[idx] = entry);
    }
    this.competitorDwell.competitor.id = id;
    this.competitorDwell.date = [dateFrom, dateTo];
    this.competitorDwell.loading = false;
  }

  popCompetitorDwell = ({ day }) => {
    if (!Array.isArray(this.competitorDwell.competitor.stats)) {
      throw {
        statusCode: 422,
      };
    }
    this.competitorDwell.competitor.stats =
      this.competitorDwell.competitor.stats.filter((stat) => stat.day !== day);
  };

  *getPoiByDwell({ dateFrom, dateTo, refresh = false }) {
    if (this.pointsOfInterest.dwell.cloud.stats && !refresh) {
      return this.pointsOfInterest.dwell.cloud;
    }
    const url = configStore.API.dashboard.analytics.poi.dwell
      .replace("%LIMIT%", 60)
      .replace("%ORDER%", "desc")
      .replace("%DATE_FROM%", formatDate(dateFrom))
      .replace("%DATE_TO%", formatDate(dateTo));
    const res = yield Request(url);
    if (responseFail(res)) {
      throw {
        statusCode: res.statusCode || 400,
      };
    }
    this.pointsOfInterest.dwell.cloud.date = [dateFrom, dateTo];
    this.pointsOfInterest.dwell.cloud.stats = res;
    return this.pointsOfInterest.dwell.cloud;
  }

  popDwellByDay = ({ day }) => {
    if (!Array.isArray(this.dwellByDay.stats)) {
      throw {
        statusCode: 422,
      };
    }
    this.dwellByDay.stats = this.dwellByDay.stats.filter(
      (stat) => stat.day !== day
    );
  };

  *getDwellByDay({
    id,
    name = null,
    day,
    dateFrom,
    dateTo,
    loading = false,
    refresh = false,
  }) {
    if (refresh) {
      this.dwellByDay.stats = [];
    }
    this.dwellByDay.loading = loading;
    const url = configStore.API.dashboard.analytics.poi.dwellByDay
      .replace("%ID%", id)
      .replace("%DAY%", day)
      .replace("%DATE_FROM%", formatDate(dateFrom))
      .replace("%DATE_TO%", formatDate(dateTo));
    const res = yield Request(url);
    if (responseFail(res)) {
      this.dwellByDay.loading = false;
      this.dwellByDay.error = true;
      throw {
        statusCode: res.statusCode || 400,
      };
    }
    const entry = {
      day,
      data: res,
    };
    if (!this.dwellByDay.stats) this.dwellByDay.stats = [entry];
    else {
      const idx = this.dwellByDay.stats.findIndex((stat) => stat.day === day);
      idx === -1
        ? this.dwellByDay.stats.push(entry)
        : (this.dwellByDay.stats[idx] = entry);
    }
    this.dwellByDay.id = id;
    this.dwellByDay.name = name;
    this.dwellByDay.date = [dateFrom, dateTo];
    this.dwellByDay.loading = false;
    this.dwellByDay.error = false;
    return this.dwellByDay;
  }

  *getOneCompetitorFootfall({
    dateFrom,
    dateTo,
    clientId,
    competitorId,
    refresh = false,
  }) {
    this.competitorFootfall.loading = true;
    const url = configStore.API.dashboard.analytics.competitors.footfall
      .replace("%DATE_FROM%", formatDate(dateFrom))
      .replace("%DATE_TO%", formatDate(dateTo));
    const urlCompetitor = url.replace("%ID%", competitorId);
    const urlMe = url.replace("%ID%", clientId);
    const res = yield Promise.all([Request(urlCompetitor), Request(urlMe)]);
    if (responseFail(res)) {
      throw {
        statusCode: res.statusCode || 400,
      };
    }
    res.forEach((r) => {
      if (responseFail(r)) {
        throw {
          statusCode: res.statusCode || 400,
        };
      }
    });

    this.competitorFootfall.competitor.stats = res[0];
    this.competitorFootfall.competitor.id = competitorId;
    this.competitorFootfall.client.stats = res[1];
    this.competitorFootfall.client.id = competitorId;
    this.competitorFootfall.date = [dateFrom, dateTo];
    this.competitorFootfall.loading = false;
  }

  getManyCompetitorFootfall = async ({ dateFrom, dateTo, selected }) => {
    if (!selected || (Array.isArray(selected) && !selected.length)) {
      this.manyCompetitorFootfall.stats = [];
      this.manyCompetitorFootfall.select.selected = [];
      return;
    }

    this.manyCompetitorFootfall.loading = true;
    this.manyCompetitorFootfall.select.selected = selected;
    this.manyCompetitorFootfall.stats = [];

    // parameter for new date, force a new fetch for each select
    await Promise.all(
      selected.map(async (competitor) => {
        const url = configStore.API.dashboard.analytics.competitors.footfall
          .replace("%ID%", competitor)
          .replace("%DATE_FROM%", formatDate(dateFrom))
          .replace("%DATE_TO%", formatDate(dateTo));

        const res = await Request(url);

        runInAction(() => {
          if (responseFail(res)) {
            throw {
              statusCode: res.statusCode || 400,
            };
          }

          res[0] = {
            ...res[0],
            color: randomColor(),
            id: competitor.value,
            name: competitor.label,
          };

          this.manyCompetitorFootfall.stats.push(res);
        });
      })
    );

    runInAction(() => {
      this.manyCompetitorFootfall.date = [dateFrom, dateTo];
      this.manyCompetitorFootfall.loading = false;
    });
  };

  *getCompetitorStoreFootfall({
    dateFrom,
    dateTo,
    clientId = null,
    competitorId = null,
    refresh = false,
  }) {
    const hasCompetitorReq =
      (competitorId &&
        competitorId !== this.competitorStoreFootfall.competitor.id) ||
      (competitorId && refresh);
    const hasClientReq =
      (clientId && clientId !== this.competitorStoreFootfall.client.id) ||
      (clientId && refresh);

    this.competitorStoreFootfall.loading = true;
    const url = configStore.API.dashboard.analytics.competitors.store.footfall
      .replace("%DATE_FROM%", formatDate(dateFrom))
      .replace("%DATE_TO%", formatDate(dateTo));
    let compRes;
    let clientRes;
    if (hasCompetitorReq) {
      const urlCompetitor = url.replace("%ID%", competitorId);
      compRes = yield Request(urlCompetitor);
    }
    if (hasClientReq) {
      const urlMe = url.replace("%ID%", clientId);
      clientRes = yield Request(urlMe);
    }
    if (
      (clientRes && responseFail(clientRes)) ||
      (compRes && responseFail(compRes))
    ) {
      throw {
        statusCode: 400,
      };
    }
    if (hasCompetitorReq) {
      this.competitorStoreFootfall.competitor.stats = compRes;
      this.competitorStoreFootfall.competitor.id = competitorId;
    }
    if (hasClientReq) {
      this.competitorStoreFootfall.client.stats = clientRes;
      this.competitorStoreFootfall.client.id = clientId;
    }
    this.competitorStoreFootfall.date = [dateFrom, dateTo];
    this.competitorStoreFootfall.loading = false;
    return this.competitorStoreFootfall;
  }

  *getCompetitorStoreDwell({
    competitorId = null,
    clientId = null,
    day,
    dateFrom,
    dateTo,
    force = false,
    refresh = false,
  }) {
    const hasCompetitorReq =
      (competitorId &&
        competitorId !== this.competitorStoreDwell.competitor.id) ||
      (competitorId && force);

    const hasClientReq =
      (clientId && clientId !== this.competitorStoreDwell.client.id) ||
      (clientId && force);

    if (refresh) {
      this.competitorStoreDwell.loading = true;
    }
    const url = configStore.API.dashboard.analytics.competitors.store.dwellByDay
      .replace("%DATE_FROM%", formatDate(dateFrom))
      .replace("%DATE_TO%", formatDate(dateTo));

    let compRes;
    let clientRes;

    if (hasCompetitorReq) {
      const compUrl = url.replace("%ID%", competitorId).replace("%DAY%", day);
      compRes = yield Request(compUrl);
      const entry = {
        day,
        data: compRes,
      };
      if (!this.competitorStoreDwell.competitor.stats || refresh) {
        this.competitorStoreDwell.competitor.stats = [entry];
      } else {
        const idx = this.competitorStoreDwell.competitor.stats.findIndex(
          (stat) => stat.day === day
        );
        idx === -1
          ? this.competitorStoreDwell.competitor.stats.push(entry)
          : (this.competitorStoreDwell.competitor.stats[idx] = entry);
      }
      this.competitorStoreDwell.competitor.id = competitorId;
    }

    if (hasClientReq) {
      const clientUrl = url.replace("%ID%", clientId).replace("%DAY%", day);
      clientRes = yield Request(clientUrl);
      const entry = {
        day,
        data: clientRes,
      };
      if (!this.competitorStoreDwell.client.stats || refresh) {
        this.competitorStoreDwell.client.stats = [entry];
      } else {
        const idx = this.competitorStoreDwell.client.stats.findIndex(
          (stat) => stat.day === day
        );
        idx === -1
          ? this.competitorStoreDwell.client.stats.push(entry)
          : (this.competitorStoreDwell.client.stats[idx] = entry);
      }
      this.competitorStoreDwell.client.id = clientId;
    }

    if (
      (compRes && responseFail(compRes)) ||
      (clientRes && responseFail(clientRes))
    ) {
      throw {
        statusCode: 400,
      };
    }
    this.competitorStoreDwell.date = [dateFrom, dateTo];
    this.competitorStoreDwell.loading = false;
    return this.competitorStoreDwell;
  }

  popCompetitorStoreDwell({ day }) {
    const compStats = this.competitorStoreDwell.competitor.stats;
    const clientStats = this.competitorStoreDwell.client.stats;
    if (Array.isArray(compStats)) {
      this.competitorStoreDwell.competitor.stats = compStats.filter(
        (stat) => stat.day !== day
      );
    }
    if (Array.isArray(clientStats)) {
      this.competitorStoreDwell.client.stats = clientStats.filter(
        (stat) => stat.day !== day
      );
    }
  }

  getCompetition = async (dateFrom, dateTo) => {
    const urlCompetitors =
      this.rootStore.configStore.API.dashboard.analytics.competitors.summary
        .replace("%DATE_FROM%", formatDate(dateFrom))
        .replace("%DATE_TO%", formatDate(dateTo));

    const urlMe =
      this.rootStore.configStore.API.dashboard.analytics.competitors.client
        .replace("%DATE_FROM%", formatDate(dateFrom))
        .replace("%DATE_TO%", formatDate(dateTo));

    this.competitorTiles.error = null;

    try {
      this.competitorTiles.loading = true;
      const response = await Promise.all([
        axios.get(urlCompetitors),
        axios.get(urlMe),
      ]);

      runInAction(() => {
        const [competitorsResponse, meResponse] = response;
        const competitorsList = competitorsResponse.data;
        const client = meResponse.data;

        this.manyCompetitorFootfall.select.selected = competitorsList.map(
          (brand) => ({
            value: brand.competitorId,
            label: brand.name,
          })
        );

        this.competitorTiles.stats = competitorsList.sort(
          (x, y) => y.visitCount - x.visitCount
        );
        this.competitorTiles.me = client;
        this.competitorTiles.date = [dateFrom, dateTo];
        this.competitorTiles.loading = false;

        return null;
      });
    } catch (error) {
      runInAction(() => {
        this.competitorTiles.loading = false;
        const statusCode = error.response.data.statusCode;
        const message = error.response.data.message;
        this.competitorTiles.error = {
          statusCode,
          message,
        };

        throw new Error(this.competitorTiles.error);
      });
    }
  };

  *getAcquisition({ dateFrom, dateTo, refresh = false }) {
    if (this.acquisition.stats && !refresh) return this.acquisition;
    this.acquisition.loading = true;
    const url = configStore.API.dashboard.analytics.acquisition
      .replace("%DATE_FROM%", formatDate(dateFrom))
      .replace("%DATE_TO%", formatDate(dateTo));
    const res = yield Request(url);
    if (responseFail(res)) {
      throw {
        statusCode: res.statusCode || 400,
      };
    }
    this.acquisition.stats = res;
    this.acquisition.date = [dateFrom, dateTo];
    this.acquisition.loading = false;
    return this.acquisition;
  }

  *getInstallsByDay({ dateFrom, dateTo, refresh = false }) {
    if (this.installsByDay.stats && !refresh) return this.installsByDay;

    this.installsByDay.loading = true;
    const url = configStore.API.dashboard.analytics.installsByDay
      .replace("%DATE_FROM%", formatDate(dateFrom))
      .replace("%DATE_TO%", formatDate(dateTo));
    const res = yield Request(url);
    if (responseFail(res)) {
      throw {
        statusCode: res.statusCode || 400,
      };
    }
    this.installsByDay.stats = res.map((data) => parseInt(data.installs));
    this.installsByDay.labels = res.map((data) =>
      moment(data.date).format("ll")
    );
    this.installsByDay.date = [dateFrom, dateTo];
    this.installsByDay.loading = false;
    return this.installsByDay;
  }

  *getImpressionsCombined({ dateFrom, dateTo, refresh = false }) {
    if (this.impressionStats.combined.stats && !refresh) {
      return this.impressionStats.combined;
    }

    this.impressionStats.combined.loading = true;
    const url = configStore.API.dashboard.analytics.impressions.combined
      .replace("%DATE_FROM%", formatDate(dateFrom))
      .replace("%DATE_TO%", formatDate(dateTo));
    const res = yield Request(url);
    if (responseFail(res)) {
      throw {
        statusCode: res.statusCode || 400,
      };
    }

    this.impressionStats.combined.stats = res;
    this.impressionStats.combined.date = [dateFrom, dateTo];
    this.impressionStats.combined.loading = false;
    return this.impressionStats.combined;
  }

  *getImpressionsIos({ dateFrom, dateTo, refresh = false }) {
    if (this.impressionStats.ios.stats && !refresh) {
      return this.impressionStats.ios;
    }
    this.impressionStats.ios.loading = true;
    const url = configStore.API.dashboard.analytics.impressions.ios
      .replace("%DATE_FROM%", formatDate(dateFrom))
      .replace("%DATE_TO%", formatDate(dateTo));
    const res = yield Request(url);
    if (responseFail(res)) {
      throw {
        statusCode: res.statusCode || 400,
      };
    }
    this.impressionStats.ios.stats = res;
    this.impressionStats.ios.date = [dateFrom, dateTo];
    this.impressionStats.ios.loading = false;
    return this.impressionStats.ios;
  }

  *getImpressionsAndroid({ dateFrom, dateTo, refresh = false }) {
    if (this.impressionStats.android.stats && !refresh) {
      return this.impressionStats.android;
    }
    this.impressionStats.android.loading = true;
    const url = configStore.API.dashboard.analytics.impressions.android
      .replace("%DATE_FROM%", formatDate(dateFrom))
      .replace("%DATE_TO%", formatDate(dateTo));
    const res = yield Request(url);
    if (responseFail(res)) {
      throw {
        statusCode: res.statusCode || 400,
      };
    }
    this.impressionStats.android.stats = res;
    this.impressionStats.android.date = [dateFrom, dateTo];
    this.impressionStats.android.loading = false;
    return this.impressionStats.android;
  }

  *getUsageByDay({ dateFrom, dateTo, refresh = false }) {
    if (this.usageByDay.stats && !refresh) return this.usageByDay;
    this.usageByDay.loading = true;
    const url = configStore.API.dashboard.analytics.usageByDay
      .replace("%DATE_FROM%", formatDate(dateFrom))
      .replace("%DATE_TO%", formatDate(dateTo));
    const res = yield Request(url);
    if (responseFail(res)) {
      throw {
        statusCode: res.statusCode || 400,
      };
    }
    this.usageByDay.stats = res;
    this.usageByDay.date = [dateFrom, dateTo];
    this.usageByDay.loading = false;
    return this.usageByDay;
  }

  *getDevicesByBrand({ dateFrom, dateTo, refresh = false }) {
    if (this.devicesByBrand.stats && !refresh) return this.devicesByBrand;
    this.devicesByBrand.loading = true;
    const url = configStore.API.dashboard.analytics.devicesByBrand
      .replace("%DATE_FROM%", formatDate(dateFrom))
      .replace("%DATE_TO%", formatDate(dateTo));
    const res = yield Request(url);
    if (responseFail(res)) {
      throw {
        statusCode: res.statusCode || 400,
      };
    }
    this.devicesByBrand.stats = res;
    this.devicesByBrand.date = [dateFrom, dateTo];
    this.devicesByBrand.loading = false;
    return this.devicesByBrand;
  }

  *getVisitsInstalls({ dateFrom, dateTo, refresh = false }) {
    if (this.visitsInstalls.stats && !refresh) return this.visitsInstalls;
    this.visitsInstalls.loading = true;
    const url = configStore.API.dashboard.analytics.visitsInstalls
      .replace("%DATE_FROM%", formatDate(dateFrom))
      .replace("%DATE_TO%", formatDate(dateTo));
    const res = yield Request(url);
    if (responseFail(res) || (Array.isArray(res) && !res.length)) {
      throw {
        statusCode: res.statusCode || 400,
      };
    }
    this.visitsInstalls.data = res;
    this.visitsInstalls.date = [dateFrom, dateTo];
    this.visitsInstalls.loading = false;
    return this.visitsInstalls;
  }
}

export default AnalyticsStore;
