// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoginActivate-module__messageContainer___3NYh5 {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  font-size: 1rem;\n  font-weight: 300;\n  width: 90%;\n  padding-bottom: 10px; }\n  .LoginActivate-module__messageContainer_message___2ldzR {\n    text-align: center;\n    width: 480px; }\n", ""]);
// Exports
exports.locals = {
	"messageContainer": "LoginActivate-module__messageContainer___3NYh5",
	"messageContainer_message": "LoginActivate-module__messageContainer_message___2ldzR"
};
module.exports = exports;
