import { FC } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../hooks/useStores";
import styles from "./ColorOptions.module.scss";
import { useTranslation } from "react-i18next";

const ColorOptions: FC = () => {
  const { interfaceStore } = useStores();

  const { t } = useTranslation();

  return (
    <div className={styles.dropDown}>
      {t("header.lightMode")}
      <div
        className={styles.lightModeBox}
        onClick={() => interfaceStore.setTheme("light")}
        onKeyDown={() => interfaceStore.setTheme("light")}
        role="presentation"
      />
      {t("header.darkMode")}
      <div
        className={styles.darkModeBox}
        onClick={() => interfaceStore.setTheme("dark")}
        onKeyDown={() => interfaceStore.setTheme("dark")}
        role="presentation"
      />
    </div>
  );
};

export default observer(ColorOptions);
