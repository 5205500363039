import React from 'react';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';

import {Collapse} from 'antd';

const ErrorModalContent = ({error}) => {
  const {Panel} = Collapse;

  return (
    <article className="error-modal__description">
      <div className="error-modal__accordion-container">
        <Collapse
          bordered={false}
          className="error-modal__accordion"
        >
          <Panel
            header={error.name}
            key="1"
            className="error-modal__accordion-panel"
          >
            <pre className="error-modal__pre">
              <code>
                {JSON.stringify(error.message, undefined, 2)}
              </code>
            </pre>
          </Panel>
        </Collapse>
      </div>
    </article>
  );
};

ErrorModalContent.propTypes = {
  error: PropTypes.object,
};

export default observer(ErrorModalContent);
