import { FC } from "react";
import { observer } from "mobx-react";

import { Menu } from "antd";
import { Link } from "react-router-dom";
import {
  BankOutlined,
  EnvironmentOutlined,
  FileAddOutlined,
  HomeOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import { useStores } from "../../../hooks/useStores";
import styles from "./SalesSideBar.module.scss";

const SalesSideBar: FC = () => {
  const { interfaceStore } = useStores();
  const menuTheme = interfaceStore.theme === "light" ? "light" : "dark";

  return (
    <Menu theme={menuTheme} mode="inline">
      <Menu.Item key="/app">
        <Link to="/app">
          <HomeOutlined />

          <span className="nav-text">Home</span>
        </Link>
      </Menu.Item>

      <Menu.ItemGroup
        title={
          interfaceStore.navActive ? (
            "Tools"
          ) : (
            <div className={styles.sidebarTitle}>T</div>
          )
        }
      />

      <Menu.Item key="/app/tools/create-promo">
        <Link to="/app/tools/create-promo">
          <NotificationOutlined />

          <span className="nav-text">Create Promo Code</span>
        </Link>
      </Menu.Item>

      <Menu.Item key="/app/tools/add-business">
        <Link to="/app/tools/add-business">
          <FileAddOutlined />

          <span className="nav-text">Add Business</span>
        </Link>
      </Menu.Item>

      <Menu.ItemGroup
        title={
          interfaceStore.navActive ? (
            "Analytics"
          ) : (
            <div className={styles.sidebarTitle}>A</div>
          )
        }
      />

      <Menu.Item key="/app/analytics/business/business-stats">
        <Link to="/app/analytics/business/business-stats">
          <BankOutlined />

          <span className="nav-text">Business Stats</span>
        </Link>
      </Menu.Item>

      <Menu.Item key="/app/analytics/business/store-map">
        <Link to="/app/analytics/business/store-map">
          <EnvironmentOutlined />

          <span className="nav-text">Live Map</span>
        </Link>
      </Menu.Item>
    </Menu>
  );
};

export default observer(SalesSideBar);
