/* eslint-disable */
import loadable from '@loadable/component';


const QcTempHome = loadable(() => import('../pages/qc/QcTempHome/QcTempHome'));


export const qcTempRoutes = [
  { path: '/app', component: QcTempHome },

];