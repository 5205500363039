/* eslint-disable react-hooks/rules-of-hooks */
import { makeAutoObservable, runInAction } from "mobx";
import axios from "../../utils/axios";
import { MainRootStore } from "../index";
import ResponseError from "../../utils/axios/ResponseError";

import * as Types from "./userTypes";

/**
 * @description Fetch & Store - User & Authentication
 */
class UserStore {
  rootStore: MainRootStore;

  username: string | null = null;

  numberOfStores: number | string | null = null;

  user: Types.TUser | null = null;

  client: Types.TClient | null = null;

  accessToken: string | null = null;

  refreshToken: string | null = null;

  error: Types.IRequestError | null = null;

  loading: boolean = false;

  legitBusinessAcc: boolean = false;
  roleId: number | undefined = undefined;

  constructor(rootStore: MainRootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  get hasSession() {
    if (!this.accessToken) this.restoreSession();
    return this.accessToken;
  }

  restoreSession = () => {
    this.accessToken = localStorage.getItem("cc_client_token");
    this.refreshToken = localStorage.getItem("cc_client_refresh_token");

    return !!this.accessToken;
  };

  me = async () => {
    if (this.user && this.client) {
      return;
    }

    try {
      this.loading = true;

      const res = await Promise.all([
        axios.get(this.rootStore.configStore.API.dashboard.auth.me),
        axios.get(this.rootStore.configStore.API.dashboard.auth.category),
      ]);

      runInAction(async () => {
        const url =
          this.rootStore.configStore.API.dashboard.analytics.poi.places;

        const resPlace = await axios.post(url, {
          categories: [res[1].data.client_category_id],
        });

        runInAction(() => {
          this.user = {
            ...res[0].data.auth_user,
            username:
              this.username || localStorage.getItem("cc_username") || null,
          };

          const clientData = {
            ...res[0].data.client_details,
            ...res[1].data,
          };

          this.client = clientData;

          if (resPlace.data && resPlace.data[res[1].data.client_category_id]) {
            this.client = {
              ...clientData,
              ...resPlace.data[res[1].data.client_category_id][0],
            };
          }

          this.loading = false;
        });
      });
    } catch (error: any) {
      runInAction(() => {
        this.loading = false;
        const {
          response: { data },
        } = error;
        this.error = {
          statusCode: data.statusCode,
          message: data.message,
        };

        throw new ResponseError(this.error);
      });
    }
  };

  // ToDo: Handle revoke endpoint
  logout = () => {
    [
      // Todo: Remove this once jQuery request model migrated to MobX
      "client_id",
      "client_name",
      "client_firebase_topic",
      "client_type",
      "user_id",
      "role_id",
      "cc_username",
      "cc_user_coord",
      "cc_client_token",
      "cc_client_token_creation_date",
      "cc_client_refresh_token",
      "cc_client_refresh_token_creation_date",
      "number_of_stores",
    ].forEach((key) => {
      localStorage.removeItem(key);
    });
    // const url = window.location.href.split('/app');
    // window.location.assign(`${url[0]}/login`);
    this.accessToken = null;
    this.refreshToken = null;
    this.username = null;
    this.roleId = undefined;
    this.user = null;
    this.client = null;
    this.numberOfStores = null;
    this.rootStore.routerStore.push("/login");
  };

  resetPassword = () => {
    this.rootStore.routerStore.push("/update-password");
  };
  editDetails = () => {
    this.rootStore.routerStore.push("/edit-details");
  };
  addUser = () => {
    this.rootStore.routerStore.push("/add-user");
  };

  setLegitBusinessAcc = (legitBusinessAcc?: boolean): void => {
    if (legitBusinessAcc) {
      this.legitBusinessAcc = legitBusinessAcc;
    } else {
      this.legitBusinessAcc = false;
    }
  };
  setRoleId = (roleId?: number | undefined): void => {
    if (roleId) {
      this.roleId = roleId;
    } else {
      this.roleId = undefined;
    }
  };

  async authenticate({ username, password }: { username: any; password: any }) {
    if (!this.hasSession) {
      try {
        this.loading = true;
        const res = await axios.post(
          this.rootStore.configStore.API.dashboard.auth.login,
          {
            username,
            password,
          }
        );

        runInAction(() => {
          this.setAuth(res.data, username);
        });
      } catch (error: any) {
        runInAction(() => {
          this.loading = false;
          const {
            response: { data },
          } = error;
          this.error = data;

          throw new ResponseError({
            statusCode: data.statusCode,
            message: data.message,
          });
        });
      }
    }

    this.rootStore.routerStore.push("/app");
  }

  setAuth = (res: any, username?: any) => {
    this.accessToken = res.token;
    this.refreshToken = res.refreshToken;
    this.username = username;
    this.loading = false;
    this.error = null;

    const isoDate = new Date().toISOString();
    // Todo: Rename this once jQuery request model migrated to MobX
    localStorage.setItem("cc_username", username);
    localStorage.setItem("cc_client_token", res.token);
    localStorage.setItem("cc_client_token_creation_date", isoDate);
    localStorage.setItem("cc_client_refresh_token", res.refreshToken);
    localStorage.setItem("cc_client_refresh_token_creation_date", isoDate);
  };
}

export default UserStore;
