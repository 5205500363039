import { makeAutoObservable } from 'mobx';

import { MainRootStore } from '..';

import * as Types from './interfaceTypes';

/**
 * @description Fetch & Store - Rules & togglers for interface
 */
class InterfaceStore {

  theme: string;

  colors: Types.TColors;

  navActive: boolean;

  modal: Types.TModal = {
    isOpen: false,
    active: false,
    onSubmit: null,
    onClick: null,
    message: null,
    title: null,
    flags: null,
  };

  portalModal: Types.TPortalModal = {
    isOpen: false,
    content: null,
    title: '',
    footer: null,
  }

  navBuffer: Types.INavBuffer[] = [
    {
      // Default buffer should always have the home page to traverse to
      name: 'Home',
      path: '/app',
      match: {
        url: '/app'
      }
    }
  ];

  rootStore: MainRootStore;

  constructor(rootStore: MainRootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.theme = rootStore.configStore.THEME.settings.theme;
    this.colors = rootStore.configStore.THEME.colors;
    this.navActive = rootStore.configStore.THEME.settings.navExpanded;
  }

  setTheme = (theme?: string): void => {
    if (theme) {
      document.body.classList.remove(`theme-${this.theme}`);
      this.theme = theme;
      localStorage.setItem('user_preference_theme', this.theme);
      document.body.classList.add(`theme-${this.theme}`);
    } else {
      document.body.classList.remove(`theme-${this.theme}`);
      const savedTheme = localStorage.getItem('user_preference_theme');
      this.theme = savedTheme || this.rootStore.configStore.THEME.settings.theme;
      document.body.classList.add(`theme-${this.theme}`);
    }
  }

  showModal = ({
    name,
    onSubmit = null,
    onClick = null,
    message = null,
    title = null,
    flags = null
  }: Types.TShowModal): void => {
    this.modal.active = name;
    this.modal.onSubmit = onSubmit || onClick;
    this.modal.message = message;
    this.modal.title = title;
    this.modal.flags = flags;
  };

  openModal = ({ title, content, footer }: Types.TOpenModal): void => {
    this.portalModal.title = title;
    this.portalModal.content = content;
    this.portalModal.footer = footer;
    this.portalModal.isOpen = true;
  }

  closeModal = (): void => {
    this.portalModal.isOpen = false;
    this.portalModal.title = '';
    this.portalModal.content = null;
    this.portalModal.footer = null;
  }

  hideModal = (): void => {
    this.modal.active = false;
    this.modal.onSubmit = null;
    this.modal.message = null;
    this.modal.title = null;
  };

  successModal = (): void => {
    this.showModal({
      name: 'SUCCESS'
    });
  };

  errorModal = (): void => {
    this.showModal({
      name: 'ERROR'
    });
  };

  toggleNav = (): void => {
    this.navActive = !this.navActive;
  };

  bufferNav = ({ match, meta }: {match: any, meta: any}): void => {
    const location = {
      name: meta.title,
      path: match.path,
      meta,
      match
    };
    if (meta.title.toLowerCase() === 'home') {
      // App navigated to home, reset the trail
      this.navBuffer = [location];
    } else {
      if (this.navBuffer[this.navBuffer.length - 1].name === meta.title) return;
      this.navBuffer.push(location);
    }
  };
}

export default InterfaceStore;
