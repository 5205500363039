import { FC } from 'react';
import { Space, TimePicker } from 'antd';

const TimePickers: FC = () => (
  <Space direction="vertical" size={16}>
    <TimePicker />

    <TimePicker disabled />

    <TimePicker use12Hours />

    <TimePicker status="error" />

    <TimePicker status="warning" />
  </Space>
);

export default TimePickers;
