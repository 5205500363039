/* eslint-disable no-shadow */

import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteImage,
  editDetails,
  getBusinessUserDetails,
  getBusinessUserImages,
  getBusinessUserStatus,
  postLoginTime,
  postPaymentIntent,
  postPaymentSession,
  updatePassword,
} from "../../utils/API/stores";
import { useTranslation } from "react-i18next";
import { handleErrorModalTrans } from "../../utils/modals/simpleModals";

export enum EStoreKey {
  USER_STATUS = "user-status",
  PAYMENT_INTENT = "payment-intent",
  PAYMENT_SESSION = "payment-session",
  BUSINESS_DETAILS = "business-details",
  LOGIN_CHECK = "login-check",
  UPDATE_PASSWORD = "update-password",
  BUSINESS_IMAGES = "user-images",
  DELETE_IMAGE = "delete-image",
  EDIT_DETAILS = "edit-details",
}

// ----------- Queries ------------- //

export const useGetBusinessUserStatus = (
  userId: any,
  isBusinessUser: boolean
) =>
  useQuery(
    [EStoreKey.USER_STATUS, userId],
    () => getBusinessUserStatus(userId),
    {
      keepPreviousData: true,
      enabled: isBusinessUser === true,
      retry: 1,
    }
  );
export const useGetBusinessUserDetails = (userEmail: any) =>
  useQuery(
    [EStoreKey.BUSINESS_DETAILS, userEmail],
    () => getBusinessUserDetails(userEmail),
    {
      keepPreviousData: true,
      retry: 1,
    }
  );
export const useGetBusinessUserImages = (imageType: any) =>
  useQuery(
    [EStoreKey.BUSINESS_IMAGES, imageType],
    () => getBusinessUserImages(imageType),
    {
      keepPreviousData: true,
      enabled: imageType !== "upload",
      retry: 0,
    }
  );

// ------Mutations-------//

export const usePostPaymentIntent = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return useMutation(postPaymentIntent, {
    onSuccess: () => {
      queryClient.invalidateQueries(EStoreKey.PAYMENT_INTENT);
    },
    onError: () => {
      handleErrorModalTrans(
        `${t("errorModal.whoops")}`,
        `${t("errorModal.stripe")}`,
        `${t("errorModal.dismiss")}`
      );
    },
  });
};
export const usePostPaymentSession = () => {
  const queryClient = useQueryClient();

  return useMutation(postPaymentSession, {
    onSuccess: () => {
      queryClient.invalidateQueries(EStoreKey.PAYMENT_SESSION);
    },
  });
};

export const usePostLoginTime = () => {
  const queryClient = useQueryClient();

  return useMutation(postLoginTime, {
    onSuccess: () => {
      queryClient.invalidateQueries(EStoreKey.LOGIN_CHECK);
    },
  });
};
export const useEditUserDetails = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return useMutation(editDetails, {
    onSuccess: () => {
      queryClient.invalidateQueries(EStoreKey.EDIT_DETAILS);
    },
    onError: () => {
      handleErrorModalTrans(
        `${t("errorModal.whoops")}`,
        `${t("errorModal.generic")}`,
        `${t("errorModal.dismiss")}`
      );
    },
  });
};

export const useUpdatePassword = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return useMutation(updatePassword, {
    onSuccess: () => {
      queryClient.invalidateQueries(EStoreKey.UPDATE_PASSWORD);
    },
    onError: () => {
      handleErrorModalTrans(
        `${t("errorModal.whoops")}`,
        `${t("errorModal.password")}`,
        `${t("errorModal.dismiss")}`
      );
    },
  });
};

export const useDeleteImage = (imageId: any) => {
  const queryClient = useQueryClient();

  return useMutation(deleteImage, {
    onSuccess: () => {
      queryClient.invalidateQueries([EStoreKey.DELETE_IMAGE, imageId]);
    },
  });
};
