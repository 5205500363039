// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Login-module__container___2MTLM {\n  height: 100vh;\n  width: 100vw;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  background: #e4e4e4; }\n\n.Login-module__section___27s-C {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  background: #e4e4e4;\n  border-radius: 0.375rem;\n  margin-right: 0;\n  margin-left: 0;\n  height: 100vh;\n  width: 100%; }\n\n.Login-module__box___2o3FQ {\n  background-color: #fff;\n  border-radius: 0.375rem;\n  width: 50%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center; }\n  .Login-module__box___2o3FQ::before,\n  .Login-module__box___2o3FQ ::after {\n    box-sizing: border-box; }\n\n.Login-module__boxBody___2gdM4 {\n  border-radius: 0.375rem;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center; }\n\n.Login-module__image___GJEZy {\n  object-fit: cover;\n  height: 100%; }\n", ""]);
// Exports
exports.locals = {
	"container": "Login-module__container___2MTLM",
	"section": "Login-module__section___27s-C",
	"box": "Login-module__box___2o3FQ",
	"boxBody": "Login-module__boxBody___2gdM4",
	"image": "Login-module__image___GJEZy"
};
module.exports = exports;
