import { useState, useEffect, FC } from "react";
import { Trans as I18N } from "react-i18next";

interface ILoaderProps {
  theme: any;
}

const Loader: FC<ILoaderProps> = ({ theme }) => {
  const [delay, setDelay] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDelay(true);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const delayMessage = <I18N>DELAY_MESSAGE</I18N>;

  return (
    <div className="page-dashboard h-100 relative Loader">
      <div className="true-center">
        <div className="lds-grid">
          {[...Array(9)].map((_, index) => (
            <div key={index} />
          ))}
        </div>
      </div>

      {delay && (
        <span
          className={`true-center mt-5 pt-5 font-weight-bold ${
            theme === "dark" ? "text-white" : ""
          }`}
        >
          {delayMessage}
        </span>
      )}
    </div>
  );
};

export default Loader;
