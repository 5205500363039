import { FC } from "react";
import { observer } from "mobx-react";
import { Dropdown, Col, Row, Space, Button } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { ConfigProvider } from "antd";
import enUS from "antd/es/locale/en_US";
import deDE from "antd/es/locale/de_DE";
import { useStores } from "../../../hooks/useStores";
import Logo from "../../../assets/logos/VenuenowLogo.png";
import styles from "./Header.module.scss";
import UserDropdown from "../UserDropDown/UserDropdown";
import i18n from "../../../i18n";
import MasterDatePicker from "../MasterDatePicker";

const Header: FC = () => {
  const { interfaceStore, userStore } = useStores();

  const roleId = userStore.user?.roleId;

  return (
    <Row>
      <Col span={roleId === 1 ? 8 : 12}>
        <Space size={24} align="start">
          <Button
            type="link"
            onClick={interfaceStore.toggleNav}
            onKeyUp={interfaceStore.toggleNav}
            icon={
              !interfaceStore.navActive ? (
                <MenuUnfoldOutlined />
              ) : (
                <MenuFoldOutlined />
              )
            }
            style={{
              borderRadius: "50%",
            }}
          />

          <NavLink to="/app" className={styles.header_iconLink}>
            <img
              alt="Logo"
              src={Logo}
              style={{ maxHeight: "40px", marginTop: "5px" }}
            />
          </NavLink>
        </Space>
      </Col>

      {roleId === 1 && (
        <Col span={8}>
          <ConfigProvider locale={i18n.language === "de" ? deDE : enUS}>
            <MasterDatePicker />
          </ConfigProvider>
        </Col>
      )}

      <Col span={roleId === 1 ? 8 : 12}>
        <Row justify="end">
          <Col>
            <Dropdown
              overlay={<UserDropdown />}
              trigger={["click"]}
              placement="bottomRight"
            >
              <Button
                type="ghost"
                icon={<UserOutlined />}
                style={{
                  borderRadius: "10%",
                  height: "35px",
                }}
              />
            </Dropdown>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default observer(Header);
