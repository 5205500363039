// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".simpleModals-module__okText___2EoWv {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding-left: 14px;\n  padding-right: 14px; }\n", ""]);
// Exports
exports.locals = {
	"okText": "simpleModals-module__okText___2EoWv"
};
module.exports = exports;
