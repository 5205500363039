import { makeAutoObservable } from "mobx";

import { MainRootStore } from "..";

/**
 * @description Fetch & Store - Data for Offer Preview on Baby Dash
 */
class CustomOfferStore {
  templateImageUrl: string;

  offerImageUrl: string;

  rootStore: MainRootStore;

  constructor(rootStore: MainRootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.templateImageUrl = "No Image";
    this.offerImageUrl = "No Image";
  }

  setTemplateImageUrl = (templateImageUrl?: string): void => {
    if (templateImageUrl) {
      this.templateImageUrl = templateImageUrl;
    } else {
      this.templateImageUrl = "No Image";
    }
  };

  setOfferImageUrl = (offerImageUrl?: string): void => {
    if (offerImageUrl) {
      this.offerImageUrl = offerImageUrl;
    } else {
      this.offerImageUrl = "No Image";
    }
  };

  clearCustomOffer = () => {
    this.templateImageUrl = "No Image";
    this.offerImageUrl = "No Image";
  };
}

export default CustomOfferStore;
