import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          header: {
            lightMode: "Light Mode",
            darkMode: "Dark Mode",
            userSettings: "User Settings",
            updatePassword: "Update Password",
            updateDetails: "Update Details",
            addNewUser: "Add New User",
            language: "Language",
            english: "English",
            german: "German",
            logOut: "Log Out",
          },
          essentialStats: {
            title: "Essential Stats",
            storeVisits: "Store Visits",
            appInstalls: "App Installs",
            offerSent: "Offers Sent",
            offersOpened: "Offers Opened",
            offersRedeemed: "Offers Redeemed",
            offersConverted: "Offers Converted",
          },
          appAcc: {
            installsByDay: "Installs By Day",
            permissionsAllowed: "Permissions Allowed",
            appInstalls: "App Installs",
            installs: "Installs",
            locationServices: "Location Services",
            pushNotifs: "Push Notifications",
            allowed: "Allowed",
            alwaysAllowed: "Always Allowed",
            allowedWhenActive: "Allowed When Active",
            declined: "Declined",
          },
          appInt: {
            title: "App Interactions",
            android: "Android",
            combined: "Combined",
            ios: "iOS",
            usageByDay: "App Usage By Day",
            appVisits: "App Visits",
            visits: "Visits",
            storeVisits: "Store Visits",
          },
          appDev: {
            title: "Devices By Brand",
            apple: "Apple",
            android: "Android",
            other: "Other",
            deviceMap: "Device Map",
          },
          campaigns: {
            title: "Campaigns Sent and redeemed",
            sent: "Sent",
            opened: "Opened",
            redeemed: "Redeemed",
            allStoresCampaigns: "All Stores & Campaigns",
            yourGroups: "Your Groups",
            yourCampaigns: "Your Campaigns",
            selectGroup: "Select Group..",
            selectCampaign: "Select Campaign..",
            allCampaigns: "- ALL CAMPAIGNS -",
            noData: "No Data. Please Select Campaigns..",
            topCampaigns: "Top 5 Campaigns",
            bottomCampaigns: "Bottom 5 Campaigns",
            noCampaigns: "No Campaigns Available",
            headCampaign: "Campaign",
            headType: "Type",
            headSent: "Sent",
            headConverted: "Converted",
            individualCampaigns: "Individual Campaigns",
            selectPlaceHolder: "Select a campaign..",
            singleNoData: "No Data. Please Select Campaign..",
          },
          poi: {
            places: "POI Places",
            topFivePlaces: "Top 5 Places",
            bottomFivePlaces: "Bottom 5 Places",
            poiDwellTime: "POI Dwell Time",
            topFiveTime: "Top 5 Time Spent",
            bottomFiveTime: "Bottom 5 Time Spent",
            location: "Location",
            visitCount: "Visit Count",
            dwellTime: "Dwell Time",
            dwellingByHour: "Dwelling By Hour",
            selectPOI: "Select an above point of interest",
            competitorTitle: "Competitor Footfall",
            yourCompetitors: "Your Competitors",
            visits: "Visits",
            selectThreeCompetitors: "Select Up To 3 Competitors..",
            pleaseSelect: "Please Select Competitors...",
            poiLocations: "POI Locations",
            monday: "Monday",
            tuesday: "Tuesday",
            wednesday: "Wednesday",
            thursday: "Thursday",
            friday: "Friday",
            saturday: "Saturday",
            sunday: "Sunday",
          },
          ev: {
            topChargerVisits: "Top Charger Visits",
            networkVisitsByDay: "Network Visits By Day",
            selectThreeNetworks: "Select up to 3 network brands",
            noData: "No Data. Please Select Network/s...",
            noCardData: "No Data. Please Adjust Date Range..",
            networkDwellTime: "Network Dwell Time By Day (mins)",
          },
          business: {
            essentialBusiness: "Essential Business Stats",
            registeredAccounts: "Registered Accounts",
            lapsedAccounts: "Lapsed Accounts",
            activeStoreTitle: "Businesses with Active Stores",
            activeStores: "Active Stores",
            inactiveStores: "Inactive Stores",
            activeCampaigns: "Active Campaigns",
            statsOverTime: "Stats Over Time",
            activeAccounts: "Active Accounts",
            newAccounts: "New Accounts",
            offersSent: "Offers Sent",
            accountsLoginsByDay: "Account Logins By Day",
            selectBusiness: "Select Business",
            pleaseSelect: "Please Select Business..",
            newAccountsByDay: "New Accounts By Day",
            newAccs: "New Accounts",
            topBottomBusiness: "Top / Bottom Active Businesses",
            topFiveLogins: "Top 5 Active Businesses By Logins",
            bottomFiveLogins: "Bottom 5 Active Businesses By Logins",
            logins: "Logins",
            all: "- ALL -",
            bottomFiveActiveBusiness: "Bottom 5 Active Businesses By Logins",
            inactiveBusinesses: "Inactive Businesses",
            businessName: "Business",
            active: "Active",
            inactive: "Inactive",
            paid: "Paid",
            unpaid: "Unpaid",
            lastLogin: "Last Login",
            email: "Email",
            phone: "Phone",
            search: "Search",
            reset: "Reset",
            offerSent: "Offers Sent",
            dateRegistered: "Date Registered",
            storeInfo: "Store Info",
          },
          storeMap: {
            title: "Live Store Map",
            searchAddress: "Search Address",
            chargerRaduis: "Ev Charger Radius (Miles)",
            legend: "Legend",
            stores: "Stores",
            users: "Users",
            chargers: "Chargers",
          },
          sideMenu: {
            home: "Home",
            analytics: "Analytics",
            app: "App",
            acquisition: "Acquisition",
            interactions: "Interactions",
            devices: "Devices",
            deviceMap: "Device Map",
            campaignsOffer: "Campaigns & Offers",
            standardCampaigns: "Standard Campaigns",
            poi: "POI",
            visits: "Visits",
            dwell: "Dwell",
            competitors: "Competitors",
            ev: "EV",
            networkChargers: "Netword Chargers",
            business: "Business",
            businessStats: "Business Stats",
            storeMap: "Store Map",
            manageBrand: "Manage Brand",
            offers: "Offers",
            createOffer: "Create Offer",
            currentOffers: "Current Offers",
            sendOffers: "Send Offers",
            instantOffer: "Instant Offer",
            archivedOffers: "Archived Offers",
            campaigns: "Campaigns",
            manageCampaigns: "Manage Campaigns",
            createCampaign: "Create Campaign",
            locations: "Locations",
            venues: "Venues",
            createVenue: "Create Venue",
            groupsSubgroups: "Groups & Subgroups",
            createLocation: "Create Location",
          },
          offers: {
            createOffer: "Create Offer",
            notification: "Notification",
            notificationTitle: "Enter Notification Title",
            notificationRule: "Please provide a notification title",
            notificationBody: "Enter Notification Body Text",
            notifBodyRule: "Please provide a notification body text",
            inbox: "Inbox",
            inboxTitle: "Enter Inbox Title",
            inboxTitleRule: "Please provide inbox title",
            inboxBody: "Enter Inbox Body Text",
            inboxBodyRule: "Please provide inbox body text",
            action: "Action",
            actionRule: "Please select an Action",
            openApp: "Open App",
            openUrl: "Open URL",
            urlRule: "Please provide a URL",
            urlValidRule: "Please provide a valid URL",
            enterUrl: "Enter URL",
            paymentRule: "Please provide Customer Payment amount",
            customerPayment: "Customer Payment",
            clientRule: "Please provide Client Payment amount",
            clientPayment: "Client Payment",
            showPayment: "Show Payment",
            inboxTimeout: "Inbox Timeout",
            inboxTimeoutRule: "Please provide inbox timeout",
            inboxTimeoutPeriodRule: "Please select inbox timeout period",
            redeemTimeout: "Redeem Timeout",
            redeemTimeoutRule: "Please provide redeem timeout",
            redeemTimePeriodRule: "Please select redeem timeout period",
            minutes: "Minutes",
            hours: "Hours",
            days: "Days",
            weeks: "Weeks",
            months: "Months",
            terms: "Terms & Conditions",
            termsBody: "Enter Terms & Conditions",
            submit: "Submit",
            clear: "Clear",
          },
          imgUpload: {
            uploadImage: "Upload Image",
            imageReqs:
              "For best results your image should be 2:1 ratio (i.e. 1000px x 500px) and a minimum of 1000px wide",
            uploadPreview: "Your uploaded image will show here",
            uploadRules: "Please provide an image",
          },
          currentOffers: {
            title: "Current Offers",
            close: "Close",
            info: "Info",
            edit: "Edit",
            archive: "Archive",
            unarchive: "Unarchive",
            active: "Active",
            inActve: "Inactive",
            offerDetails: "Offer Details",
            id: "ID",
            notifTitle: "Notification Title",
            notifBody: "Notification Body",
            inboxTitle: "Inbox Title",
            inboxBody: "Inbox Body",
            action: "Acttion",
            inboxTimout: "Inbox Timeout",
            inboxTimeframe: "Inbox Timeframe",
            redeemTimout: "Redeem Timout",
            redeemTimeframe: "Redeem Timeframe",
            terms: "Terms & Conditions",
          },
          sendOffers: {
            title: "Send Offer On Map",
            competitors: "Please select Competitor(s)",
            clearForm: "Clear From",
            filter: "Filter",
            user: "Users",
          },
          instantOffer: {
            title: "Instant Offer",
            selectCountry: "Please select a Country",
            selectTimeZone: "Please select a Time Zone",
            clearForm: "Clear Form",
            submit: "Submit",
          },
          archivedOffers: {
            title: "Archived Offers",
          },
          createCampaign: {
            title: "Create Campaign",
            competitor: "Competitor",
            competitorDesc:
              "Send offers to customers when they are near your competitors.",
            firstVisit: "First Visit",
            firstVisitDesc:
              "Send an offer to your customers on their first visit to one of your stores.",
            visits: "Visits",
            visitsDesc:
              "Send an offer based on the amount of times a customer has visited one of your stores.",
            rolling: "Rolling",
            rollingDesc:
              "Same as a Visits campaign but restricted to a specified period of days.",
            scheduled: "Scheduled",
            scheduledDesc:
              "Set an offer to launch at a certain time each day during a specified period.",
            homecard: "Homecard",
            homecardDesc: "Set an offer as a homecard",
            campaignTypeRule: "Please select campaign type",
            chooseCampaignType: "Choose campaign type",
            setNameCriteria: "Set name and criteria",
            selectLocations: "Select locations",
            setCompetitor: "Set competitor",
            offerDetails: "Offer details",
            selectOffer: "Select an offer",
            confirmSelection: "Confirm selections",
            congratulations: "Congratulations, your campaign is now live!",
            dismiss: "Dismiss",
            proxTrigger: "Proximity Trigger",
            proxTriggerRule: "Proximity Trigger is required",
            campaignName: "Campaign Name",
            campaignNameRule: "Campaign Name is required",
            dateRange: "Date Range",
            dateRangeRule: "Please select Date Range!",
            selectGroup: "Select a group or multiple groups",
            selectGroupRule: "Groups are required",
            selectGroupPlaceholder:
              "Type group name(s) here or use the dropdown",
            competitors: "Competitors",
            competitorsRule: "Please select competitors",
            competitorsPlaceholder: "Select competitors",
            expiryTimeout: "Expiry Timeout",
            expiryTimeoutRule: "Please input Expiry Timeout",
            expiryTimeframeRule: "Please select expiry Time Frame",
            redeemTimeout: "Redeem Timeout",
            redeemTimeoutRule: "Please input Redeem Timeout",
            redeemTimeframeRule: "Please select Redeem Time Frame",
            targetAudience: "Target Audience",
            engagement: "Engagement Method",
            engagementRule: "Please select Engagement Method",
            ageGroup: "Age Group",
            ageGroupRule: "Please select Age Group",
            dwellTime: "Dwell Time",
            dwellTimeRule: "Please select Dwell Time",
            weeklySpend: "Weekly Spend",
            weeklySpendRule: "Please select Weekly Spend",
            offerSelectRule: "Please select offer",
            selectOfferList: "Select an Existing Offer",
            checkCampaignDetails: "Please Check Campaign Details",
            type: "Type",
            locationGroups: "Location Groups",
            expiryTimout: "Expiry Timeout",
            layer: "Layer",
            reach: "Reach",
            price: "Price",
            name: "Name",
            proximity: "Proximity",
            noVisits: "Number Of Visits Required",
            recurring: "Recurring / Milestone",
            duration: "Campaign Duration / All Time",
            pushFirst: "Push on First Visit?",
            no: "No",
            yes: "Yes",
            rollingPeriod: "Rolling Period (Days)",
            periodLength: "Period Length (Days)",
            claimLimit: "Claim Limit",
            distTime: "Distribution Time",
            id: "ID",
            customId: "Custom ID",
            address: "Address",
            city: "City",
            postCode: "Post Code",
            selectedStores:
              "Select locations where your campaign will be active",
            selectedStoresRule: "Stores are required",
            selectSubGroups: "Select a subgroup or multiple subgroups",
            selectSubGroupsRule: "Subgroups are required",
            selectSubGroupPlace:
              "Type subgroup name(s) here or use the dropdown",
          },
          controls: {
            prev: "PREV",
            submit: "SUBMIT",
            next: "NEXT",
          },
          manageCampaign: {
            title: "Manage Campaign",
            dismiss: "Dismiss",
            id: "ID",
            name: "Name",
            type: "Type",
            dateRange: "Date Range",
            live: "Live",
            status: "Status",
            actions: "Actions",
            view: "View",
            edit: "Edit",
            delete: "Delete ",
          },
          sendOffer: {
            sendPartOne: `Are you sure you want to send offer to `,
            sendPartTwo: `user(s)?`,
            send: "Send",
            cancel: "Cancel",
          },
          errorModal: {
            dismiss: "Dismiss",
            whoops: "Whoops",
            generic: "Something went wrong! Please try again.",
            message:
              "There was a problem loading data! Please Reload the page and try again.",
            stripe:
              "There was a problem connecting to Stripe. Please reload the page and try again.",
            password:
              "There was a problem updating your password! Please reload the page and try again.",
            offer: `There was a problem with this offer feature. Please try again.`,
            image:
              "There was a problem with image upload! Please reload and try again",
            form: "Please complete the form",
            unable: "Unable to send Offer, no users currently on map.",
          },
          successModal: {
            success: "Success!",
          },
          login: {
            hello: "Hello!",
            welcome:
              "Welcome to your VenueNow Dashboard, please enter your email address below!",
            email: "Email",
            emailRule: "Email Address is required",
            next: "Next",
            great: "Great!",
            enterPass:
              "Please enter your password to log in to your dashboard!",
            password: "Password",
            passRule: "Password is required",
            passValidation:
              "Password must be a minimum of 10 characters with at least 1 number and one special character (!@#$%^&*).",
            passProb:
              "There was a problem! Please check your password and try again!",
            passPlaceholder: "Create password...",
            confirmPass: "Confirm Password",
            confirmPassRule: "Please confirm your password",
            passNoMatch: "Passwords do no match!",
            createPassword: "Create Password!",
            createPassProblem:
              "There was a problem! Please re-enter your password and try again!",
            login: "Login",
            forgotPass: "Forgot your password? Click Here!",
            hmm: "Hmm..",
            noPass:
              "It doesnt look there is a password set up for this account! Hit the button below and a password creation link will be sent your email",
            notActivated:
              "It doesnt look there your account has been activated!",
            resetSent: "Reset Link Sent!",
            followLink:
              "Follow the link on your email and reset your password!",
            sendPassLink: "Send password creation link!",
            pleaseContact:
              "Please contact our support team and we will get that account activated as soon as possible!",
            success: "Success!",
            passCreated: "Password Creation Successful!",
            createPass: "Create a Password",
            createPassEntry:
              "Create a password for your account using the fields below!",
          },
          dateRanges: {
            last7Days: "Last 7 Days",
            last14Days: "Last 14 Days",
            last21Days: "Last 21 Days",
            lastMonth: "Last Month",
            last3Months: "Last 3 Months",
            allTime: "All Time",
          },

          // here we will place our translations...
        },
      },
      de: {
        translation: {
          header: {
            lightMode: "Lichtmodus",
            darkMode: "Dunkelmodus",
            userSettings: "Benutzereinstellungen",
            updatePassword: "Passwort Aktualisieren",
            updateDetails: "Details Aktualisieren",
            addNewUser: "Neuen Benutzer Hinzufügen",
            language: "Sprache",
            english: "Englisch",
            german: "Deutsch",
            logOut: "Abmelden",
          },
          essentialStats: {
            title: "Wesentliche Statistiken",
            storeVisits: "Filialbesuche",
            appInstalls: "App-Installationens",
            offerSent: "Angebote gesendet",
            offersOpened: "Angebote geöffnet",
            offersRedeemed: "Angebote eingelöst",
            offersConverted: "Angebote umgewandelt",
          },
          appAcc: {
            installsByDay: "Installationen pro Tag",
            permissionsAllowed: "Genehmigte Berechtigungen",
            appInstalls: "App-Installationens",
            installs: "Installationen",
            locationServices: "Standortdienste",
            pushNotifs: "Push-Benachrichtigungen",
            allowed: "Erlaubt",
            alwaysAllowed: "Immer Erlaubt",
            allowedWhenActive: "Erlaubt Wenn Aktiv",
            declined: "Abgelehnt",
          },
          appInt: {
            title: "App Interaktionen",
            android: "Android",
            combined: "Kombiniert",
            ios: "iOS",
            usageByDay: "App-Nutzung pro Tag",
            appVisits: "App-Besuche",
            visits: "Besuche",
            storeVisits: "Geschäftsbesuche",
          },
          appDev: {
            title: "Geräte nach Marke",
            apple: "Apple",
            android: "Android",
            other: "Andere",
            deviceMap: "Geräteübersicht",
          },
          campaigns: {
            title: "Kampagnen gesendet und eingelöst",
            sent: "Gesendet",
            opened: "Geöffnet",
            redeemed: "Eingelöst",
            allStoresCampaigns: "Alle Geschäfte und Kampagnen",
            yourGroups: "Deine Gruppen",
            yourCampaigns: "Deine Kampagnen",
            selectGroup: "Gruppe auswählen..",
            selectCampaign: "Kampagne auswählen..",
            allcampaigns: "- ALLE KAMPAGNEN -",
            noData: "Keine Daten. Bitte wähle Kampagnen aus..",
            topCampaigns: "Spitze 5 Kampagnen",
            bottomCampaigns: "Untere 5 Kampagnen",
            noCampaigns: "Keine verfügbaren Kampagnen",
            headCampaign: "Kampagne",
            headType: "Typ",
            headSent: "Gesendet",
            headConverted: "Konvertiert",
            individualCampaigns: "Individuelle Kampagnen",
            selectPlaceHolder: "Wähle eine Kampagne aus..",
            singleNoData: "Keine Daten. Bitte wähle eine Kampagne aus..",
          },
          poi: {
            places: "POI Orte",
            topFivePlaces: "Top 5 Orte",
            bottomFivePlaces: "Bottom 5 Orte",
            poiDwellTime: "POI Verweildauer",
            topFiveTime: "Top 5 Verweildauer",
            bottomFiveTime: "Bottom 5 Verweildauer",
            location: "Standort",
            visitCount: "Besucheranzahl",
            dwellTime: "Verweildauer",
            dwellingByHour: "Verweildauer pro Stunde",
            selectPOI: "Wählen Sie einen oben genannten Interessenspunkt aus",
            competitorTitle: "Wettbewerber Besucheranzahl",
            yourCompetitors: "Ihre Wettbewerber",
            visits: "Besuche",
            selectThreeCompetitors: "Bis zu 3 Wettbewerber auswählen..",
            pleaseSelect: "Bitte Wettbewerber auswählen...",
            poiLocations: "POI Standorte",
            monday: "Montag",
            tuesday: "Dienstag",
            wednesday: "Mittwoch",
            thursday: "Donnerstag",
            friday: "Freitag",
            saturday: "Samstag",
            sunday: "Sonntag",
          },
          ev: {
            topChargerVisits: "Top-Ladestationen Besuche",
            networkVisitsByDay: "Netzwerkbesuche pro Tag",
            selectThreeNetworks: "Wählen Sie bis zu 3 Netzwerkmarken aus",
            noData: "Keine Daten. Bitte wählen Sie Netzwerk/e aus...",
            noCardData: "Keine Daten. Bitte passen Sie den Zeitraum an..",
            networkDwellTime: "Netzwerkverweildauer pro Tag (Minuten)",
          },
          business: {
            essentialBusiness: "Statistiken zum wesentlichen Geschäft",
            registeredAccounts: "Registrierte Konten",
            lapsedAccounts: "Abgelaufene Konten",
            activeStores: "Aktive Geschäfte",
            inactiveStores: "Inaktive Geschäfte",
            activeStoreTitle: "Unternehmen mit aktiven Geschäften",
            activeCampaigns: "Aktive Kampagnen",
            statsOverTime: "Statistiken im Laufe der Zeit",
            activeAccounts: "Aktive Konten",
            newAccounts: "Neue Konten",
            offersSent: "Angebote gesendet",
            accountsLoginsByDay: "Kontoanmeldungen pro Tag",
            selectBusiness: "Geschäft auswählen",
            pleaseSelect: "Bitte Geschäft auswählen...",
            newAccountsByDay: "Neue Konten pro Tag",
            newAccs: "Neue Konten",
            topBottomBusiness: "Top / Bottom-aktive Unternehmen",
            topFiveLogins: "Top 5 aktive Unternehmen nach Anmeldungen",
            bottomFiveLogins: "Bottom 5 aktive Unternehmen nach Anmeldungen",
            logins: "Anmeldungen",
            all: "- ALLE -",
            bottomFiveActiveBusiness:
              "Bottom 5 aktive Unternehmen nach Anmeldungen",
            inactiveBusinesses: "Inaktive Unternehmen",
            businessName: "Unternehmensname",
            active: "Aktiv",
            inactive: "Inaktiv",
            paid: "Bezahlt",
            unpaid: "Unbezahlt",
            lastLogin: "Letzte Anmeldung",
            email: "E-Mail",
            phone: "Telefon",
            search: "Suche",
            reset: "Reset",
            offerSent: "Angebote Gesendet",
            dateRegistered: "Anmeldedatum",
            storeInfo: "Geschäftsinformationen",
          },
          storeMap: {
            title: "Live Kartenansicht",
            searchAddress: "Adresse suchen",
            chargerRaduis: "Ladekreis für Elektrofahrzeuge (Meilen)",
            legend: "Legende",
            stores: "Geschäfte",
            users: "Benutzer",
            chargers: "Ladestationen",
          },
          sideMenu: {
            home: "Startseite",
            analytics: "Analytik",
            app: "App",
            acquisition: "Kundengewinnung",
            interactions: "Interaktionen",
            devices: "Geräte",
            deviceMap: "Geräteübersicht",
            campaignsOffer: "Kampagnen & Angebote",
            standardCampaigns: "Standardkampagnen",
            poi: "POI",
            visits: "Besuche",
            dwell: "Verweildauer",
            competitors: "Mitbewerber",
            ev: "Elektrofahrzeuge",
            networkChargers: "Netzwerk-Ladegeräte",
            business: "Geschäft",
            businessStats: "Geschäftsstatistiken",
            storeMap: "Kartenansicht",
            manageBrand: "Marke verwalten",
            offers: "Angebote",
            createOffer: "Angebot erstellen",
            currentOffers: "Aktuelle Angebote",
            sendOffers: "Angebote senden",
            instantOffer: "Sofortangebot",
            archivedOffers: "Archivierte Angebote",
            campaigns: "Kampagnen",
            manageCampaigns: "Kampagnen verwalten",
            createCampaign: "Kampagne erstellen",
            locations: "Standorte",
            venues: "Veranstaltungsorte",
            createVenue: "Veranstaltungsort erstellen",
            groupsSubgroups: "Gruppen & Untergruppen",
            createLocation: "Standort erstellen",
          },
          offers: {
            createOffer: "Angebot erstellen",
            notification: "Benachrichtigung",
            notificationTitle: "Benachrichtigungstitel eingeben",
            notificationRule:
              "Bitte geben Sie einen Benachrichtigungstitel ein",
            notificationBody: "Benachrichtigungstext eingeben",
            notifBodyRule: "Bitte geben Sie einen Benachrichtigungstext ein",
            inbox: "Posteingang",
            inboxTitle: "Posteingangstitel eingeben",
            inboxTitleRule: "Bitte geben Sie einen Posteingangstitel ein",
            inboxBody: "Text für Posteingang eingeben",
            inboxBodyRule: "Bitte geben Sie einen Text für den Posteingang ein",
            action: "Aktion",
            actionRule: "Bitte wählen Sie eine Aktion aus",
            openApp: "App öffnen",
            openUrl: "URL öffnen",
            urlRule: "Bitte geben Sie eine URL ein",
            urlValidRule: "Bitte geben Sie eine gültige URL ein",
            enterUrl: "URL eingeben",
            paymentRule: "Bitte geben Sie den Betrag für die Kundenzahlung an",
            customerPayment: "Kundenzahlung",
            clientRule:
              "Bitte geben Sie den Betrag für die Zahlung an den Client an",
            clientPayment: "Clientzahlung",
            showPayment: "Zahlung anzeigen",
            inboxTimeout: "Posteingang-Zeitlimit",
            inboxTimeoutRule:
              "Bitte geben Sie das Zeitlimit für den Posteingang an",
            inboxTimeoutPeriodRule:
              "Bitte wählen Sie den Zeitraum für das Posteingang-Zeitlimit aus",
            redeemTimeout: "Einlöse-Zeitlimit",
            redeemTimeoutRule: "Bitte geben Sie das Einlöse-Zeitlimit an",
            redeemTimePeriodRule:
              "Bitte wählen Sie den Zeitraum für das Einlöse-Zeitlimit aus",
            minutes: "Minuten",
            hours: "Stunden",
            days: "Tage",
            weeks: "Wochen",
            months: "Monate",
            terms: "Nutzungsbedingungen",
            termsBody: "Nutzungsbedingungen eingeben",
            submit: "Senden",
            clear: "Löschen",
          },
          imgUpload: {
            uploadImage: "Bild hochladen",
            imageReqs:
              "Für beste Ergebnisse sollte Ihr Bild ein Seitenverhältnis von 2:1 haben (z. B. 1000px x 500px) und mindestens 1000px breit sein",
            uploadPreview: "Ihr hochgeladenes Bild wird hier angezeigt",
            uploadRules: "Bitte laden Sie ein Bild hoch",
          },
          currentOffers: {
            title: "Aktuelle Angebote",
            close: "Schließen",
            info: "Info",
            edit: "Bearbeiten",
            archive: "Archivieren",
            unarchive: "Wiederherstellen",
            active: "Aktiv",
            inActve: "Inaktiv",
            offerDetails: "Angebotsdetails",
            id: "ID",
            notifTitle: "Benachrichtigungstitel",
            notifBody: "Benachrichtigungstext",
            inboxTitle: "Posteingangstitel",
            inboxBody: "Text für Posteingang",
            action: "Aktion",
            inboxTimout: "Posteingang-Zeitlimit",
            inboxTimeframe: "Posteingang-Zeitrahmen",
            redeemTimout: "Einlöse-Zeitlimit",
            redeemTimeframe: "Einlöse-Zeitrahmen",
            terms: "Nutzungsbedingungen",
          },
          sendOffers: {
            title: "Angebot auf Karte senden",
            competitors: "Bitte wählen Sie Konkurrent(en) aus",
            clearForm: "Formular leeren",
            filter: "Filtern",
            user: "Benutzer",
          },
          instantOffer: {
            title: "Sofortangebot",
            selectCountry: "Bitte wählen Sie ein Land aus",
            selectTimeZone: "Bitte wählen Sie eine Zeitzone aus",
            clearForm: "Formular leeren",
            submit: "Absenden",
          },
          archivedOffers: {
            title: "Archivierte Angebote",
          },
          createCampaign: {
            title: "Kampagne erstellen",
            competitor: "Konkurrent",
            competitorDesc:
              "Senden Sie Angebote an Kunden, wenn sie sich in der Nähe Ihrer Konkurrenten befinden.",
            firstVisit: "Erster Besuch",
            firstVisitDesc:
              "Senden Sie ein Angebot an Ihre Kunden bei ihrem ersten Besuch in einem Ihrer Geschäfte.",
            visits: "Besuche",
            visitsDesc:
              "Senden Sie ein Angebot basierend auf der Anzahl der Besuche eines Kunden in einem Ihrer Geschäfte.",
            rolling: "Fortlaufend",
            rollingDesc:
              "Wie eine Besuchskampagne, aber beschränkt auf einen bestimmten Zeitraum in Tagen.",
            scheduled: "Geplant",
            scheduledDesc:
              "Legen Sie ein Angebot fest, das zu einer bestimmten Zeit jeden Tag während eines angegebenen Zeitraums gestartet wird.",
            homecard: "Homecard",
            homecardDesc: "Legen Sie ein Angebot als Homecard fest",
            campaignTypeRule: "Bitte wählen Sie den Kampagnentyp aus",
            chooseCampaignType: "Wählen Sie den Kampagnentyp aus",
            setNameCriteria: "Name und Kriterien festlegen",
            selectLocations: "Standorte auswählen",
            setCompetitor: "Konkurrent festlegen",
            offerDetails: "Angebotsdetails",
            selectOffer: "Angebot auswählen",
            confirmSelection: "Auswahl bestätigen",
            congratulations:
              "Herzlichen Glückwunsch, Ihre Kampagne ist jetzt aktiv!",
            dismiss: "Schließen",
            proxTrigger: "Nähe-Auslöser",
            proxTriggerRule: "Nähe-Auslöser ist erforderlich",
            campaignName: "Kampagnenname",
            campaignNameRule: "Kampagnenname ist erforderlich",
            dateRange: "Datumsbereich",
            dateRangeRule: "Bitte wählen Sie den Datumsbereich aus!",
            selectGroup: "Eine Gruppe oder mehrere Gruppen auswählen",
            selectGroupRule: "Gruppen sind erforderlich",
            selectGroupPlaceholder:
              "Geben Sie hier Gruppennamen ein oder verwenden Sie die Dropdown-Liste",
            competitors: "Konkurrenten",
            competitorsRule: "Bitte wählen Sie Konkurrenten aus",
            competitorsPlaceholder: "Konkurrenten auswählen",
            expiryTimeout: "Ablaufzeit",
            expiryTimeoutRule: "Bitte geben Sie die Ablaufzeit an",
            expiryTimeframeRule: "Bitte wählen Sie den Ablaufzeitrahmen aus",
            redeemTimeout: "Einlösezeit",
            redeemTimeoutRule: "Bitte geben Sie die Einlösezeit an",
            redeemTimeframeRule: "Bitte wählen Sie den Einlösezeitrahmen aus",
            targetAudience: "Zielgruppe",
            engagement: "Engagement-Methode",
            engagementRule: "Bitte wählen Sie die Engagement-Methode aus",
            ageGroup: "Altersgruppe",
            ageGroupRule: "Bitte wählen Sie die Altersgruppe aus",
            dwellTime: "Verweildauer",
            dwellTimeRule: "Bitte wählen Sie die Verweildauer aus",
            weeklySpend: "Wöchentliche Ausgaben",
            weeklySpendRule: "Bitte wählen Sie die wöchentlichen Ausgaben aus",
            offerSelectRule: "Bitte wählen Sie ein Angebot aus",
            selectOfferList: "Wählen Sie ein vorhandenes Angebot aus",
            checkCampaignDetails: "Bitte überprüfen Sie die Kampagnendetails",
            type: "Typ",
            locationGroups: "Standortgruppen",
            expiryTimout: "Ablaufzeit",
            layer: "Ebene",
            reach: "Reichweite",
            price: "Preis",
            name: "Name",
            proximity: "Nähe",
            noVisits: "Anzahl der erforderlichen Besuche",
            recurring: "Wiederkehrend / Meilenstein",
            duration: "Kampagnendauer / Gesamtzeit",
            pushFirst: "Beim ersten Besuch pushen?",
            no: "Nein",
            yes: "Ja",
            rollingPeriod: "Laufzeit (Tage)",
            periodLength: "Zeitraum (Tage)",
            claimLimit: "Einlöselimit",
            distTime: "Verteilungszeit",
            id: "ID",
            customId: "Benutzerdefinierte ID",
            address: "Adresse",
            city: "Stadt",
            postCode: "Postleitzahl",
            selectedStores:
              "Wählen Sie Standorte aus, an denen Ihre Kampagne aktiv sein wird",
            selectedStoresRule: "Standorte sind erforderlich",
            selectSubGroups:
              "Eine Untergruppe oder mehrere Untergruppen auswählen",
            selectSubGroupsRule: "Untergruppen sind erforderlich",
            selectSubGroupPlace:
              "Geben Sie hier Untergruppennamen ein oder verwenden Sie die Dropdown-Liste",
          },
          controls: {
            prev: "ZURÜCK",
            submit: "SENDEN",
            next: "WEITER",
          },

          manageCampaign: {
            title: "Kampagne verwalten",
            dismiss: "Schließen",
            id: "ID",
            name: "Name",
            type: "Typ",
            dateRange: "Datumsbereich",
            live: "Live",
            status: "Status",
            actions: "Aktionen",
            view: "Ansehen",
            edit: "Bearbeiten",
            delete: "Löschen",
          },

          errorModal: {
            dismiss: "Schließen",
            whoops: "Hoppla",
            generic:
              "Etwas ist schief gelaufen! Bitte versuchen Sie es erneut.",
            message:
              "Beim Laden der Daten ist ein Problem aufgetreten! Bitte laden Sie die Seite neu und versuchen Sie es erneut.",
            stripe:
              "Es gab ein Problem beim Verbinden mit Stripe. Bitte laden Sie die Seite neu und versuchen Sie es erneut.",
            password:
              "Beim Aktualisieren Ihres Passworts ist ein Problem aufgetreten! Bitte laden Sie die Seite neu und versuchen Sie es erneut.",
            offer:
              "Bei dieser Angebot-Funktion ist ein Problem aufgetreten. Bitte laden Sie die Seite neu und versuchen Sie es erneut.",
            image:
              "Es gab ein Problem mit dem Bildupload! Bitte laden Sie die Seite neu und versuchen Sie es erneut.",
            form: "Bitte füllen Sie das Formular aus.",
          },
          successModal: {
            success: "Erfolg!",
          },
          login: {
            hello: "Hallo!",
            welcome:
              "Willkommen bei Ihrem VenueNow-Dashboard! Bitte geben Sie Ihre E-Mail-Adresse unten ein.",
            email: "E-Mail",
            emailRule: "E-Mail-Adresse ist erforderlich",
            next: "Weiter",
            great: "Super!",
            enterPass:
              "Bitte geben Sie Ihr Passwort ein, um sich bei Ihrem Dashboard anzumelden!",
            password: "Passwort",
            passRule: "Passwort ist erforderlich",
            passValidation:
              "Das Passwort muss mindestens 10 Zeichen lang sein und mindestens 1 Zahl und ein Sonderzeichen (!@#$%^&*) enthalten.",
            passProb:
              "Es gab ein Problem! Bitte überprüfen Sie Ihr Passwort und versuchen Sie es erneut!",
            passPlaceholder: "Passwort erstellen...",
            confirmPass: "Passwort bestätigen",
            confirmPassRule: "Bitte bestätigen Sie Ihr Passwort",
            passNoMatch: "Passwörter stimmen nicht überein!",
            createPassword: "Passwort erstellen!",
            createPassProblem:
              "Es gab ein Problem! Bitte geben Sie Ihr Passwort erneut ein und versuchen Sie es erneut!",
            login: "Anmelden",
            forgotPass: "Passwort vergessen? Hier klicken!",
            hmm: "Hmm...",
            noPass:
              "Es scheint, dass für dieses Konto kein Passwort festgelegt ist! Klicken Sie auf die Schaltfläche unten, um einen Link zur Passworterstellung per E-Mail zu erhalten.",
            notActivated: "Es scheint, dass Ihr Konto nicht aktiviert wurde!",
            resetSent: "Reset-Link gesendet!",
            followLink:
              "Folgen Sie dem Link in Ihrer E-Mail und setzen Sie Ihr Passwort zurück!",
            sendPassLink: "Link zur Passworterstellung senden!",
            pleaseContact:
              "Bitte kontaktieren Sie unser Support-Team, und wir werden Ihr Konto so schnell wie möglich aktivieren!",
            success: "Erfolg!",
            passCreated: "Passworterstellung erfolgreich!",
            createPass: "Ein Passwort erstellen",
            createPassEntry:
              "Erstellen Sie ein Passwort für Ihr Konto mit den folgenden Feldern!",
          },
          dateRanges: {
            last7Days: "Letzte 7 Tage",
            last14Days: "Letzte 14 Tage",
            last21Days: "Letzte 21 Tage",
            lastMonth: "Letzter Monat",
            last3Months: "Letzte 3 Monate",
            allTime: "Gesamter Zeitraum",
          },

          // here we will place our translations...
        },
      },
    },
  });

export default i18n;
