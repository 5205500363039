// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ResetPassword-module__container___3PNbP {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center; }\n\n.ResetPassword-module__imageContainer___3YgdG {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  padding-bottom: 10px; }\n  .ResetPassword-module__imageContainer_img___K5jWo {\n    justify-content: center;\n    align-items: center;\n    height: 80px; }\n\n.ResetPassword-module__title___1M49M {\n  text-align: center;\n  font-size: 1.6rem;\n  font-weight: 600;\n  padding: 0px 0 10px 0;\n  margin: 0; }\n\n.ResetPassword-module__messageContainer___ZKG_4 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 1rem;\n  font-weight: 300; }\n  .ResetPassword-module__messageContainer_message___nJyb0 {\n    text-align: center;\n    width: 500px;\n    margin: 0; }\n\n.ResetPassword-module__errorMessage___30Wvu {\n  padding: 0;\n  margin: 0;\n  padding-top: 20px;\n  display: flex;\n  text-align: center;\n  font-size: 0.8rem;\n  color: red; }\n\n@media (max-width: 1060px) {\n  .ResetPassword-module__messageContainer_message___nJyb0 {\n    width: 400px; } }\n\n@media (max-width: 836px) {\n  .ResetPassword-module__messageContainer_message___nJyb0 {\n    width: 300px; } }\n\n@media (max-width: 655px) {\n  .ResetPassword-module__messageContainer_message___nJyb0 {\n    width: 220px; } }\n", ""]);
// Exports
exports.locals = {
	"container": "ResetPassword-module__container___3PNbP",
	"imageContainer": "ResetPassword-module__imageContainer___3YgdG",
	"imageContainer_img": "ResetPassword-module__imageContainer_img___K5jWo",
	"title": "ResetPassword-module__title___1M49M",
	"messageContainer": "ResetPassword-module__messageContainer___ZKG_4",
	"messageContainer_message": "ResetPassword-module__messageContainer_message___nJyb0",
	"errorMessage": "ResetPassword-module__errorMessage___30Wvu"
};
module.exports = exports;
