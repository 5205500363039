import React from 'react';
import RootStore from '../../store';

import ErrorModalHeader from '../../components/Modals/ErrorModal/ErrorModalHeader';
import ErrorModalContent from '../../components/Modals/ErrorModal/ErrorModalContent';
import ErrorModalFooter from '../../components/Modals/ErrorModal/ErrorModalFooter';

export const handleResponseError = (error, message) => {
  const header = <ErrorModalHeader title={message} />;
  const content = <ErrorModalContent error={error} />;
  const footer = <ErrorModalFooter />;

  RootStore.interfaceStore.openModal({
    title: header, content, footer
  });
};

class ResponseError extends Error {
  constructor(error) {
    super(error);
    this.name = `Error ${error.statusCode}`;
    this.message = JSON.stringify({
      statusCode: error.statusCode,
      data: error.message
    });
  }
}

export default ResponseError;
