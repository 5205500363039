import { FC } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { Menu } from "antd";
import { PieChartOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const AnalyticsPoiSubmenu: FC = (props) => {
  const { t } = useTranslation();
  return (
    <Menu.SubMenu
      title={
        <span>
          <PieChartOutlined />

          <span>{t("sideMenu.poi")}</span>
        </span>
      }
      {...props}
    >
      <Menu.Item key="/app/analytics/visits">
        <Link to="/app/analytics/visits">
          <span className="nav-text">{t("sideMenu.visits")}</span>
        </Link>
      </Menu.Item>

      <Menu.Item key="/app/analytics/dwell">
        <Link to="/app/analytics/dwell">
          <span className="nav-text">{t("sideMenu.dwell")}</span>
        </Link>
      </Menu.Item>

      <Menu.Item key="/app/analytics/competitors">
        <Link to="/app/analytics/competitors">
          <span className="nav-text">{t("sideMenu.competitors")}</span>
        </Link>
      </Menu.Item>
    </Menu.SubMenu>
  );
};

export default observer(AnalyticsPoiSubmenu);
