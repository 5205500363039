import { makeAutoObservable } from "mobx";

import { MainRootStore } from "..";

/**
 * @description Fetch & Store - Data for Offer Preview on Baby Dash
 */
class TempBusinessStore {
  businessName: string;
  businessEmail: string;
  addressLine1: string;
  city: string;
  state: string;
  zipCode: string;
  website: string;
  phoneNumber: string | any;
  userPhoneNo: string | any;
  firstName: string | any;
  lastName: string | any;

  rootStore: MainRootStore;

  constructor(rootStore: MainRootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.businessName = "";
    this.businessEmail = "";
    this.addressLine1 = "";
    this.city = "";
    this.state = "";
    this.zipCode = "";
    this.website = "";
    this.phoneNumber = "";
    this.userPhoneNo = "";
    this.lastName = "";
    this.firstName = "";
  }

  setBusinessName = (businessName?: string): void => {
    if (businessName) {
      this.businessName = businessName;
    } else {
      this.businessName = "";
    }
  };
  setBusinessEmail = (businessEmail?: string): void => {
    if (businessEmail) {
      this.businessEmail = businessEmail;
    } else {
      this.businessEmail = "";
    }
  };

  setAddressLine1 = (addressLine1?: string): void => {
    if (addressLine1) {
      this.addressLine1 = addressLine1;
    } else {
      this.addressLine1 = "";
    }
  };

  setCity = (city?: string): void => {
    if (city) {
      this.city = city;
    } else {
      this.city = "";
    }
  };
  setState = (state?: string): void => {
    if (state) {
      this.state = state;
    } else {
      this.state = "";
    }
  };
  setZipCode = (zipCode?: string): void => {
    if (zipCode) {
      this.zipCode = zipCode;
    } else {
      this.zipCode = "";
    }
  };

  setWebsite = (website?: string): void => {
    if (website) {
      this.website = website;
    } else {
      this.website = "";
    }
  };
  setPhoneNumber = (phoneNumber?: string | any): void => {
    if (phoneNumber) {
      this.phoneNumber = phoneNumber;
    } else {
      this.phoneNumber = "";
    }
  };
  setFirstName = (firstName?: string | any): void => {
    if (firstName) {
      this.firstName = firstName;
    } else {
      this.firstName = "";
    }
  };
  setLastName = (lastName?: string | any): void => {
    if (lastName) {
      this.lastName = lastName;
    } else {
      this.lastName = "";
    }
  };
  setUsePhoneNo = (userPhoneNo?: string | any): void => {
    if (userPhoneNo) {
      this.userPhoneNo = userPhoneNo;
    } else {
      this.userPhoneNo = "";
    }
  };

  clearBusinessUser = () => {
    this.businessName = "";
    this.businessEmail = "";
    this.addressLine1 = "";
    this.city = "";
    this.state = "";
    this.zipCode = "";
    this.website = "";
    this.phoneNumber = "";
    this.userPhoneNo = "";
    this.lastName = "";
    this.firstName = "";
  };
}

export default TempBusinessStore;
