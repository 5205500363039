/* eslint-disable react/jsx-wrap-multilines */
import { FC } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { Menu } from "antd";
import { ApiOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const AnalyticsEvSubmenu: FC = (props) => {
  const { t } = useTranslation();
  return (
    <Menu.SubMenu
      title={
        <span>
          <ApiOutlined />
          <span>{t("sideMenu.ev")}</span>
        </span>
      }
      {...props}
    >
      <Menu.Item key="/app/analytics/ev/chargers">
        <Link to="/app/analytics/ev/chargers">
          <span className="nav-text">{t("sideMenu.networkChargers")}</span>
        </Link>
      </Menu.Item>
    </Menu.SubMenu>
  );
};

export default observer(AnalyticsEvSubmenu);
