import { FC, useEffect, useState } from "react";
import { Button, Divider, Form, Input, Space } from "antd";
import styles from "./UpdateDetails.module.scss";

import SectionHeader from "../../../components/Blocks/SectionHeader/SectionHeader";
import Card from "../../../components/Blocks/Card/Card";
import {
  useEditUserDetails,
  useGetBusinessUserDetails,
} from "../../../hooks/registrationHooks/registration.hooks";

import { useStores } from "../../../hooks/useStores";
import { handlePwSuccessModal } from "../../../utils/modals/simpleModals";

const UpdateDetails: FC = () => {
  const { userStore } = useStores();
  const [form] = Form.useForm();
  const [currentValues, setCurrentValues] = useState<any>();

  const userEmail = userStore.user?.username;

  const { data } = useGetBusinessUserDetails(userEmail);

  const editUserDetails = useEditUserDetails();

  useEffect(() => {
    if (data !== undefined) {
      setCurrentValues({
        companyName: data?.companyName,
        addressLine1: data?.addressLine1,
        addressLine2: data?.addressLine2,
        website: data?.website,
        city: data?.city,
        state: data?.state,
        postcode: data?.postcode,
        emailAddress: data?.emailAddress,
        phoneNumber: data?.phoneNumber,
      });
    }
  }, [data]);

  const onFinish = async () => {
    form.validateFields();

    editUserDetails.mutate(currentValues, {
      onSuccess: () => {
        handlePwSuccessModal(
          "Success!",
          "Business Account Details habe been updated! "
        );
      },
    });
  };

  return (
    <Space direction="vertical">
      <header className={styles.header}>
        <SectionHeader>Update your Account Details</SectionHeader>
      </header>

      <Divider />
      {currentValues && (
        <Form
          layout="vertical"
          onFinish={onFinish}
          className={styles.formContainer}
          initialValues={currentValues}
        >
          <div className={styles.section}>
            <Card>
              <Form.Item
                name="companyName"
                label="Company Name"
                rules={[
                  {
                    required: true,
                    message: "Company Name is required",
                  },
                ]}
              >
                <Input
                  placeholder="Required"
                  onChange={(e) =>
                    setCurrentValues({
                      ...currentValues,
                      companyName: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Card>

            <Card>
              <Form.Item
                name="addressLine1"
                label="Address Line 1"
                rules={[
                  {
                    required: true,
                    message: "Address is required",
                  },
                ]}
              >
                <Input
                  placeholder="Required"
                  onChange={(e) =>
                    setCurrentValues({
                      ...currentValues,
                      addressLine1: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Card>
          </div>

          <div className={styles.section}>
            <Card>
              <Form.Item name="website" label="Website URL">
                <Input
                  placeholder="Optional"
                  onChange={(e) =>
                    setCurrentValues({
                      ...currentValues,
                      website: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Card>

            <Card>
              <Form.Item
                name="city"
                label="City"
                rules={[
                  {
                    required: true,
                    message: "City is required",
                  },
                ]}
              >
                <Input
                  placeholder="Required"
                  onChange={(e) =>
                    setCurrentValues({
                      ...currentValues,
                      city: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Card>
          </div>

          <div className={styles.section}>
            <Card>
              <Form.Item
                name="state"
                label="State"
                rules={[
                  {
                    required: true,
                    message: "State is required",
                  },
                ]}
              >
                <Input
                  placeholder="Required"
                  onChange={(e) =>
                    setCurrentValues({
                      ...currentValues,
                      state: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Card>

            <Card>
              <Form.Item
                name="postcode"
                label="Zip Code"
                rules={[
                  {
                    required: true,
                    message: "Zip Code is required",
                  },
                ]}
              >
                <Input
                  placeholder="Required"
                  onChange={(e) =>
                    setCurrentValues({
                      ...currentValues,
                      postcode: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Card>
          </div>

          <div className={styles.section}>
            <Card>
              <Form.Item
                name="emailAddress"
                label="Email Address"
                rules={[
                  {
                    required: true,
                    message: "Email Address is required",
                  },
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                ]}
              >
                <Input
                  placeholder="Required"
                  onChange={(e) =>
                    setCurrentValues({
                      ...currentValues,
                      emailAddress: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Card>

            <Card>
              <Form.Item
                name="phoneNumber"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: "Phone Number is required",
                  },
                ]}
              >
                <Input
                  placeholder="Required"
                  onChange={(e) =>
                    setCurrentValues({
                      ...currentValues,
                      phoneNumber: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Card>
          </div>

          <Button
            type="primary"
            htmlType="submit"
            style={{ paddingLeft: "10px", paddingRight: "10px", width: "100%" }}
          >
            Update Account Details
          </Button>
        </Form>
      )}
    </Space>
  );
};

export default UpdateDetails;
