/* eslint-disable import/no-useless-path-segments */
import { MIN_OFFER_DURATION_IN_DAYS } from "../../components/Forms/QcCampaignWizard/QcCampaignPages/QcCampaignOfferExpiry/utils/DateTimeOptions";
import { OfferTimeOptions } from "./../../components/Forms/QcCampaignWizard/QcCampaignPages/QcCampaignOfferExpiry/utils/types";

interface PreviewOfferStoreDefaultValues {
  campaignName: string;
  campaignImage: string;
  previewOffer: string;
  notificationHeader: string;
  notificationMessage: string;
  terms: string;
  expiryDate: string;
  offerExpiryDate: OfferTimeOptions;
}

export const PreviewOfferStoreDefaultValues: PreviewOfferStoreDefaultValues = {
  campaignName: "Campaign Name",
  campaignImage: "No Image",
  previewOffer: "Offer",
  notificationHeader: "Notification Header",
  notificationMessage: "Notification Message",
  terms: " - No Terms & Conditions Set - ",
  expiryDate: "31 Dec, 2023",
  offerExpiryDate: {
    days: MIN_OFFER_DURATION_IN_DAYS,
    hours: "0",
    minutes: "0",
    seconds: "0",
  },
};
