import { render } from "react-dom";
import { Provider as MobxProvider } from "mobx-react";
import Chart from "chart.js/auto";

import { Provider as ReduxProvider } from "react-redux";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import RootStore, { StoreContext } from "./store";
import { ConfigProvider } from "antd";
import App from "./App.tsx";
import reduxStore from "./store/redux/store";
import "./styles/custom/App.scss";
// import 'mobx-react-lite/batchingForReactDom';

// 3rd Party Scripts
import "./plugins/chartjs-plugin-labels";

/**
 * TODO: REVISE AND DELETE THE FOLLOWING
 */
import "./styles/vendors/antd/antd.less";
import "./styles/vendors/bootstrap/bootstrap.scss";
import "./styles/layout.scss";
import "./styles/theme.scss";
import "./styles/ui.scss";
import "./styles/vendors.scss";
import "leaflet-editable"; // Must imported before "leaflet.path.drag"
import "leaflet.path.drag";
import "leaflet.heat";
// Below breaks the app until Leaflet refactor  complete
// import "leaflet/dist/leaflet"; // Must be Imported first
// import "leaflet-search";
// import "leaflet.markercluster"; // Must be Imported last
// import "leaflet-search/dist/leaflet-search.src.css";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster/dist/MarkerCluster.css";

// Chart.defaults.doughnut.defaultFontColor = '#fff';
Chart.defaults.color = "rgb(255, 255, 255)";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const rootDiv = document.getElementById("root");

render(
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools />
    <ReduxProvider store={reduxStore}>
      <StoreContext.Provider value={RootStore}>
        {/* Need to remove <MobxProvider /> component when mobx6 refactoring will be complete */}
        <MobxProvider {...RootStore}>
          <App />
        </MobxProvider>
      </StoreContext.Provider>
    </ReduxProvider>
  </QueryClientProvider>,
  rootDiv
);
