/* eslint-disable implicit-arrow-linebreak */
import { Modal } from "antd";
import styles from "./simpleModals.module.scss";

export const handleErrorModal = (title: string, content: string) =>
  Modal.error({
    className: "error-modal",
    title,
    content: (
      <div className="error-modal_content">
        <div className="error-modal_text">{content}</div>

        <div className="error-modal_icon" />
      </div>
    ),
    okText: <div className={styles.okText}>Dismiss</div>,
    centered: true,
  });

export const handleErrorModalTrans = (
  title: string,
  content: string,
  dismiss: string
) =>
  Modal.error({
    className: "error-modal",
    title,
    content: (
      <div className="error-modal_content">
        <div className="error-modal_text">{content}</div>

        <div className="error-modal_icon" />
      </div>
    ),
    okText: <div className={styles.okText}>{dismiss}</div>,
    centered: true,
  });

export const handleSuccessModal = (title: string, content: string) =>
  Modal.success({
    className: "success-modal",
    title,
    content: (
      <div className="success-modal_content">
        <div className="success-modal_text">{content}</div>

        <div className="success-modal_icon" />
      </div>
    ),
    okText: <div className={styles.okText}>Dismiss</div>,
    centered: true,
  });

export const handlePwSuccessModal = (title: string, content: string) =>
  Modal.success({
    className: "success-modal",
    title,
    content: (
      <div className="success-modal_content">
        <div className="success-modal_text">{content}</div>

        <div className="success-modal_icon" />
      </div>
    ),
    okText: <div className={styles.okText}>Dismiss</div>,
    centered: true,
  });

type TConfirmModalProps = {
  title: string;
  onOk: () => void;
  okText: string;
  cancelText: string;
};

export const handleConfirmModal = ({
  title,
  onOk,
  okText,
  cancelText,
}: TConfirmModalProps) =>
  Modal.confirm({
    className: "confirm-modal",
    title,
    okText,
    cancelText,
    onOk,
    centered: true,
  });
