// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UpdateDetails-module__header___fZ4w7 {\n  padding-top: 25px;\n  margin-bottom: -10px; }\n\n.UpdateDetails-module__formContainer___3SkmJ {\n  width: 100%;\n  padding-bottom: 5px; }\n\n.UpdateDetails-module__section___3_cTf {\n  width: 100%;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  grid-gap: 1rem; }\n", ""]);
// Exports
exports.locals = {
	"header": "UpdateDetails-module__header___fZ4w7",
	"formContainer": "UpdateDetails-module__formContainer___3SkmJ",
	"section": "UpdateDetails-module__section___3_cTf"
};
module.exports = exports;
