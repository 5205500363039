import { FC } from "react";
import { observer } from "mobx-react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { MoneyCollectOutlined } from "@ant-design/icons";

const QcTempSidebar: FC = () => (
  <Menu mode="inline">
    <Menu.Item key="/app">
      <Link to="/app">
        <MoneyCollectOutlined />

        <span className="nav-text">Complete Account</span>
      </Link>
    </Menu.Item>
  </Menu>
);

export default observer(QcTempSidebar);
