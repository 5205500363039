import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { ReloadOutlined, LoadingOutlined } from "@ant-design/icons";
import presetDates from "../../utils/dateTime/presetDates";
import DateInput from "./DateInput";
import { shallowCopy } from "../../utils";
import { ConfigProvider } from "antd";
import enUS from "antd/es/locale/en_US";
import deDE from "antd/es/locale/de_DE";
import i18n from "../../i18n";

@inject(
  "routerStore",
  "analyticsStore",
  "campaignStore",
  "interfaceStore",
  "userStore",
  "locationStore"
)
@observer
export class MasterDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  page = () => shallowCopy(this.props.interfaceStore.navBuffer).pop();

  onDateChange = async (dates) => {
    this.props.analyticsStore.setMasterDate(dates);
    const dateFrom = dates[0];
    const dateTo = dates[1];
    const params = {
      dateFrom,
      dateTo,
      refresh: true,
    };
    const path = this.props.routerStore.history.location.pathname;
  };

  render() {
    return (
      <div className="MasterDatePicker flex-center">
        <div
          className={`${
            this.state.disabled ? "invisible" : "visible"
          } d-inline px-3`}
        >
          {!this.state.loading ? (
            <ReloadOutlined
              onClick={() =>
                this.onDateChange(this.props.analyticsStore.masterDate)
              }
              style={
                this.props.interfaceStore.theme === "light"
                  ? {
                      color: "var(--color-black)",
                    }
                  : {
                      color: "var(--color-white)",
                    }
              }
            />
          ) : (
            <LoadingOutlined />
          )}
        </div>

        <div className="d-inline">
          <ConfigProvider locale={i18n.language === "de" ? deDE : enUS}>
            <DateInput
              className="chart-date-picker"
              type="chart"
              allowClear={false}
              defaultValue={this.props.analyticsStore.masterDate}
              format="YYYY-MM-DD"
              disabled={this.state.disabled}
              onChange={(dates) => this.onDateChange(dates)}
              placeholder={[" ", " "]}
              style={
                this.props.interfaceStore.theme === "light"
                  ? {
                      backgroundColor: "var(--color-primary)",
                    }
                  : {
                      backgroundColor: "var(--color-black)",
                    }
              }
            />
          </ConfigProvider>
        </div>
      </div>
    );
  }
}

export default observer(MasterDatePicker);
