import { FC } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { Menu } from "antd";
import { WifiOutlined } from "@ant-design/icons";

const WifiSubmenu: FC = (props) => {
  return (
    <Menu.SubMenu
      title={
        <span>
          <WifiOutlined />

          <span>VenueNow Connect</span>
        </span>
      }
      {...props}
    >
      <Menu.Item key="/app/analytics/wifi">
        <Link to="/app/analytics/wifi">
          <span className="nav-text">Connect Analytics</span>
        </Link>
      </Menu.Item>
    </Menu.SubMenu>
  );
};

export default observer(WifiSubmenu);
