import { FC } from 'react';
import { Space, Input } from 'antd';

import Card from '../../../../components/Blocks/Card/Card';

const Inputs: FC = () => {
  const { Password, Search } = Input;

  return (
    <Space size={16} direction="vertical">

      <Card>
        <Input />
      </Card>

      <Card>
        <Input status="warning" placeholder="Warning" />
      </Card>

      <Card>
        <Input status="error" placeholder="Error" />
      </Card>

      <Card>
        <Input addonBefore="http://" status="warning" />
      </Card>

      <Card>
        <Input addonAfter=".com" status="error" />
      </Card>

      <Card>
        <Input addonBefore="http://" addonAfter=".com" />
      </Card>

      <Card>
        <Input
          addonAfter=".com"
          suffix="-yeah"
          status="warning"
        />
      </Card>

      <Card>
        <Input
          addonBefore="http://"
          prefix="-yeah"
          status="error"
        />
      </Card>

      <Card>
        <Input
          addonBefore="http://"
          addonAfter=".com"
          suffix="-yeah"
          status="warning"
        />
      </Card>

      <Card>
        <Input suffix=".com" />
      </Card>

      <Card>
        <Password placeholder="input password" />
      </Card>

      <Card>
        <Search
          placeholder="input search text"
          allowClear
        />
      </Card>

      <Card>
        <Search
          placeholder="input search text"
          enterButton="Search"
          suffix="+"
        />
      </Card>
    </Space>
  );
};

export default Inputs;
