import { ComponentType } from 'react';
import { Route } from 'react-router-dom';

type TRoute = {
  path: string;
  component: ComponentType
}

export const mapRoutes = (routes: TRoute[]) => routes.map(route => (
  <Route
    path={route.path}
    exact
    component={route.component}
    key={route.path}
  />
));
