import { FC } from "react";
import styles from "./LoginHeaders.module.scss";
import { useTranslation } from "react-i18next";

interface ILoginHeaders {
  forgotPass: any;
  data: any;
}

const LoginHeaders: FC<ILoginHeaders> = ({ forgotPass, data }) => {
  const { t } = useTranslation();
  const message = () => {
    {
      if (!data && !forgotPass) {
        return (
          <>
            <h2 className={styles.title}>{t("login.hello")}</h2>
            <div className={styles.messageContainer}>
              <p className={styles.messageContainer_message}>
                {t("login.welcome")}
              </p>
            </div>
          </>
        );
      } else if (
        data?.isEnabled === true &&
        data?.hasPassword === true &&
        !forgotPass
      ) {
        return (
          <>
            <h2 className={styles.title}>{t("login.great")}</h2>
            <div className={styles.messageContainer}>
              <p className={styles.messageContainer_message}>
                {t("login.enterPass")}
              </p>
            </div>
          </>
        );
      } else if (
        data?.isEnabled === true &&
        data?.hasPassword === false &&
        !forgotPass
      ) {
        return (
          <>
            <h2 className={styles.title}>{t("login.hmm")}</h2>
            <div className={styles.messageContainer}>
              <p className={styles.messageContainer_message}>
                {t("login.noPass")}
              </p>
            </div>
          </>
        );
      } else if (
        data?.isEnabled === false &&
        data?.hasPassword === false &&
        !forgotPass
      ) {
        return (
          <>
            <h2 className={styles.title}>{t("login.hmm")}</h2>
            <div className={styles.messageContainer}>
              <p className={styles.messageContainer_message}>
                {t("login.notActivated")}
              </p>
            </div>
          </>
        );
      } else {
        return (
          <>
            <h2 className={styles.title}>{t("login.resetSent")}</h2>
            <div className={styles.messageContainer}>
              <p className={styles.messageContainer_message}>
                {t("login.followLink")}
              </p>
            </div>
          </>
        );
      }
    }
  };

  return <>{message()}</>;
};

export default LoginHeaders;
