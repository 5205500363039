import { FC } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { Menu } from "antd";
import { BankOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const AnalyticsBusinessSubMenu: FC = (props) => {
  const { t } = useTranslation();
  return (
    <Menu.SubMenu
      title={
        <span>
          <BankOutlined />
          <span>{t("sideMenu.business")}</span>
        </span>
      }
      {...props}
    >
      <Menu.Item key="/app/analytics/business/business-stats">
        <Link to="/app/analytics/business/business-stats">
          <span className="nav-text">{t("sideMenu.businessStats")}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/app/analytics/business/store-map">
        <Link to="/app/analytics/business/store-map">
          <span className="nav-text">{t("sideMenu.storeMap")}</span>
        </Link>
      </Menu.Item>
    </Menu.SubMenu>
  );
};

export default observer(AnalyticsBusinessSubMenu);
