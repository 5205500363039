import { FC } from 'react';
import { Space, Select } from 'antd';

import Card from '../../../../components/Blocks/Card/Card';

const Selects: FC = () => {
  const { Option } = Select;

  return (
    <Space size={16} direction="vertical">

      <Card>
        <Select defaultValue="lucy">
          <Option value="lucy">Lucy</Option>
          <Option value="brian">Brian</Option>
        </Select>
      </Card>

      <Card>
        <Select mode="multiple" defaultValue="lucy" allowClear>
          <Option value="lucy">Lucy</Option>
          <Option value="brian">Brian</Option>
        </Select>
      </Card>

      <Card>
        <Select disabled>
          <Option value="lucy">Lucy</Option>
          <Option value="brian">Brian</Option>
        </Select>
      </Card>

      <Card>
        <Select status="error">
          <Option value="lucy">Lucy</Option>
          <Option value="brian">Brian</Option>
        </Select>
      </Card>

      <Card>
        <Select status="warning">
          <Option value="lucy">Lucy</Option>
          <Option value="brian">Brian</Option>
        </Select>
      </Card>
    </Space>
  );
};

export default Selects;
