// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ColorOptions-module__dropDown___3vxvM {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n  padding-bottom: 5px; }\n\n.ColorOptions-module__lightModeBox___1ye-g {\n  width: 85px;\n  height: 28px;\n  border: solid 1px var(--color-primary);\n  border-radius: 6px;\n  cursor: pointer;\n  background: var(--color-white);\n  transition: var(--transition); }\n\n.ColorOptions-module__lightModeBox___1ye-g:hover {\n  border-color: var(--color-primary);\n  box-shadow: var(--focused-shadow); }\n\n.ColorOptions-module__darkModeBox___19_ez {\n  width: 85px;\n  height: 28px;\n  border: solid 1px var(--color-primary);\n  border-radius: 6px;\n  cursor: pointer;\n  background: var(--color-light-black);\n  transition: var(--transition); }\n\n.ColorOptions-module__darkModeBox___19_ez:hover {\n  border-color: var(--color-light-black);\n  box-shadow: var(--focused-shadow); }\n", ""]);
// Exports
exports.locals = {
	"dropDown": "ColorOptions-module__dropDown___3vxvM",
	"lightModeBox": "ColorOptions-module__lightModeBox___1ye-g",
	"darkModeBox": "ColorOptions-module__darkModeBox___19_ez"
};
module.exports = exports;
