// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UserDropDown-module__userSettings___2a6KE {\n  color: var(--color-lighter-grey);\n  margin: 0;\n  padding-top: 6px;\n  justify-content: center;\n  align-items: center; }\n\n.UserDropDown-module__logOut___ZYT_J {\n  padding-left: 20px; }\n\n.UserDropDown-module__entryField___3cHmz {\n  display: flex;\n  align-items: center;\n  justify-content: center; }\n", ""]);
// Exports
exports.locals = {
	"userSettings": "UserDropDown-module__userSettings___2a6KE",
	"logOut": "UserDropDown-module__logOut___ZYT_J",
	"entryField": "UserDropDown-module__entryField___3cHmz"
};
module.exports = exports;
