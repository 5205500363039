// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoginHeaders-module__messageContainer___32xnq {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  font-size: 1rem;\n  font-weight: 300;\n  width: 90%;\n  padding-bottom: 10px; }\n  .LoginHeaders-module__messageContainer_message___ZQcaX {\n    text-align: center;\n    width: 480px; }\n\n@media (max-width: 1000px) {\n  .LoginHeaders-module__messageContainer_message___ZQcaX {\n    width: 400px; } }\n\n@media (max-width: 810px) {\n  .LoginHeaders-module__messageContainer_message___ZQcaX {\n    width: 300px; } }\n\n@media (max-width: 570px) {\n  .LoginHeaders-module__messageContainer_message___ZQcaX {\n    width: 220px; } }\n", ""]);
// Exports
exports.locals = {
	"messageContainer": "LoginHeaders-module__messageContainer___32xnq",
	"messageContainer_message": "LoginHeaders-module__messageContainer_message___ZQcaX"
};
module.exports = exports;
