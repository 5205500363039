import { ReactNode } from "react";
import { makeAutoObservable, runInAction } from "mobx";
import { Select } from "antd";
import { Moment } from "moment";
import { Request, responseFail, MainRootStore } from "..";
import formatDate from "../../utils/dateTime/formatDate";
import axios from "../../utils/axios";
import ResponseError from "../../utils/axios/ResponseError";
import { TCoordinates } from "../../utils/keplerMap/helpers";
import { ICoordinate } from "../../utils/googleMaps/helpers";
import { transformClientStore } from "../../utils/store/locationStoreHelpers";

import * as Types from "./locationTypes";
import { handleError } from "../../utils/store/storeHelpers";

/**
 * @description Fetch & Store - Devices, stores, groups & subgroup locations
 */
class LocationStore {
  rootStore: MainRootStore;

  // ------------------ REFACTORED ----------------
  loading = false;

  competitorStoresMap: Types.TCompetitorStoresMap = {
    center: null,
    error: null,
    loading: false,
    selected: [],
    stores: null,
    zoom: null,
  };

  devicesMap: Types.TDevicesMap = {
    data: null,
    date: null,
    error: null,
    loading: true,
  };

  clientStores: Types.TClientStores = {
    loading: false,
    error: null,
    stores: [],
  };

  selectedClientStore: Types.TSelectedClientStore = {
    store: null,
    loading: false,
    error: null,
  };

  usersMap: Types.TUsersMap = {
    error: null,
    loading: false,
    users: [],
  };

  groupList: Types.TGroupList = {
    groups: [],
    loading: false,
    error: null,
  };

  countryList: Types.TCountryList = {
    countries: [],
    loading: false,
  };

  subgroupList: Types.TSubgroupList = {
    subgroups: [],
    loading: false,
    error: null,
  };

  timezoneList: Types.TTimezoneList = {
    timezones: [],
    loading: false,
  };

  // -------------- NEEDS REFACTORING -------------
  error: any = null;

  clientStoresList: any = null;

  clientGroupsList: any = null;

  storesBySubgroup: any = {
    stores: [],
    loading: false,
    error: null,
  };

  subgroupsList: any = null;

  campaignCountries: any = [];

  campaignCountriesLoading: boolean = false;

  countriesList: any = null;

  campaignTimeZones: any = [];

  campaignTimeZonesLoading: boolean = false;

  venueLocation: any = null;

  venueOpenTimes: any = null;

  editableVenueLocation: any = null;

  editableSubgroup: any = null;

  editableGroup: any = null;

  constructor(rootStore: MainRootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  // ------------------ REFACTORED ----------------

  /**
   * Shared states across multiple components
   */
  hasStores = (): boolean =>
    !!this.clientStoresList &&
    Array.isArray(this.clientStoresList) &&
    !!this.clientStoresList.length;

  setOfferMapCompetitors = (idArray: string[]): void => {
    this.competitorStoresMap.selected = idArray;
  };

  getCompetitorStoresMap = async (
    ids: string | string[],
    coordinates: TCoordinates | ICoordinate
  ): Promise<void> => {
    const idArray = !Array.isArray(ids) ? [ids] : ids;

    const hasCompetitors = ids && idArray.length;

    if (!hasCompetitors) {
      this.competitorStoresMap.stores = {};

      return;
    }

    const competitors = idArray.map((id) => parseInt(id, 10));

    const url = this.rootStore.configStore.API.dashboard.locations.competitors;

    try {
      this.competitorStoresMap.loading = true;
      this.competitorStoresMap.error = null;

      const { data }: { data: Types.ICompetitorStores } = await axios.post(
        url,
        {
          coordinates,
          competitors,
        }
      );

      runInAction(() => {
        this.competitorStoresMap.loading = false;
        this.competitorStoresMap.stores = data;
      });
    } catch (error: any) {
      runInAction(() => {
        this.competitorStoresMap.loading = false;
        const statusCode = error.response.data.statusCode;
        const message = error.response.data.message;
        this.competitorStoresMap.error = {
          statusCode,
          message,
        };

        throw new ResponseError(this.competitorStoresMap.error);
      });
    }
  };

  getDevicesMap = async (dateFrom: Moment, dateTo: Moment): Promise<void> => {
    this.devicesMap.loading = true;
    const url = this.rootStore.configStore.API.dashboard.analytics.heatmap
      .replace("%DATE_FROM%", formatDate(dateFrom))
      .replace("%DATE_TO%", formatDate(dateTo));

    try {
      const { data }: { data: Types.TDeviceData[] } = await axios.get(url);

      runInAction(() => {
        this.devicesMap.loading = false;
        this.devicesMap.data = data;
        this.devicesMap.date = [dateFrom, dateTo];
      });
    } catch (error: any) {
      runInAction(() => {
        this.devicesMap.loading = false;
        const statusCode = error.response.data.statusCode;
        const message = error.response.data.message;
        this.devicesMap.error = {
          statusCode,
          message,
        };

        throw new ResponseError(this.error);
      });
    }
  };

  fetchClientStores = async (): Promise<void> => {
    const url = this.rootStore.configStore.API.dashboard.locations.client;

    try {
      this.clientStores.loading = true;
      this.clientStores.error = null;
      const { data }: { data: Types.IClientStore[] } = await axios.get(url);

      runInAction(() => {
        this.clientStores.loading = false;
        this.clientStores.stores = data.map(
          (store: Types.IClientStore): Types.ITranfromedClientStore =>
            transformClientStore(store)
        );
      });
    } catch (error: any) {
      runInAction(() => {
        this.clientStores.loading = false;
        const statusCode = error.response.data.statusCode;
        const message = error.response.data.message;
        this.clientStores.error = {
          statusCode,
          message,
        };

        throw new ResponseError(this.error);
      });
    }
  };

  getUsersByLatLng = async (coordinates: TCoordinates): Promise<void> => {
    const url = this.rootStore.configStore.API.dashboard.locations.usersInArea;

    try {
      this.usersMap.loading = true;
      this.usersMap.error = null;
      const { data }: { data: Types.IUser[] } = await axios.post(url, {
        coordinates,
      });

      runInAction(() => {
        this.usersMap.users = data;
        this.usersMap.loading = false;
      });
    } catch (error: any) {
      runInAction(() => {
        this.usersMap.loading = false;
        const statusCode = error.response.data.statusCode;
        const message = error.response.data.message;
        this.usersMap.error = {
          statusCode,
          message,
        };

        throw new ResponseError(this.error);
      });
    }
  };

  getGroups = async (): Promise<void> => {
    const url = this.rootStore.configStore.API.dashboard.locations.groups;

    try {
      this.groupList.loading = true;
      this.groupList.error = null;

      const { data } = await axios.get(url);

      runInAction(() => {
        this.groupList.groups = data.map((group: any) => ({
          ...group,
          key: group.id,
        }));

        this.groupList.loading = false;
      });
    } catch (error: any) {
      runInAction(() => {
        this.groupList.loading = false;
        const statusCode = error.response.data.statusCode;
        const message = error.response.data.message;
        this.groupList.error = {
          statusCode,
          message,
        };

        throw new ResponseError(this.error);
      });
    }
  };

  getSubgroups = async (groupIds: number[]): Promise<void> => {
    if (!groupIds.length) {
      this.subgroupList.subgroups = [];
      this.subgroupList.error = null;
      return;
    }

    const url = this.rootStore.configStore.API.dashboard.locations.subgroups;

    try {
      this.subgroupList.loading = true;
      this.subgroupList.error = null;

      const { data } = await axios.post(url, {
        groupIds,
      });

      runInAction(() => {
        this.subgroupList.subgroups = data.map((group: any) => ({
          ...group,
          key: group.id,
        }));

        this.subgroupList.loading = false;
      });
    } catch (error: any) {
      runInAction(() => {
        this.subgroupList.loading = false;
        const statusCode = error.response.data.statusCode;
        const message = error.response.data.message;
        this.subgroupList.error = {
          statusCode,
          message,
        };

        throw new ResponseError(this.error);
      });
    }
  };

  getStoresBySubgroup = async (subgroupIds: number[]) => {
    if (!subgroupIds.length) {
      this.storesBySubgroup.stores = [];
      this.storesBySubgroup.error = null;
      return;
    }

    const url =
      this.rootStore.configStore.API.dashboard.locations.storesBySubgroup;

    try {
      this.storesBySubgroup.loading = true;
      this.storesBySubgroup.error = null;

      const { data } = await axios.post(url, {
        subgroupIds,
      });

      runInAction(() => {
        this.storesBySubgroup.stores = data.map((store: any) => ({
          ...store,
          key: store.id,
        }));

        this.storesBySubgroup.loading = false;
      });
    } catch (error: any) {
      runInAction(() => {
        this.storesBySubgroup.loading = false;
        const statusCode = error.response.data.statusCode;
        const message = error.response.data.message;
        this.storesBySubgroup.error = {
          statusCode,
          message,
        };

        throw new ResponseError(this.error);
      });
    }
  };

  toggleGroupStatus = async (groupIds: number[]): Promise<void> => {
    const url = this.rootStore.configStore.API.dashboard.locations.toggleGroups;

    try {
      this.groupList.loading = true;
      await axios.post(url, {
        groupIds,
      });

      runInAction(() => {
        this.groupList.loading = false;
        this.groupList.groups = this.groupList.groups.map((group) => {
          if (groupIds.includes(group.id)) {
            const active = group.active === 1 ? 0 : 1;
            return {
              ...group,
              active,
            };
          }

          return group;
        });
      });
    } catch (error: any) {
      runInAction(() => {
        this.groupList.loading = false;
        handleError(error);
      });
    }
  };

  toggleSubgroupStatus = async (subgroupIds: number[]): Promise<void> => {
    const url =
      this.rootStore.configStore.API.dashboard.locations.toggleSubgroup;

    try {
      this.subgroupList.loading = true;
      await axios.post(url, {
        subgroupIds,
      });

      runInAction(() => {
        this.subgroupList.loading = false;
        this.subgroupList.subgroups = this.subgroupList.subgroups.map(
          (subgroup) => {
            if (subgroupIds.includes(subgroup.id)) {
              const active = subgroup.active === 1 ? 0 : 1;
              return {
                ...subgroup,
                active,
              };
            }

            return subgroup;
          }
        );
      });
    } catch (error) {
      runInAction(() => {
        this.subgroupList.loading = false;
        handleError(error);
      });
    }
  };

  toggleStore = async (storeIds: string[]): Promise<void> => {
    const url = this.rootStore.configStore.API.dashboard.locations.toggleStore;

    try {
      this.clientStores.loading = true;
      await axios.post(url, {
        storeIds,
      });

      runInAction(() => {
        this.clientStores.stores = this.clientStores.stores.map((store) => {
          if (storeIds.includes(store.id!)) {
            return {
              ...store,
              active: store.active === 0 ? 1 : 0,
            };
          }

          return store;
        });
      });
    } catch (error) {
      runInAction(() => {
        handleError(error);
      });
    } finally {
      runInAction(() => {
        this.clientStores.loading = false;
      });
    }
  };

  editGroup = async (group: Types.IGroup): Promise<void> => {
    const url = this.rootStore.configStore.API.dashboard.locations.findGroup;

    try {
      this.groupList.loading = true;
      await axios.put(`${url}/${group.id}`, group);

      runInAction(() => {
        this.groupList.loading = false;
        const idx = this.groupList.groups.findIndex(
          (item) => item.id === group.id
        );
        this.groupList.groups[idx] = group;
      });
    } catch (error: any) {
      runInAction(() => {
        this.groupList.loading = false;
        handleError(error);
      });
    }
  };

  addGroup = async (group: Types.IGroup): Promise<void> => {
    const url = this.rootStore.configStore.API.dashboard.locations.addGroup;

    try {
      this.groupList.loading = true;
      const { data }: { data: Types.IGroup } = await axios.post(url, group);

      runInAction(() => {
        this.groupList.loading = false;
        this.groupList.groups.push(data);
      });
    } catch (error: any) {
      runInAction(() => {
        this.groupList.loading = false;

        handleError(error);
      });
    }
  };

  getCountryList = async (): Promise<void> => {
    const url = this.rootStore.configStore.API.dashboard.locations.countries;

    try {
      this.countryList.loading = true;
      const { data }: { data: Types.ICountry[] } = await axios.get(url);

      runInAction(() => {
        this.countryList.loading = false;
        this.countryList.countries = data;
      });
    } catch (error) {
      runInAction(() => {
        this.countryList.loading = false;
        handleError(error);
      });
    }
  };

  editSubgroup = async ({
    subgroup,
    groupId,
  }: {
    subgroup: Types.ISubgroup;
    groupId?: number | null;
  }): Promise<void> => {
    const url = this.rootStore.configStore.API.dashboard.locations.editSubgroup;

    try {
      this.subgroupList.loading = true;
      await axios.put(`${url}/${subgroup.id}`, subgroup);

      runInAction(() => {
        this.subgroupList.loading = false;

        if (groupId && groupId !== subgroup.store_group_id) {
          this.subgroupList.subgroups = this.subgroupList.subgroups.filter(
            (item) => item.id !== subgroup.id
          );
        } else {
          const idx = this.subgroupList.subgroups.findIndex(
            (item) => item.id === subgroup.id
          );
          this.subgroupList.subgroups[idx] = subgroup;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.subgroupList.loading = false;
        handleError(error);
      });
    }
  };

  addSubgroup = async (subgroup: Types.ISubgroup) => {
    const url = this.rootStore.configStore.API.dashboard.locations.addSubgroup;

    try {
      const { data }: { data: Types.ISubgroup } = await axios.post(
        url,
        subgroup
      );

      runInAction(() => {
        if (
          this.subgroupList.subgroups.length &&
          this.subgroupList.subgroups[0].store_group_id ===
            subgroup.store_group_id
        ) {
          this.subgroupList.subgroups.push(data);
        }
      });
    } catch (error) {
      runInAction(() => {
        handleError(error);
      });
    }
  };

  getTimezones = async (id: string) => {
    const url = this.rootStore.configStore.API.dashboard.locations.timezones;

    try {
      this.timezoneList.loading = true;
      const { data } = await axios.post(url, {
        countryIds: [id],
      });

      runInAction(() => {
        this.timezoneList.timezones = data;
      });
    } catch (error) {
      runInAction(() => {
        handleError(error);
      });
    } finally {
      runInAction(() => {
        this.timezoneList.loading = false;
      });
    }
  };

  createStore = async (store: Types.IClientVenue) => {
    const url = this.rootStore.configStore.API.dashboard.locations.post;

    try {
      this.clientStores.loading = true;
      await axios.post(url, {
        store,
      });

      runInAction(() => {
        this.fetchClientStores();
        // this.clientStores.stores.push(store);
      });
    } catch (error) {
      runInAction(() => {
        handleError(error);
      });
    } finally {
      runInAction(() => {
        this.clientStores.loading = false;
      });
    }
  };

  fetchStore = async (id: string) => {
    const url = this.rootStore.configStore.API.dashboard.locations.find;

    try {
      this.selectedClientStore.error = null;
      this.selectedClientStore.loading = true;
      const { data }: { data: Types.ITranfromedClientStore } = await axios.get(
        `${url}/${id}`
      );

      runInAction(() => {
        this.selectedClientStore.store = data;
      });
    } catch (error: any) {
      runInAction(() => {
        this.selectedClientStore.error = error;
        handleError(error);
      });
    } finally {
      runInAction(() => {
        this.selectedClientStore.loading = false;
      });
    }
  };

  editStore = async (store: Types.ITranfromedClientStore) => {
    const url = this.rootStore.configStore.API.dashboard.locations.post;

    try {
      this.clientStores.loading = true;
      await axios.put(`${url}/${store.id}`, {
        store,
      });

      runInAction(() => {
        const idx = this.clientStores.stores.findIndex(
          (venue) => venue.id === store.id
        );

        if (idx >= 0) {
          this.clientStores.stores[idx] = store;
        }
      });
    } catch (error) {
      runInAction(() => {
        handleError(error);
      });
    } finally {
      runInAction(() => {
        this.clientStores.loading = false;
      });
    }
  };

  // -------------- NEEDS REFACTORING -------------

  activeGroupsSelect = (): ReactNode[] | null => {
    if (!this.clientGroupsList) {
      return null;
    }

    const activeList = this.clientGroupsList.filter(
      (group: any) => group.active
    );

    if (activeList.length) {
      return activeList.map((group: any) => {
        const groupString = `${group.custom_id}, ${group.name}`;
        return (
          <Select.Option key={group.id} value={group.id}>
            {groupString}
          </Select.Option>
        );
      });
    }
    return null;
  };

  countriesSelect = (): ReactNode[] | null => {
    if (!this.countriesList) {
      return null;
    }
    return this.countriesList.map((country: any) => {
      const countryString = `${country.name}`;
      const countryValues = `${country.id},${country.alphaId2}`;

      return (
        <Select.Option key={country.alphaId2} value={countryValues}>
          {countryString}
        </Select.Option>
      );
    });
  };

  subgroupSelect = (): ReactNode[] | null => {
    if (!this.subgroupsList) {
      return null;
    }
    return this.subgroupsList.map((subgroup: any) => {
      const subgroupString = `${subgroup.custom_id}, ${subgroup.name}`;

      return (
        <Select.Option key={subgroup.id} value={subgroup.id}>
          {subgroupString}
        </Select.Option>
      );
    });
  };

  timezoneSelect = (): ReactNode[] | null => {
    if (!this.timezoneList.timezones.length) {
      return null;
    }

    return this.timezoneList.timezones.map((timezone: any) => {
      const timezoneString = `${timezone.zoneName}`;

      return (
        <Select.Option
          key={timezone.zoneId}
          data-purpose="timezone"
          value={timezone.zoneId}
        >
          {timezoneString}
        </Select.Option>
      );
    });
  };

  getCountries = async ({
    refresh = false,
  }: { refresh?: boolean } = {}): Promise<void> => {
    if (this.countriesList && !refresh) return;

    const url = this.rootStore.configStore.API.dashboard.locations.countries;
    const res = await Request(url);

    runInAction(() => {
      if (responseFail(res)) {
        throw {
          statusCode: res.statusCode || 400,
          message: "Something Went Wrong",
        };
      }

      this.countriesList = res;
    });
  };

  // redundant after refactor
  getClientStores = async ({
    refresh = false,
  }: { refresh?: boolean } = {}): Promise<void> => {
    // ToDo: Split into List & Map functions
    if (this.clientStoresList && !refresh) return;

    const url = this.rootStore.configStore.API.dashboard.locations.client;
    const res = await Request(url);
    runInAction(() => {
      if (responseFail(res)) {
        throw {
          statusCode: res.statusCode || 400,
          message: "Something Went Wrong",
        };
      }
      this.clientStoresList = res.map((venue: any) => {
        for (const key in venue) {
          if (venue[key] === null) {
            venue[key] = "";
          }
        }
        return {
          ...venue,
          key: venue.id,
          address: `${venue.addressLine1}
          ${venue.addressLine2}${venue.addressLine3}
          ${venue.addressLine4}${venue.locality}
          ${venue.postcode}`,
        };
      });
    });
  };

  getClientGroups = async ({
    refresh = true,
  }: { refresh?: boolean } = {}): Promise<void> => {
    if (this.clientGroupsList && !refresh) return;

    const url = this.rootStore.configStore.API.dashboard.locations.groups;
    const res = await Request(url);
    runInAction(() => {
      if (responseFail(res)) {
        throw {
          statusCode: res.statusCode || 400,
          message: "Something Went Wrong",
        };
      }

      this.clientGroupsList = res.map((group: any) => ({
        ...group,
        key: group.id,
      }));
    });
  };

  getStoresFromGroups = async ({
    groupIds,
  }: {
    groupIds: number[];
  }): Promise<void> => {
    const url = this.rootStore.configStore.API.dashboard.locations.fromGroups;

    try {
      const { data } = await axios.post(url, {
        groupIds,
      });

      return data;
    } catch (error: any) {
      runInAction(() => {
        const statusCode = error.response.data.statusCode;
        const message = error.response.data.message;

        throw new ResponseError({
          statusCode,
          message,
        });
      });
    }
  };

  unpairLocationFromBeacon = async ({
    locationID,
    beaconID,
  }: {
    locationID: any;
    beaconID: any;
  }): Promise<void> => {
    const url = this.rootStore.configStore.API.dashboard.locations.unpair
      .replace("%LOCATION_ID%", locationID)
      .replace("%BEACON_ID%", beaconID);
    const res = await Request(url, {
      method: "PUT",
    });

    runInAction(() => {
      if (responseFail(res)) {
        throw {
          statusCode: res.statusCode || 400,
          message: "Something Went Wrong",
        };
      }
    });
  };

  // getLocation = async ({ id }: { id: any; }): Promise<void> => {
  //   const url = this.rootStore.configStore.API.dashboard.locations.find.replace('%ID%', id);
  //   const res = await Request(url);

  //   runInAction(() => {
  //     if (responseFail(res)) {
  //       throw {
  //         statusCode: res.statusCode || 400,
  //         message: 'Something Went Wrong'
  //       };
  //     }
  //   });

  //   this.editableVenueLocation = res;
  // }

  getGroup = async ({ id }: { id: any }): Promise<void> => {
    const url =
      this.rootStore.configStore.API.dashboard.locations.findGroup.replace(
        "%ID%",
        id
      );
    const res = await Request(url);
    runInAction(() => {
      if (responseFail(res)) {
        throw {
          statusCode: res.statusCode || 400,
          message: "Something Went Wrong",
        };
      }
      this.editableGroup = res;
    });
  };

  getSubgroup = async ({ id }: { id: any }): Promise<void> => {
    const url =
      this.rootStore.configStore.API.dashboard.locations.findSubgroup.replace(
        "%ID%",
        id
      );
    const res = await Request(url);
    runInAction(() => {
      if (responseFail(res)) {
        throw {
          statusCode: res.statusCode || 400,
          message: "Something Went Wrong",
        };
      }

      this.editableSubgroup = res;
    });
  };

  // editLocation = async ({ id, data }: { id: any; data: any; }): Promise<void> => {
  //   const url = this.rootStore.configStore.API.dashboard.locations.select.replace('%ID%', id);
  //   try {
  //     await axios.put(url, data);
  //   } catch (error: any) {
  //     runInAction(() => {
  //       throw {
  //         statusCode: error.response.data.statusCode || 400,
  //         message: error.response.data.statusCode.message
  //       };
  //     });
  //   }
  // }

  // addLocation = async ({ data }: { data: any }): Promise<void> => {
  //   const url = this.rootStore.configStore.API.dashboard.locations.post;
  //   const res = await Request(url, {
  //     method: 'POST',
  //     body: JSON.stringify(data)
  //   });

  //   runInAction(() => {
  //     if (responseFail(res)) {
  //       throw {
  //         statusCode: res.statusCode || 400,
  //         message: 'Something Went Wrong'
  //       };
  //     }

  //     this.venueLocation = res;
  //   });
  // }

  getActiveSubgroups = async ({ id }: { id: any }): Promise<void> => {
    const url =
      this.rootStore.configStore.API.dashboard.locations.activeSubgroups.replace(
        "%ID%",
        id
      );

    const res = await Request(url);

    runInAction(() => {
      if (responseFail(res)) {
        throw {
          statusCode: res.statusCode || 400,
          message: "Something Went Wrong",
        };
      }

      this.subgroupsList = res.map((group: any) => ({
        ...group,
        key: group.id,
      }));
    });
  };

  // getOpenTimes = async ({ id }: { id: any }): Promise<void> => {
  //   const url = this.rootStore.configStore.API.dashboard.locations.openClose.replace(
  //     '%ID%',
  //     id
  //   );
  //   const res = await Request(url);

  //   runInAction(() => {
  //     if (responseFail(res)) {
  //       throw {
  //         statusCode: res.statusCode || 400,
  //         message: 'Something Went Wrong'
  //       };
  //     }

  //     this.venueOpenTimes = res;
  //   });
  // }

  // setOpenTimes = async ({ id, data }: { id: any; data: any; }): Promise<void> => {
  //   const url = this.rootStore.configStore.API.dashboard.locations.openClose.replace(
  //     '%ID%',
  //     id
  //   );

  //   try {
  //     await axios.post(url, data);
  //   } catch (error: any) {
  //     runInAction(() => {
  //       throw {
  //         statusCode: error.response.data.statusCode || 400,
  //         message: error.response.data.statusCode.message
  //       };
  //     });
  //   }
  // }

  toggleLocation = async ({
    id,
    state,
  }: {
    id: any;
    state: any;
  }): Promise<void> => {
    const url = this.rootStore.configStore.API.dashboard.locations.toggleStore
      .replace("%ID%", id)
      .replace("%STATE%", state);
    const res = await Request(url, {
      method: "PUT",
    });

    runInAction(() => {
      if (responseFail(res)) {
        throw {
          statusCode: res.statusCode || 400,
          message: "Something Went Wrong",
        };
      }
      const idx = this.clientStoresList.findIndex(
        (store: any) => parseInt(store.id, 10) === parseInt(id, 10)
      );
      this.clientStoresList[idx].active = state;
    });
  };

  getCampaignTimeZones = async ({
    country,
  }: {
    country: any;
  }): Promise<void> => {
    const url =
      this.rootStore.configStore.API.dashboard.campaign.timezones.replace(
        "%COUNTRY%",
        country
      );
    this.campaignTimeZonesLoading = true;
    const res = await Request(url);

    runInAction(() => {
      if (responseFail(res)) {
        this.campaignTimeZonesLoading = false;

        throw {
          statusCode: res.statusCode || 400,
          message: "Something Went Wrong",
        };
      }
      this.campaignTimeZones = res;
      this.campaignTimeZonesLoading = false;
    });
  };

  getCampaignCountries = async (): Promise<void> => {
    const url = this.rootStore.configStore.API.dashboard.campaign.countries;
    this.campaignCountriesLoading = true;
    const { data } = await axios.get(url);

    runInAction(() => {
      this.campaignCountries = data;
      this.campaignCountriesLoading = false;
    });
  };
}

export default LocationStore;
