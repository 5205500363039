import { FC } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { Menu } from "antd";
import { NotificationOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";

const ManageCampaignsSubmenu: FC = (props) => {
  const { t } = useTranslation();

  return (
    <Menu.SubMenu
      title={
        <span>
          <NotificationOutlined />

          <span>{t("sideMenu.campaigns")}</span>
        </span>
      }
      {...props}
    >
      <Menu.Item key="/app/manage/campaigns">
        <Link to="/app/manage/campaigns">
          <span className="nav-text">{t("sideMenu.manageCampaigns")}</span>
        </Link>
      </Menu.Item>

      <Menu.Item key="/app/manage/campaigns/create">
        <Link to="/app/manage/campaigns/create">
          <span className="nav-text">{t("sideMenu.createCampaign")}</span>
        </Link>
      </Menu.Item>
    </Menu.SubMenu>
  );
};

export default observer(ManageCampaignsSubmenu);
