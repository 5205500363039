// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".QcSidebar-module__sidebarTitle___1czeD {\n  display: flex;\n  align-items: center;\n  justify-content: center; }\n", ""]);
// Exports
exports.locals = {
	"sidebarTitle": "QcSidebar-module__sidebarTitle___1czeD"
};
module.exports = exports;
