import { observer } from "mobx-react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { useStores } from "../../../hooks/useStores";
import styles from "./QcSidebar.module.scss";
import {
  campaignMenuItems,
  homeMenuItem,
  menuGroups,
  offerMenuItems,
  storeMenuItems,
} from "./constants";
import { MenuItem } from "./types";

const QcSidebar = () => {
  const { interfaceStore } = useStores();
  const menuTheme = interfaceStore.theme === "light" ? "light" : "dark";

  const renderMenuItem = (menuItem: MenuItem) => (
    <Menu.Item key={menuItem.path}>
      <Link to={menuItem.path}>
        {menuItem.icon}
        <span className="nav-text">{menuItem.text}</span>
      </Link>
    </Menu.Item>
  );

  const renderMenuGroup = (title: string, icon: string) => (
    <Menu.ItemGroup
      title={
        interfaceStore.navActive ? (
          title
        ) : (
          <div className={styles.sidebarTitle}>{icon}</div>
        )
      }
    />
  );

  return (
    <Menu theme={menuTheme} mode="inline">
      {renderMenuItem(homeMenuItem)}

      {renderMenuGroup(menuGroups.campaigns.title, menuGroups.campaigns.icon)}

      {campaignMenuItems.map((menuItem: MenuItem) => renderMenuItem(menuItem))}

      {renderMenuGroup(menuGroups.stores.title, menuGroups.stores.icon)}

      {storeMenuItems.map((menuItem: MenuItem) => renderMenuItem(menuItem))}

      {renderMenuGroup(
        menuGroups.offerCreation.title,
        menuGroups.offerCreation.icon
      )}

      {offerMenuItems.map((menuItem: MenuItem) => renderMenuItem(menuItem))}
    </Menu>
  );
};

export default observer(QcSidebar);
