import { FC } from 'react';
import { Space, Checkbox } from 'antd';

const Checkboxes: FC = () => {
  const CheckboxGroup = Checkbox.Group;

  const checkedOptions = [
    'Apple',
    'Pear',
    'Orange'
  ];

  const defaultCheckedOptions = ['Apple', 'Orange'];

  return (
    <Space size={16} direction="vertical">
      <Space size={16}>
        <Checkbox checked>With text</Checkbox>

        <Checkbox checked={false} />

        <Checkbox checked disabled />

        <Checkbox checked={false} disabled />

        <Checkbox indeterminate />

        <Checkbox indeterminate disabled />
      </Space>

      <CheckboxGroup options={checkedOptions} value={defaultCheckedOptions} />
    </Space>

  );
};

export default Checkboxes;
