/* eslint-disable camelcase */
import config from "../../../../qsr.config";
import axios from "../../axios";

import {
  IGetClientStore,
  IGetEvChargerStore,
  IGetEvChargerData,
  IPostEvChargerStore,
  IPostStartEvCharge,
  IPostStopEvCharge,
  IGetClientStoreBySubgroup,
  IGetCompetitorStore,
  IPostClientStore,
  IPutClientStore,
  IGetBusinessUserPaidStatus,
  IPostBusinessStore,
  IGetBusinessStores,
  IPostPaymentIntent,
  IGetBusinessUserDetails,
  IPostPaymentSession,
  IPutUpdatePassword,
  IGetTopChargersStore,
  IGetAllChargersStore,
  IGetChargeDwell,
  IPostPromoRequest,
  IGetChargersVisits,
  IGetStripeVouchers,
  IGetPromoCodes,
  IGetBusinessStoreLocations,
  IGetBusinessUserImages,
  IGetPaymentInfo,
  IPostGenerateImage,
  IPutUpdateDetails,
  IGetEvMapLocations,
  IPostSalesStore,
  IPostNewProduct,
  IPostCampaignOffer,
} from "./storeTypes";
import { TCoordinates } from "../locations/locationTypes";
import { TDate, TEvCoords } from "../analytics/analyticsTypes";

// --------------- Queries ------------- //

export const getStore = async (id: string | number) => {
  const url = config.API.dashboard.stores.getStore;
  const { data }: { data: IGetClientStore } = await axios.get(`${url}/${id}`);
  return data;
};

export const getEvLocation = async (id: string | number) => {
  const url = config.API.dashboard.ev.getEvLocation;
  const { data }: { data: IGetEvChargerStore[] } = await axios.get(
    `${url}/${id}`
  );
  return data;
};

export const getBusinessUserStatus = async (userId: any) => {
  const url = config.API.dashboard.businessUser.getUserPaidStatus.replace(
    ":userId",
    userId
  );
  const { data }: { data: IGetBusinessUserPaidStatus } = await axios.get(url);

  return data;
};

export const getBusinessUserDetails = async (userEmail: any) => {
  const url = config.API.dashboard.businessUser.getBusinessUserDetails.replace(
    ":userEmail",
    userEmail
  );
  const { data }: { data: IGetBusinessUserDetails } = await axios.get(url);

  return data;
};
export const getBusinessUserImages = async (imageType: any) => {
  const url = config.API.dashboard.businessUser.getImages.replace(
    ":imageType",
    imageType
  );
  const { data }: { data: IGetBusinessUserImages[] } = await axios.get(url);

  return data;
};

export const getStripeVouchers = async () => {
  const url = config.API.dashboard.sales.getStripeVouchers;
  const { data }: { data: IGetStripeVouchers[] } = await axios.get(url);

  return data;
};
export const getPromoCodes = async () => {
  const url = config.API.dashboard.sales.getPromoCodes;
  const { data }: { data: IGetPromoCodes[] } = await axios.get(url);

  return data;
};

export const getPaymentInfo = async (userPayEmail: string | any) => {
  const url = config.API.dashboard.businessUser.getPaymentInfo.replace(
    ":systemUserEmail",
    userPayEmail
  );
  const { data }: { data: IGetPaymentInfo } = await axios.get(url);

  return data;
};

export const getClientStores = async () => {
  const url = config.API.dashboard.stores.getClientStores;
  const { data }: { data: IGetClientStore[] } = await axios.get(url);

  return data;
};

export const getBusinessStores = async () => {
  const url = config.API.dashboard.stores.getBusinessStore;
  const { data }: { data: IGetBusinessStores[] } = await axios.get(url);

  return data;
};

export const getBusinessStoreLocations = async () => {
  const url = config.API.dashboard.stores.getBusinessStoreLocations;
  const { data }: { data: IGetBusinessStoreLocations[] } = await axios.get(url);

  return data;
};

export const getCompetitorId = async () => {
  const url = config.API.dashboard.analytics.competitors.getCompetitorId;
  const { data }: { data: any } = await axios.get(url);

  return data;
};

export const getEvChargerGroups = async () => {
  const url = config.API.dashboard.ev.getChargerGroups;
  const { data }: { data: IGetEvChargerStore[] } = await axios.get(url);

  return data;
};

export const getTopChargerVisits = async (date: TDate) => {
  const { date_from, date_to } = date;
  const url = config.API.dashboard.ev.getTopChargerVisits
    .replace("%DATE_FROM%", date_from)
    .replace("%DATE_TO%", date_to);
  const { data }: { data: IGetTopChargersStore[] } = await axios.get(url);

  return data;
};
export const getCompetitorFootfall = async (date: TDate, competitorId: any) => {
  const { date_from, date_to } = date;
  const url = config.API.dashboard.analytics.competitors.footfall
    .replace("%DATE_FROM%", date_from)
    .replace("%DATE_TO%", date_to)
    .replace("%PLACE_IDS%", competitorId);
  const { data }: { data: any } = await axios.get(url);

  return data;
};
export const getCompetitorLocationFootfall = async (
  date: TDate,
  competitorId: any
) => {
  const { date_from, date_to } = date;
  const url = config.API.dashboard.analytics.competitors.store.footfall
    .replace("%DATE_FROM%", date_from)
    .replace("%DATE_TO%", date_to)
    .replace("%ID%", competitorId);
  const { data }: { data: any } = await axios.get(url);

  return data;
};

export const getChargerVisitsPerDay = async (date: TDate, networks: any) => {
  const { date_from, date_to } = date;
  const url = config.API.dashboard.ev.getChargerVisitsPerDay
    .replace("%DATE_FROM%", date_from)
    .replace("%DATE_TO%", date_to)
    .replace("%NETWORKS%", networks);
  const { data }: { data: IGetChargersVisits } = await axios.get(url);

  return data;
};

export const getChargerDwellPerDay = async (date: TDate, networks: any) => {
  const { date_from, date_to } = date;
  const url = config.API.dashboard.ev.getChargerDwellPerDay
    .replace("%DATE_FROM%", date_from)
    .replace("%DATE_TO%", date_to)
    .replace("%NETWORKS%", networks);
  const { data }: { data: IGetChargeDwell } = await axios.get(url);

  return data;
};

export const getEvMapLocations = async (
  evCoords: TEvCoords,
  radius: string
) => {
  const { evLat, evLng } = evCoords;
  const url = config.API.dashboard.ev.getEvMapLocations
    .replace("%LAT%", evLat)
    .replace("%LNG%", evLng)
    .replace(":radius", radius);
  const { data }: { data: IGetEvMapLocations } = await axios.get(url);

  return data;
};

export const getAllNetworkChargers = async () => {
  const url = config.API.dashboard.ev.getNetworkChargers;
  const { data }: { data: IGetAllChargersStore[] } = await axios.get(url);

  return data;
};

export const getEvChargerData = async () => {
  const url = config.API.dashboard.ev.evChargers;
  const { data }: { data: IGetEvChargerData[] } = await axios.get(url);

  return data;
};

export const getClientStoresBySubgroup = async (subgroupIds: number[]) => {
  if (subgroupIds && subgroupIds.length) {
    const url = config.API.dashboard.stores.storesBySubgroup;
    const { data }: { data: IGetClientStoreBySubgroup[] } = await axios.post(
      url,
      {
        subgroupIds,
      }
    );

    return data;
  }

  return [];
};

export const getCompetitorStoresByLatLng = async (
  competitorIds: string[],
  coordinates: TCoordinates | null
) => {
  if (!coordinates || competitorIds.length === 0) return [];

  const competitors = competitorIds.map((id) => parseInt(id, 10));

  const url = config.API.dashboard.locations.competitors;
  const { data }: { data: { [key: number]: IGetCompetitorStore[] } } =
    await axios.post(url, {
      coordinates,
      competitors,
    });

  return data;
};

export const startEvCharge = async (data: IPostStartEvCharge) => {
  const url = config.API.dashboard.ev.startEvCharge;
  const { data: response } = await axios.post(url, data);

  return response;
};

export const stopEvCharge = (store: IPostStopEvCharge) => {
  const url = config.API.dashboard.ev.stopEvCharge;
  return axios.post(url, store);
};

export const getAllSalesTypes = async () => {
  const url = config.API.dashboard.sales.getAllTypes;
  const { data }: { data: any } = await axios.get(url);

  return data;
};

export const getOfferPreview = async (
  storeName: any,
  offerText: any,
  storeType: any,
  keyProduct: any,
  positions: any
) => {
  const url = config.API.dashboard.sales.getOfferPreview;
  const { data }: { data: any } = await axios.get(
    `${url}?storeName=${storeName}&offerText=${offerText}&storeType=${storeType}&keyProduct=${keyProduct}&positions=${positions}`
  );

  return data;
};

export const getKeyProducts = async (typeIds: any) => {
  const url = config.API.dashboard.sales.getKeyProducts;
  const { data }: { data: any } = await axios.get(`${url}?ids=${typeIds}`);

  return data;
};

// --------------- Mutations ------------- //

export const createClientStore = (store: IPostClientStore) => {
  const url = config.API.dashboard.stores.createClientStore;
  return axios.post(url, store);
};

export const postCompetitorList = async (store: any) => {
  const url = config.API.dashboard.analytics.poi.places;
  return axios.post(url, store);
};

export const editClientStore = (store: IPutClientStore) => {
  const url = config.API.dashboard.stores.editClientStore;
  return axios.put(url, store);
};

export const createBusinessStore = (store: IPostBusinessStore) => {
  const url = config.API.dashboard.stores.createBusinessStore;
  return axios.post(url, store);
};

export const toggleClientStoreActiveStatus = (id: string | number) => {
  const url = config.API.dashboard.stores.toggleActiveClientStore;

  return axios.put(`${url}/${id}`);
};
export const toggleBusinessActiveStatus = (id: string | number) => {
  const url = config.API.dashboard.stores.toggleActiveClientStore;

  return axios.put(`${url}/${id}`);
};

export const toggleActiveChargerGroup = (id: string | number) => {
  const url = config.API.dashboard.ev.toggleActiveChargerGroup;

  return axios.put(`${url}/${id}`);
};

export const createChargerGroup = (store: IPostEvChargerStore) => {
  const url = config.API.dashboard.ev.createChargerGroup;
  return axios.post(url, store);
};

export const editChargerGroup = (store: IPostEvChargerStore) => {
  const url = config.API.dashboard.ev.editChargerGroup;

  return axios.put(`${url}/${store.id}`, store);
};

export const postPaymentIntent = async (data: IPostPaymentIntent) => {
  const url = config.API.dashboard.businessUser.postPaymentIntent;

  const { data: response } = await axios.post(url, data);

  return response;
};

export const postPaymentSession = async (data: IPostPaymentSession) => {
  const url = config.API.dashboard.businessUser.postPaymentSession;

  const { data: response } = await axios.post(url, data);

  return response;
};

export const postPromoRequest = async (data: IPostPromoRequest) => {
  const url = config.API.dashboard.sales.postPromoRequest;

  const { data: response } = await axios.post(url, data);

  return response;
};
export const postSalesStore = async (data: IPostSalesStore) => {
  const url = config.API.dashboard.sales.postStore;

  const { data: response } = await axios.post(url, data);

  return response;
};
export const postNewProduct = async (data: IPostNewProduct) => {
  const url = config.API.dashboard.sales.postNewProduct;

  const { data: response } = await axios.post(url, data);

  return response;
};
export const postCampaignOffer = async (data: IPostCampaignOffer) => {
  const url = config.API.dashboard.sales.postCampaignOffer;

  const { data: response } = await axios.post(url, data);

  return response;
};
export const postTempAccount = async (data: any) => {
  const url = config.API.dashboard.businessUser.postTempAccount;

  const { data: response } = await axios.post(url, data);

  return response;
};

export const postLoginTime = async () => {
  const url = config.API.dashboard.businessUser.postLoginTime;

  return axios.post(url);
};

export const editDetails = async (data: IPutUpdateDetails) => {
  const url = config.API.dashboard.businessUser.editDetails;

  return axios.put(url, data);
};

export const updatePassword = (data: IPutUpdatePassword) => {
  const url = config.API.dashboard.businessUser.updatePassword;

  return axios.put(url, data);
};

export const deleteImage = (imageId: any) => {
  const url = config.API.dashboard.businessUser.deleteImage.replace(
    ":imageId",
    imageId
  );

  return axios.delete(url);
};

export const generateImage = async (data: IPostGenerateImage) => {
  const url = config.API.dashboard.businessUser.generateImage;

  const { data: response } = await axios.post(url, data);

  return response;
};
