import { FC } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { Menu } from "antd";
import { BarChartOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";

const AnalyticsAppSubmenu: FC = (props) => {
  const { t } = useTranslation();

  return (
    <Menu.SubMenu
      title={
        <span>
          <BarChartOutlined />

          <span>{t("sideMenu.app")}</span>
        </span>
      }
      {...props}
    >
      <Menu.Item key="/app/analytics/acquisitions">
        <Link to="/app/analytics/acquisitions">
          <span className="nav-text">{t("sideMenu.acquisition")}</span>
        </Link>
      </Menu.Item>

      <Menu.Item key="/app/analytics/interactions">
        <Link to="/app/analytics/interactions">
          <span className="nav-text">{t("sideMenu.interactions")}</span>
        </Link>
      </Menu.Item>

      <Menu.Item key="/app/analytics/devices">
        <Link to="/app/analytics/devices">
          <span className="nav-text">{t("sideMenu.devices")}</span>
        </Link>
      </Menu.Item>

      <Menu.Item key="/app/analytics/device-map">
        <Link to="/app/analytics/device-map">
          <span className="nav-text">{t("sideMenu.deviceMap")}</span>
        </Link>
      </Menu.Item>
    </Menu.SubMenu>
  );
};

export default observer(AnalyticsAppSubmenu);
