// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UpdatePassword-module__header___-QMrK {\n  padding-top: 25px;\n  margin-bottom: -10px; }\n", ""]);
// Exports
exports.locals = {
	"header": "UpdatePassword-module__header___-QMrK"
};
module.exports = exports;
