import { observer } from 'mobx-react';
import { FC } from 'react';
import { useStores } from '../../../hooks/useStores';

import styles from './SectionHeaders.module.scss';

const SectionHeader: FC = ({ children }) => {
  const { interfaceStore } = useStores();

  return (
    <header className={interfaceStore.theme === 'light' ? styles.section_headerLight : styles.section_headerDark}>
      <h4 className={interfaceStore.theme === 'light' ? styles.section_headerLight__heading : styles.section_headerDark__heading}>
        {children}
      </h4>
    </header>
  );
};

export default observer(SectionHeader);
