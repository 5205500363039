/* eslint-disable quotes */
import defaultDates from "./defaultDates";

const last7Days = "Last 7 Days";
const last14Days = "Last 14 Days";
const last21Days = "Last 21 Days";
const lastMonth = "Last Month";
const last3Months = "Last 3 Months";
const allTime = "All Time";

const presetDates = {
  allTime: [defaultDates.start_of_time, defaultDates.today],
  default: [defaultDates.seven_days_ago, defaultDates.today],
  ranges: {
    [last7Days]: [defaultDates.seven_days_ago, defaultDates.today],
    [last14Days]: [defaultDates.fourteen_days_ago, defaultDates.today],
    [last21Days]: [defaultDates.twentyone_days_ago, defaultDates.today],
    [lastMonth]: [defaultDates.thirtyone_days_ago, defaultDates.today],
    [last3Months]: [defaultDates.ninetyone_days_ago, defaultDates.today],
    [allTime]: [defaultDates.start_of_time, defaultDates.today],
  },
};

export default presetDates;
