// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SectionHeaders-module__section_headerDark__heading___2K4pg {\n  width: 100%;\n  color: var(--white);\n  font-size: 1.375rem;\n  line-height: 1.5rem;\n  font-weight: 500;\n  text-transform: uppercase;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis; }\n\n.SectionHeaders-module__section_headerLight__heading___1mue9 {\n  width: 100%;\n  color: var(--color-black);\n  font-size: 1.375rem;\n  line-height: 1.5rem;\n  font-weight: 500;\n  text-transform: uppercase;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  margin: 0;\n  color: var(--color-black);\n  font-size: 1.375rem; }\n", ""]);
// Exports
exports.locals = {
	"section_headerDark__heading": "SectionHeaders-module__section_headerDark__heading___2K4pg",
	"section_headerLight__heading": "SectionHeaders-module__section_headerLight__heading___1mue9"
};
module.exports = exports;
