import { FC, useState } from "react";
import { Button, Divider, Form, Input, Space } from "antd";
import styles from "./UpdatePassword.module.scss";

import SectionHeader from "../../../components/Blocks/SectionHeader/SectionHeader";
import Card from "../../../components/Blocks/Card/Card";
import { useUpdatePassword } from "../../../hooks/registrationHooks/registration.hooks";
import { handlePwSuccessModal } from "../../../utils/modals/simpleModals";
import { useStores } from "../../../hooks/useStores";

const UpdatePassword: FC = () => {
  const { userStore } = useStores();
  const [form] = Form.useForm();
  const [currentPass, setCurrentPass] = useState<string | undefined>();
  const [newPass, setNewPass] = useState<string | undefined>();

  const updatePassword = useUpdatePassword();

  const onFinish = async () => {
    form.validateFields();

    const store = {
      currentPassword: currentPass,
      newPassword: newPass,
    };

    updatePassword.mutate(store, {
      onSuccess: () => {
        handlePwSuccessModal(
          "Success!",
          "Password has been updated! Please log in again with your new password."
        );
        setTimeout(() => {
          userStore.logout();
        }, 1000);
      },
    });
  };

  return (
    <Space direction="vertical">
      <header className={styles.header}>
        <SectionHeader>Update your Password</SectionHeader>
      </header>

      <Divider />

      <Form layout="vertical" onFinish={onFinish}>
        <Card>
          <Form.Item
            name="currentPassword"
            label="Please input your current password"
            rules={[
              {
                required: true,
                message: "Current password is required",
              },
            ]}
          >
            <Input.Password
              placeholder="Current Password...."
              onChange={(e) => setCurrentPass(e.target.value)}
            />
          </Form.Item>
        </Card>

        <Card>
          <Form.Item
            name="newPassword"
            label="Please input your new password"
            rules={[
              {
                required: true,
                message: "New password is required",
              },
              {
                pattern: new RegExp(
                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{10,}$/
                ),
                message:
                  "Password must be a minimum of 10 characters with at least 1 number and 1 special character.",
              },
            ]}
          >
            <Input.Password
              placeholder="New Password...."
              onChange={(e) => setNewPass(e.target.value)}
            />
          </Form.Item>
        </Card>

        <Button
          type="primary"
          htmlType="submit"
          style={{ paddingLeft: "10px", paddingRight: "10px", width: "100%" }}
        >
          SUBMIT
        </Button>
      </Form>
    </Space>
  );
};

export default UpdatePassword;
