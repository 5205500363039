import { FC } from 'react';
import moment from 'moment';
import { Space, DatePicker } from 'antd';
import FnsDatePicker from '../../../../components/AntD/DatePicker/DatePicker';

const DatePickers: FC = () => {
  const { RangePicker } = DatePicker;
  const { RangePicker: FnsRangePicker } = FnsDatePicker;

  const range = (start: any, end: any) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDate = (current: any) => current && current < moment().endOf('day');

  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  });

  const disabledRangeTime = (_: any, type: any) => {
    if (type === 'start') {
      return {
        disabledHours: () => range(0, 60).splice(4, 20),
        disabledMinutes: () => range(30, 60),
        disabledSeconds: () => [55, 56],
      };
    }
    return {
      disabledHours: () => range(0, 60).splice(20, 4),
      disabledMinutes: () => range(0, 31),
      disabledSeconds: () => [55, 56],
    };
  };

  return (
    <Space size={16} direction="vertical">
      <DatePicker />
      <DatePicker disabled />
      <DatePicker showTime />
      <DatePicker picker="week" />
      <DatePicker picker="month" />
      <DatePicker picker="quarter" />
      <DatePicker picker="year" />
      <DatePicker status="error" />
      <DatePicker status="warning" />
      <RangePicker />
      <RangePicker suffixIcon={null} />
      <RangePicker disabled />
      <RangePicker showTime />
      <RangePicker picker="week" />
      <RangePicker picker="month" />
      <RangePicker picker="quarter" />
      <RangePicker picker="year" />

      <DatePicker
        format="YYYY-MM-DD HH:mm:ss"
        disabledDate={disabledDate}
        disabledTime={disabledDateTime}
      />
      <DatePicker picker="month" disabledDate={disabledDate} />
      <RangePicker disabledDate={disabledDate} />
      <RangePicker
        disabledDate={disabledDate}
        disabledTime={disabledRangeTime}
        showTime={{
          hideDisabledOptions: true,
        }}
        format="YYYY-MM-DD HH:mm:ss"
      />
      <FnsDatePicker />
      <FnsRangePicker />
    </Space>
  );
};

export default DatePickers;
