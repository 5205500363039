import { makeAutoObservable } from "mobx";

import { MainRootStore } from "..";

import * as Types from "./formTypes";

class FormStore {
  rootStore: MainRootStore;

  multipageWizard: Types.TMultipageWizard = {
    state: {
      pages: [],
      activePage: null,
      completedPages: [],
    },
  };

  constructor(rootStore: MainRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  setPages = (pages: Types.TPage[]) => {
    this.multipageWizard.state.pages = pages;
  };

  setupForm = (pages: Types.TPage[]) => {
    this.setPages(pages);
    this.multipageWizard.state.activePage = pages[0];
    // this.multipageWizard.state.completedPages = [];
  };

  setActivePage = (page: Types.TPage): void => {
    this.multipageWizard.state.activePage = page;
  };

  setCompletedPages = (page: Types.TPage) => {
    const idx = this.multipageWizard.state.completedPages.findIndex(
      (completedPage) => completedPage === page
    );

    if (idx === -1) {
      this.multipageWizard.state.completedPages.push(page);
    }
  };

  get totalPages() {
    return this.multipageWizard.state.pages.length;
  }

  get totalOfCompletedPages() {
    return this.multipageWizard.state.completedPages.length;
  }
}

export default FormStore;
