import { FC } from 'react';
import { Space, InputNumber } from 'antd';

import Card from '../../../../components/Blocks/Card/Card';

const InputNumbers: FC = () => (
  <Space size={16} direction="vertical">
    <Card>
      <InputNumber min={1} max={10} />
    </Card>

    <Card>
      <InputNumber status="warning" />
    </Card>

    <Card>
      <InputNumber status="error" />
    </Card>

    <Card>
      <InputNumber disabled />
    </Card>
  </Space>
);

export default InputNumbers;
