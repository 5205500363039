import { makeAutoObservable } from "mobx";

import { MainRootStore } from "..";

/**
 * @description Fetch & Store - Data for Offer Preview on Baby Dash
 */
class AddBusinessStore {
  businessName: string;
  businessEmail: string;
  cellNumber: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  website: string;
  phoneNumber: string | any;
  storeLat: number | null;
  storeLng: number | null;
  country: string;
  showStoreDetails: boolean;
  allTypes: any;
  productTypeIds: [];

  openingHours: any;

  convertOpeningHours: any;
  customHours: any;
  timeZoneName: string;
  timeZoneId: number | null;

  openAllDay: boolean;
  customOpeningHours: boolean;

  storeObject: object | any;

  stepPass: boolean;
  stepMove: boolean;
  finishStore: boolean;
  resetStep: boolean;
  skipOpening: boolean;

  photoUrls: any;

  placeId: string;

  template: string;
  keyProduct: string;
  offerText: string;

  placeTypes: [];
  storeType: "";

  isEditStore: boolean;

  rootStore: MainRootStore;

  constructor(rootStore: MainRootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.businessName = "";
    this.businessEmail = "";
    this.cellNumber = "";
    this.addressLine1 = "";
    this.addressLine2 = "";
    this.city = "";
    this.state = "";
    this.zipCode = "";
    this.website = "";
    this.phoneNumber = "";
    this.stepPass = false;
    this.resetStep = false;
    this.openingHours = undefined;
    this.convertOpeningHours = undefined;
    this.timeZoneId = null;
    this.timeZoneName = "";
    this.storeLat = null;
    this.storeLng = null;
    this.country = "";
    this.showStoreDetails = false;
    this.finishStore = false;
    this.photoUrls = undefined;
    this.placeId = "";
    this.placeTypes = [];
    this.storeObject = {};
    this.openAllDay = false;
    this.stepMove = false;
    this.skipOpening = false;
    this.allTypes = [];
    this.productTypeIds = [];
    this.template = "";
    this.keyProduct = "";
    this.offerText = "";
    this.storeType = "";
    this.isEditStore = false;
  }

  setBusinessName = (businessName?: string): void => {
    if (businessName) {
      this.businessName = businessName;
    } else {
      this.businessName = "";
    }
  };
  setBusinessEmail = (businessEmail?: string): void => {
    if (businessEmail) {
      this.businessEmail = businessEmail;
    } else {
      this.businessEmail = "";
    }
  };
  setCellNumber = (cellNumber?: string): void => {
    if (cellNumber) {
      this.cellNumber = cellNumber;
    } else {
      this.cellNumber = "";
    }
  };

  setAddressLine1 = (addressLine1?: string): void => {
    if (addressLine1) {
      this.addressLine1 = addressLine1;
    } else {
      this.addressLine1 = "";
    }
  };
  setAddressLine2 = (addressLine2?: string): void => {
    if (addressLine2) {
      this.addressLine2 = addressLine2;
    } else {
      this.addressLine2 = "";
    }
  };
  setCity = (city?: string): void => {
    if (city) {
      this.city = city;
    } else {
      this.city = "";
    }
  };
  setState = (state?: string): void => {
    if (state) {
      this.state = state;
    } else {
      this.state = "";
    }
  };
  setZipCode = (zipCode?: string): void => {
    if (zipCode) {
      this.zipCode = zipCode;
    } else {
      this.zipCode = "";
    }
  };
  setWebsite = (website?: string): void => {
    if (website) {
      this.website = website;
    } else {
      this.website = "";
    }
  };
  setPhoneNumber = (phoneNumber?: string | any): void => {
    if (phoneNumber) {
      this.phoneNumber = phoneNumber;
    } else {
      this.phoneNumber = "";
    }
  };
  setStepPass = (stepPass?: boolean): void => {
    if (stepPass) {
      this.stepPass = stepPass;
    } else {
      this.stepPass = false;
    }
  };
  setResetStep = (resetStep?: boolean): void => {
    if (resetStep) {
      this.resetStep = resetStep;
    } else {
      this.resetStep = false;
    }
  };
  setStepMove = (stepMove?: boolean): void => {
    if (stepMove) {
      this.stepMove = stepMove;
    } else {
      this.stepMove = false;
    }
  };
  setFinishStore = (finishStore?: boolean): void => {
    if (finishStore) {
      this.finishStore = finishStore;
    } else {
      this.finishStore = false;
    }
  };
  setSkipOpening = (skipOpening?: boolean): void => {
    if (skipOpening) {
      this.skipOpening = skipOpening;
    } else {
      this.skipOpening = false;
    }
  };
  setOpenAllDay = (openAllDay?: boolean): void => {
    if (openAllDay) {
      this.openAllDay = openAllDay;
    } else {
      this.openAllDay = false;
    }
  };
  setCustomOpeningHours = (customOpeningHours?: boolean): void => {
    if (customOpeningHours) {
      this.customOpeningHours = customOpeningHours;
    } else {
      this.customOpeningHours = false;
    }
  };

  setAllTypes = (allTypes?: any): void => {
    if (allTypes) {
      this.allTypes = allTypes;
    } else {
      this.allTypes = false;
    }
  };

  setOpeningHours = (openingHours?: any): void => {
    if (openingHours) {
      this.openingHours = openingHours;
    } else {
      this.openingHours = undefined;
    }
  };

  setCustomHours = (customHours?: any): void => {
    if (customHours) {
      this.customHours = customHours;
    } else {
      this.customHours = undefined;
    }
  };
  setConvertOpeningHours = (convertOpeningHours?: any): void => {
    if (convertOpeningHours) {
      this.convertOpeningHours = convertOpeningHours;
    } else {
      this.convertOpeningHours = undefined;
    }
  };

  setStoreLat = (storeLat?: number | null): void => {
    if (storeLat) {
      this.storeLat = storeLat;
    } else {
      this.storeLat = null;
    }
  };
  setStoreLng = (storeLng?: number | null): void => {
    if (storeLng) {
      this.storeLng = storeLng;
    } else {
      this.storeLng = null;
    }
  };

  setTimeZoneName = (timeZoneName?: string): void => {
    if (timeZoneName) {
      this.timeZoneName = timeZoneName;
    } else {
      this.timeZoneName = "";
    }
  };
  setTimeZoneId = (timeZoneId?: number | null): void => {
    if (timeZoneId) {
      this.timeZoneId = timeZoneId;
    } else {
      this.timeZoneId = null;
    }
  };
  setCountry = (country?: string): void => {
    if (country) {
      this.country = country;
    } else {
      this.country = "";
    }
  };
  setShowStoreDetails = (showStoreDetails?: boolean): void => {
    if (showStoreDetails) {
      this.showStoreDetails = showStoreDetails;
    } else {
      this.showStoreDetails = false;
    }
  };
  setPhotoUrls = (photoUrls?: any): void => {
    if (photoUrls) {
      this.photoUrls = photoUrls;
    } else {
      this.photoUrls = undefined;
    }
  };
  setPlaceId = (placeId?: string): void => {
    if (placeId) {
      this.placeId = placeId;
    } else {
      this.placeId = "";
    }
  };

  setPlaceTypes = (placeTypes?: [] | any): void => {
    if (placeTypes) {
      this.placeTypes = placeTypes;
    } else {
      this.placeTypes = [];
    }
  };
  setStoreType = (storeType?: string | any): void => {
    if (storeType) {
      this.storeType = storeType;
    } else {
      this.storeType = "";
    }
  };
  setProductTypeIds = (productTypeIds?: [] | any): void => {
    if (productTypeIds) {
      this.productTypeIds = productTypeIds;
    } else {
      this.productTypeIds = [];
    }
  };

  setTemplate = (template?: string | any): void => {
    if (template) {
      this.template = template;
    } else {
      this.template = "";
    }
  };

  setKeyProduct = (keyProduct?: string | any): void => {
    if (keyProduct) {
      this.keyProduct = keyProduct;
    } else {
      this.keyProduct = "";
    }
  };
  setOfferText = (offerText?: string | any): void => {
    if (offerText) {
      this.offerText = offerText;
    } else {
      this.offerText = "";
    }
  };

  setStoreObject = (storeObject?: {} | any): void => {
    if (storeObject) {
      this.storeObject = storeObject;
    } else {
      this.storeObject = [];
    }
  };

  setEditStore = (isEditStore?: boolean): void => {
    if (isEditStore) {
      this.isEditStore = isEditStore;
    } else {
      this.isEditStore = false;
    }
  };

  clearBusinessUser = () => {
    this.businessName = "";
    this.businessEmail = "";
    this.addressLine1 = "";
    this.addressLine2 = "";
    this.cellNumber = "";
    this.city = "";
    this.state = "";
    this.zipCode = "";
    this.website = "";
    this.phoneNumber = "";
    this.stepPass = false;
    this.resetStep = false;
    this.openingHours = undefined;
    this.convertOpeningHours = undefined;
    this.customHours = undefined;
    this.timeZoneId = null;
    this.timeZoneName = "";
    this.storeLat = null;
    this.storeLng = null;
    this.country = "";
    this.showStoreDetails = false;
    this.placeId = "";
    this.placeTypes = [];
    this.storeObject = {};
    this.openAllDay = false;
    this.finishStore = false;
    this.customOpeningHours = false;
    this.skipOpening = false;
    this.stepMove = false;
    this.allTypes = [];
    this.productTypeIds = [];
    this.template = "";
    this.offerText = "";
    this.storeType = "";
  };
}

export default AddBusinessStore;
