/* eslint-disable */
import loadable from '@loadable/component';

const QcHome = loadable(() => import('../pages/qc/QcHome/QcHome'));
const QcCreateCampaign = loadable(() => import('../pages/qc/QcCreateCampaign/QcCreateCampaign'));
const QcManageCampaigns = loadable(() => import('../pages/qc/QcManageCampaigns/QcManageCampaigns'));
const QcMap = loadable(() => import('../pages/qc/QcMap/QcMap'));
const QcCreateVenue = loadable(() => import('../pages/qc/QcCreateVenue/QcCreateVenue'));
const QcStoreTable = loadable(() => import('../pages/qc/QcViewVenues/QcViewVenues'));
const QcEditStore = loadable(() => import('../pages/qc/QcEditVenue/QcEditVenue'));
const QcImageGallery = loadable(() => import('../pages/qc/QcImageGallery/QcImageGallery'));
const QcCreateOffer = loadable(() => import('../pages/qc/QcCreateOffer/QcCreateOffer'));

export const qcRoutes = [
  { path: '/app', component: QcHome },
  { path: '/app/create', component: QcCreateCampaign },
  { path: '/app/manage', component: QcManageCampaigns },
  { path: '/app/map', component: QcMap },
  { path: '/app/create-store', component: QcCreateVenue },
  { path: '/app/stores', component: QcStoreTable },
  { path: '/app/stores/edit-store/:storeId', component: QcEditStore },
  { path: '/app/image-gallery', component: QcImageGallery },
  { path: '/app/create-offer', component: QcCreateOffer },
];
