import { reduceSpecials } from '..';
import * as Types from '../../store/config/configTypes';

type TGetFilenameReturn = {
  key: string;
  filename: string | null
};

export const getFilename = (place: Types.TPlace, logos: Types.TLogos): TGetFilenameReturn => {
  const key: string = reduceSpecials({
    string: place.placeName
  });

  const filename = logos.find(logo => {
    const reduced: string = reduceSpecials({
      string: logo
    });

    return reduced === key;
  });

  return filename ? {
    key,
    filename
  } : {
    key,
    filename: null
  };
};
