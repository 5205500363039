/* eslint-disable indent */
/* eslint-disable @typescript-eslint/indent */
import { FC } from "react";
import { observer } from "mobx-react";
import { Menu } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { useStores } from "../../../hooks/useStores";

import ColorOptions from "../ColorOptions/ColorOptions";
import { useTranslation } from "react-i18next";

import styles from "./UserDropDown.module.scss";

const UserDropdown: FC = () => {
  const { userStore, interfaceStore } = useStores();

  const { t, i18n } = useTranslation();

  const roleId = userStore.user?.roleId;

  return (
    <Menu
      style={
        interfaceStore.theme === "light"
          ? {
              background: "var(--color-white)",
              color: "var(--color-light-black)",
            }
          : {
              background: "var(--color-light-black)",
              color: "white",
            }
      }
      id="user-dropdown"
    >
      <Menu.Item
        key="user"
        style={{
          width: "100%",
        }}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p className={styles.userSettings}>{t("header.userSettings")}</p>
        </span>
      </Menu.Item>

      <Menu.Divider />

      <ColorOptions />

      <Menu.Divider />

      {roleId === 3 && (
        <>
          <Menu.Item
            style={
              interfaceStore.theme === "light"
                ? {
                    color: "var(--color-light-black)",
                    textAlign: "center",
                  }
                : {
                    color: "var(--color-white)",
                    textAlign: "center",
                  }
            }
            key="/app/update-password"
            onClick={userStore.resetPassword}
          >
            <span>{t("header.updatePassword")}</span>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            style={
              interfaceStore.theme === "light"
                ? {
                    color: "var(--color-light-black)",
                    textAlign: "center",
                  }
                : {
                    color: "var(--color-white)",
                    textAlign: "center",
                  }
            }
            key="/app/edit-details"
            onClick={userStore.editDetails}
          >
            <span>{t("header.updateDetails")}</span>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            style={
              interfaceStore.theme === "light"
                ? {
                    color: "var(--color-light-black)",
                    textAlign: "center",
                  }
                : {
                    color: "var(--color-white)",
                    textAlign: "center",
                  }
            }
            key="/app/add-user"
            onClick={userStore.addUser}
          >
            <span>{t("header.addNewUser")}</span>
          </Menu.Item>

          <Menu.Divider />
        </>
      )}

      {roleId === 1 && (
        <>
          <Menu.SubMenu
            title={t("header.language")}
            style={
              interfaceStore.theme === "light"
                ? {
                    color: "var(--color-light-black)",
                    textAlign: "center",
                  }
                : {
                    color: "var(--color-white)",
                    textAlign: "center",
                  }
            }
          >
            <Menu.Item key="english" onClick={() => i18n.changeLanguage("en")}>
              {t("header.english")}
            </Menu.Item>
            <Menu.Item key="german" onClick={() => i18n.changeLanguage("de")}>
              {t("header.german")}
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Divider />
        </>
      )}

      <Menu.Item
        style={
          interfaceStore.theme === "light"
            ? {
                color: "var(--color-light-black)",
                paddingRight: "10px",
              }
            : {
                color: "var(--color-white)",
                paddingRight: "10px",
              }
        }
        key="logout"
        onClick={userStore.logout}
      >
        <LogoutOutlined />
        <span className={styles.logOut}>{t("header.logOut")}</span>
      </Menu.Item>
    </Menu>
  );
};

export default observer(UserDropdown);
