import { Form, Input, Button } from "antd";
import { FC } from "react";

import styles from "./LoginMainForm.module.scss";
import { useTranslation } from "react-i18next";

interface ILoginInterface {
  handleEmailSubmit: any;
  isLoading: any;
  setEmailInput: any;
  setErrorState: any;
  error: any;
}

const LoginMainForm: FC<ILoginInterface> = ({
  handleEmailSubmit,
  isLoading,
  setEmailInput,
  setErrorState,
  error,
}) => {
  const [form] = Form.useForm();

  const { t } = useTranslation();
  return (
    <Form
      onFinish={handleEmailSubmit}
      layout="vertical"
      form={form}
      style={{ width: "90%" }}
    >
      <Form.Item
        name="username"
        rules={[
          {
            required: true,
            message: t("login.emailRule"),
          },
        ]}
      >
        <Input
          style={{
            border: "1px solid #868686",
            color: "black",
            borderRadius: "6px",
            width: "100%",
          }}
          placeholder={t("login.emailRule")}
          onChange={async (e: any) => {
            setErrorState(false);
            setEmailInput(e.target.value);
          }}
        />
      </Form.Item>

      <Form.Item>
        <Button
          style={{ width: "100%" }}
          type="primary"
          htmlType="submit"
          loading={isLoading}
          block
        >
          {t("login.next")}
        </Button>
        {error && <p className={styles.errorMessage}>{t("login.passProb")}</p>}
      </Form.Item>
    </Form>
  );
};

export default LoginMainForm;
