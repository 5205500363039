/* eslint-disable */
import loadable from "@loadable/component";
// import Loader from '../components/Blocks/LoaderSmall';

// QSR
const Home = loadable(() => import("../pages/qsr/Home"));
const UpdatePassword = loadable(() => import("../pages/core/UpdatePassword/UpdatePassword"));
// const Feedback = loadable(() => import("../pages/qsr/Feedback"));
const Acquisitions = loadable(() => import("../pages/qsr/analytics/Acquisitions"));
const Interactions = loadable(() => import("../pages/qsr/analytics/Interactions"));
const Devices = loadable(() => import("../pages/qsr/analytics/Devices"));
const DeviceMap = loadable(() => import("../pages/qsr/analytics/DeviceMap"));
const CampaignData = loadable(() => import("../pages/qsr/analytics/Campaigns"));
const VisitData = loadable(() => import("../pages/qsr/analytics/Visits"));
const Dwell = loadable(() => import("../pages/qsr/analytics/Dwell"));
const CompetitorsData = loadable(() => import("../pages/qsr/analytics/Competitors"));
const CompetitorData = loadable(() => import("../pages/qsr/analytics/Competitor"));
const CompetitorStoreData = loadable(() => import("../pages/qsr/analytics/CompetitorStore"));
const ManageCampaigns = loadable(() => import("../pages/qsr/manage/Campaigns/ManageCampaigns/ManageCampaigns"));
const EditCampaign = loadable(() => import("../pages/qsr/manage/Campaigns/EditCampaign/EditCampaign"));
const CreateCampaign = loadable(() => import("../pages/qsr/manage/Campaigns/CreateCampaign/CreateCampaign"));
const SendOffer = loadable(() => import("../pages/qsr/manage/Offers/SendOffer/SendOffer"));
const CurrentOffers = loadable(() => import("../pages/qsr/manage/Offers/CurrentOffers/CurrentOffers"));
const CreateOffer = loadable(() => import("../pages/qsr/manage/Offers/CreateOffer/CreateOffer"));
const EditOffer = loadable(() => import("../pages/qsr/manage/Offers/EditOffer/EditOffer"));
const ArchivedOffers = loadable(() => import("../pages/qsr/manage/Offers/ArchivedOffers/ArchivedOffers"));
const InstantOffer = loadable(() => import("../pages/qsr/manage/Offers/InstantOffer/InstantOffer"));
const EvChargers = loadable(() => import("../pages/qsr/analytics/EV/Chargers/Chargers"));
const BusinessAnalytics = loadable(() => import("../pages/qsr/analytics/Business/BusinessAnalytics/BusinessAnalytics"));
const ActiveStores = loadable(() => import("../pages/qsr/analytics/Business/BusinessStatPages/ActiveStores"));
const OffersSent = loadable(() => import("../pages/qsr/analytics/Business/BusinessStatPages/OffersSent"));
const NewAccounts = loadable(() => import("../pages/qsr/analytics/Business/BusinessStatPages/NewAccounts"));
const ActiveCampaigns = loadable(() => import("../pages/qsr/analytics/Business/BusinessStatPages/ActiveCampaigns"));
const InactiveCampaigns = loadable(() => import("../pages/qsr/analytics/Business/BusinessStatPages/InactiveCampaigns"));
const RegisteredAccounts = loadable(() => import("../pages/qsr/analytics/Business/BusinessStatPages/RegisteredAccounts"));
const StoreMap = loadable(() => import("../pages/qsr/analytics/Business/BusinessStoreMap/BusinessStoreMap"));
const WifiAnalytics = loadable(() => import("../pages/qsr/analytics/Wifi/WifiAnalytics"));



export const adminRoutes = [
  // Home
  { path: '/app', component: Home, requiresAuth: true, meta: { title: 'Home', description: '' } },

  // Analytics
  { path: '/app/analytics/acquisitions', component: Acquisitions, requiresAuth: true, meta: { title: 'Acquisition Data', description: '' } },
  { path: '/app/analytics/interactions', component: Interactions, requiresAuth: true, meta: { title: 'Interaction Data', description: '' } },
  { path: '/app/analytics/wifi', component: WifiAnalytics, requiresAuth: true, meta: { title: 'Wifi Data', description: '' } },
  { path: '/app/analytics/devices', component: Devices, requiresAuth: true, meta: { title: 'Device Data', description: '' } },
  { path: '/app/analytics/device-map', component: DeviceMap, requiresAuth: true, meta: { title: 'Device Map', description: '' } },
  { path: '/app/analytics/campaign', component: CampaignData, requiresAuth: true, meta: { title: 'Campaign Data', description: '' } },
  { path: '/app/analytics/visits', component: VisitData, requiresAuth: true, meta: { title: 'Visitor Data', description: '' } },
  { path: '/app/analytics/dwell', component: Dwell, requiresAuth: true, meta: { title: 'Time Data', description: '' } },
  { path: '/app/analytics/competitors', component: CompetitorsData, requiresAuth: true, meta: { title: 'Competitor Data', description: '' } },
  { path: '/app/analytics/competitors/:id', component: CompetitorData, requiresAuth: true, meta: { title: 'Competitor Report', description: '' } },
  { path: '/app/analytics/competitors/:id/:store', component: CompetitorStoreData, requiresAuth: true, meta: { title: 'Store Report', description: '' } },
  { path: "/app/analytics/ev/chargers", component: EvChargers, requiresAuth: true, meta: { title: 'EV Chargers', description: '' } },
  { path: "/app/analytics/business/business-stats", component: BusinessAnalytics, requiresAuth: true, meta: { title: 'Business Stats', description: '' } },
  { path: "/app/analytics/business/business-stats/active-stores", component: ActiveStores, requiresAuth: true, meta: { title: 'Active Stores', description: '' } },
  { path: "/app/analytics/business/business-stats/offers-sent", component: OffersSent, requiresAuth: true, meta: { title: 'Offers Sent', description: '' } },
  { path: "/app/analytics/business/business-stats/new-accounts", component: NewAccounts, requiresAuth: true, meta: { title: 'New Accounts', description: '' } },
  { path: "/app/analytics/business/business-stats/active-campaigns", component: ActiveCampaigns, requiresAuth: true, meta: { title: 'Active Campaigns', description: '' } },
  { path: "/app/analytics/business/business-stats/inactive-campaigns", component: InactiveCampaigns, requiresAuth: true, meta: { title: 'Inactive Campaigns', description: '' } },
  { path: "/app/analytics/business/business-stats/registered-accounts", component: RegisteredAccounts, requiresAuth: true, meta: { title: 'Registered Accounts', description: '' } },
  { path: "/app/analytics/business/store-map", component: StoreMap, requiresAuth: true, meta: { title: 'Store Map', description: '' } },



  // Brand Management
  { path: '/app/manage/campaigns', component: ManageCampaigns, requiresAuth: true, meta: { title: 'Manage Campaigns', description: '' } },
  { path: '/app/manage/campaigns/create', component: CreateCampaign, requiresAuth: true, meta: { title: 'Create Campaign', description: '' } },
  { path: '/app/manage/campaigns/edit/:campaignId', component: EditCampaign, requiresAuth: true, meta: { title: 'Edit Campaign', description: '' } },
  { path: '/app/manage/offers/current', component: CurrentOffers, requiresAuth: true, meta: { title: 'Current Offers', description: '' } },
  { path: '/app/manage/offer/send', component: SendOffer, requiresAuth: true, meta: { title: 'Send Offers', description: '' } },
  { path: '/app/manage/offer/archive', component: ArchivedOffers, requiresAuth: true, meta: { title: 'Archived Offers', description: '' } },
  { path: '/app/manage/offer/create', component: CreateOffer, requiresAuth: true, meta: { title: 'Create Offers', description: '' } },
  { path: '/app/manage/offer/edit/:id', component: EditOffer, requiresAuth: true, meta: { title: 'Edit Offer', description: '' } },
  { path: '/app/manage/offer/instant', component: InstantOffer, requiresAuth: true, meta: { title: 'Instant Offer', description: '' } },

  // Miscellaneous

   { path: '/app/update-password', component: UpdatePassword, requiresAuth: true, meta: { title: 'Update Password', description: '' } },
];
