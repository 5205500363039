import { FC } from "react";

import styles from "./LoginActivate.module.scss";
import { useTranslation } from "react-i18next";

const LoginActivate: FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.messageContainer}>
      <p className={styles.messageContainer_message}>
        {t("login.pleaseContact")}
      </p>
      <button
        onClick={() => (window.location.href = "mailto:sales@pebble.tech")}
        className={styles.disclaimerLink}
      >
        sales@pebble.tech
      </button>
    </div>
  );
};

export default LoginActivate;
