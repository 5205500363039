import { Button } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface ILoginPassInterface {
  posting: any;
  sendPassClick: any;
}

const LoginSendPassLink: FC<ILoginPassInterface> = ({
  sendPassClick,
  posting,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <Button
        type="primary"
        onClick={sendPassClick}
        loading={posting}
        block
        style={{
          width: "100%",
          paddingLeft: "10px",
          paddingRight: "10px",
          fontSize: "1.1rem",
        }}
      >
        {t("login.sendPassLink")}
      </Button>
    </div>
  );
};

export default LoginSendPassLink;
