// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoginForm-module__container___1WLEo {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center; }\n\n.LoginForm-module__imageContainer___3LYTq {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  padding-bottom: 10px; }\n  .LoginForm-module__imageContainer_img___1TLfS {\n    justify-content: center;\n    align-items: center;\n    height: 80px; }\n\n.LoginForm-module__title___3I92q {\n  text-align: center;\n  font-size: 1.6rem;\n  font-weight: 600;\n  padding: 0px 0 10px 0;\n  margin: 0; }\n\n.LoginForm-module__disclaimerLink___2_uwQ {\n  text-decoration: none;\n  color: var(--color-white);\n  border-radius: 6px;\n  padding-left: 10px;\n  padding-right: 10px;\n  background: var(--color-primary);\n  font-size: 1.1rem; }\n", ""]);
// Exports
exports.locals = {
	"container": "LoginForm-module__container___1WLEo",
	"imageContainer": "LoginForm-module__imageContainer___3LYTq",
	"imageContainer_img": "LoginForm-module__imageContainer_img___1TLfS",
	"title": "LoginForm-module__title___3I92q",
	"disclaimerLink": "LoginForm-module__disclaimerLink___2_uwQ"
};
module.exports = exports;
