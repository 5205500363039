import {FC} from 'react';

type TButtonType = 'success' | 'danger' | 'info';

interface IActionButtonProps {
  btnType: TButtonType;
  clickHandler: () => void;
  disabled?: boolean;
}

const ActionButton: FC<IActionButtonProps> = ({children, btnType = 'success', clickHandler, disabled = false, ...rest}) => (
  <button
    type="button"
    className={`action-button action-button--${btnType}`}
    onClick={() => clickHandler()}
    disabled={disabled}
    {...rest}
  >
    {children}
  </button>
);

export default ActionButton;
