import { FC } from 'react';
import { Space, Button, Row, Col } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const Buttons: FC = () => (
  <Space>
    <Button danger>Suh</Button>
    <Button disabled danger>Suh</Button>
    <Button ghost>Suh</Button>
    <Button type="primary" icon={<DownloadOutlined />}>Suh</Button>
    <Button type="default" size="middle">Suh</Button>
    <Button type="ghost">Suh</Button>
    <Button type="link">Suh</Button>
    <Button type="text">Suh</Button>
    <Button>Suh</Button>

    <Row>
      <Col span={24}>
        <Button block>Suh</Button>
      </Col>
    </Row>
  </Space>
);

export default Buttons;
