import { FC, useEffect, useState } from "react";
import styles from "./Login.module.scss";

import { useStores } from "../../hooks/useStores";

import LoginImage from "../../assets/images/Login-Image.jpg";
import ResetPassword from "./ResetPassword/ResetPassword";
import LoginForm from "../../components/Forms/LoginForm/LoginForm";

const Login: FC = () => {
  const { interfaceStore } = useStores();
  interfaceStore.setTheme("light");
  const [isResetPass, setIsResetPass] = useState<boolean>(false);

  useEffect(() => {
    const resetToken = new URLSearchParams(location.search).get("token");
    if (resetToken !== null) {
      setIsResetPass(true);
    }
  }, []);

  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <div className={styles.box}>
          <div className={styles.boxBody}>
            <img
              className={styles.image}
              src={LoginImage}
              alt="Woman Charging Car"
            />
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.boxBody}>
            {isResetPass === true ? <ResetPassword /> : <LoginForm />}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
