import { OfferTimeOptions } from "./types";

export const daysOptions = Array.from({ length: 30 }, (_, i) => i + 1);
export const hoursOptions = Array.from({ length: 24 }, (_, i) => i);
export const minutesOptions = Array.from({ length: 60 }, (_, i) => i);
export const secondsOptions = Array.from({ length: 60 }, (_, i) => i);

export const MIN_OFFER_DURATION_IN_DAYS = "1";

export const initialOfferDurationTime: OfferTimeOptions = {
  days: MIN_OFFER_DURATION_IN_DAYS,
  hours: "0",
  minutes: "0",
  seconds: "0",
};
