import React from 'react';
import PropTypes from 'prop-types';

const ErrorModalHeader = ({title}) => (
  <div className="error-modal__header">
    <div className="error-modal__icon" />

    <div className="error-modal__title">
      {title}
    </div>
  </div>
);

ErrorModalHeader.propTypes = {
  title: PropTypes.string,
};

export default ErrorModalHeader;
