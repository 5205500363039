import { Form, Input, Button } from "antd";
import { FC } from "react";

import styles from "./LoginPassForm.module.scss";
import { useTranslation } from "react-i18next";

interface ILoginPassInterface {
  handlePassSubmit: any;
  posting: any;
  setErrorState: any;
  onForgotClick: any;
  errorState: any;
  setEnteredPass: any;
}

const LoginPassForm: FC<ILoginPassInterface> = ({
  handlePassSubmit,
  posting,
  setErrorState,
  onForgotClick,
  errorState,
  setEnteredPass,
}) => {
  const [form] = Form.useForm();

  const { t } = useTranslation();

  return (
    <Form
      onFinish={handlePassSubmit}
      layout="vertical"
      form={form}
      style={{ width: "90%" }}
    >
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: t("login.passRule"),
          },
        ]}
      >
        <Input.Password
          style={{
            border: "1px solid #868686",
            color: "black",
            borderRadius: "6px",
            width: "100%",
          }}
          placeholder={t("login.password")}
          onChange={(e) => {
            setEnteredPass(e.target.value);
            setErrorState(false);
          }}
        />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={posting}
          block
          style={{ width: "100%" }}
        >
          {t("login.login")}
        </Button>
        {errorState && (
          <p className={styles.errorMessage}>{t("login.passProb")}</p>
        )}
      </Form.Item>

      <div className={styles.forgotPassContainer}>
        <p className={styles.forgotPassContainer_msg} onClick={onForgotClick}>
          {t("login.forgotPass")}
        </p>
      </div>
    </Form>
  );
};

export default LoginPassForm;
