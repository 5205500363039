import { FC, useState } from "react";
import "@ant-design/compatible/assets/index.css";
import { observer } from "mobx-react";

import styles from "./LoginForm.module.scss";
import Logo from "../../../assets/logos/VenueNowStacked.png";

import { useStores } from "../../../hooks/useStores";
import {
  useGetEmailStatus,
  usePostLogin,
  useResetPassRequest,
} from "../../../hooks/userHooks";
import {
  handleErrorModal,
  handleSuccessModal,
} from "../../../utils/modals/simpleModals";
import LoginMainForm from "./LoginComponents/LoginMainForm/LoginMainForm";
import LoginPassForm from "./LoginComponents/LoginPassForm/LoginPassForm";
import LoginSendPassLink from "./LoginComponents/LoginSendPassLink/LoginSendPassLink";
import LoginActivate from "./LoginComponents/LoginActivate/LoginActivate";
import LoginHeaders from "./LoginComponents/LoginHeaders/LoginHeaders";

const LoginForm: FC = () => {
  const [posting, setPosting] = useState(false);
  const [errorState, setErrorState] = useState<boolean>(false);
  const [runEndpoint, setRunEndpoint] = useState<boolean>(false);
  const [forgotPass, setForgotPass] = useState<boolean>(false);
  const [enteredEmail, setEnteredEmail] = useState<string>();
  const [enteredPass, setEnteredPass] = useState<string>();
  const [emailInput, setEmailInput] = useState<string>();

  const { userStore } = useStores();

  const userLogin = usePostLogin();
  const resetRequest = useResetPassRequest();

  const accountEmail = enteredEmail;

  const handleEmailSubmit = async () => {
    setEnteredEmail(emailInput);
    setRunEndpoint(true);
    setTimeout(() => {
      setRunEndpoint(false);
    }, 300);
  };

  const { data, error, isLoading } = useGetEmailStatus(
    accountEmail,
    runEndpoint
  );

  const handlePassSubmit = async () => {
    setPosting(true);

    const store = {
      username: enteredEmail,
      password: enteredPass,
    };

    userLogin.mutate(store, {
      onSuccess: (response: any) => {
        userStore.setAuth(response.data, store.username);
        userStore.rootStore.routerStore.push("/app");
        setErrorState(false);
      },
      onError: (e) => {
        setErrorState(true);
        console.log(e);
        setPosting(false);
      },
    });
  };

  const sendPassClick = async () => {
    setPosting(true);

    const resetRequestStore = {
      emailAddress: enteredEmail,
    };
    resetRequest.mutate(resetRequestStore, {
      onSuccess: () => {
        handleSuccessModal(
          "Success!",
          "You should recieve an email shortly. Follow the link and set up your password!"
        );
        setErrorState(false);
        setPosting(false);
      },
      onError: (e) => {
        handleErrorModal("Oops!", "Something went wrong. Please try again!");
        setErrorState(true);
        console.log(e);
        setPosting(false);
      },
    });
  };

  const onForgotClick = async () => {
    setForgotPass(true);
    sendPassClick();
  };

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img className={styles.imageContainer_img} src={Logo} alt="logo" />
      </div>

      <LoginHeaders data={data} forgotPass={forgotPass} />

      {!data && !forgotPass && (
        <>
          <LoginMainForm
            handleEmailSubmit={handleEmailSubmit}
            isLoading={isLoading}
            setEmailInput={setEmailInput}
            setErrorState={setErrorState}
            error={error}
          />
        </>
      )}

      {data?.isEnabled && data?.hasPassword && !forgotPass && (
        <>
          <LoginPassForm
            handlePassSubmit={handlePassSubmit}
            posting={posting}
            setErrorState={setErrorState}
            onForgotClick={onForgotClick}
            errorState={errorState}
            setEnteredPass={setEnteredPass}
          />
        </>
      )}

      {data?.isEnabled === true &&
        data?.hasPassword === false &&
        !forgotPass && (
          <LoginSendPassLink sendPassClick={sendPassClick} posting={posting} />
        )}

      {data?.isEnabled === false &&
        data?.hasPassword === false &&
        !forgotPass && <LoginActivate />}
    </div>
  );
};

export default observer(LoginForm);
