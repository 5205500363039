import { FC } from 'react';
import { Form, Input, Button, Radio, Select, Row, Col } from 'antd';
import Card from '../../../../components/Blocks/Card/Card';

const Forms: FC = () => {
  const { Option } = Select;
  const [form] = Form.useForm();

  return (
    <Form
      layout="vertical"
      form={form}
    >
      <Card>
        <Form.Item
          label="Form Layout"
          name="layout"
          rules={[
            {
              required: true,
              message: 'This is required'
            }
          ]}
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value="horizontal">Horizontal</Radio.Button>
            <Radio.Button value="vertical">Vertical</Radio.Button>
            <Radio.Button value="inline">Inline</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Card>

      <Card>
        <Form.Item
          label="Field A"
          name="fieldA"
          rules={[
            {
              required: true,
              message: 'This is required'
            }
          ]}
        >
          <Input placeholder="input placeholder" />
        </Form.Item>
      </Card>

      <Card>
        <Form.Item
          label="Field B"
          name="fieldB"
          rules={[
            {
              required: true,
              message: 'This is required'
            }
          ]}
        >
          <Input placeholder="input placeholder" />
        </Form.Item>
      </Card>

      <Card>
        <Form.Item label="Location">
          <Input.Group>
            <Row gutter={8}>
              <Col span={6}>
                <Form.Item
                  name={['address', 'province']}
                  rules={[
                    {
                      required: true, message: 'Province is required'
                    }
                  ]}
                >
                  <Select placeholder="Select province">
                    <Option value="Zhejiang">Zhejiang</Option>
                    <Option value="Jiangsu">Jiangsu</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={18}>
                <Form.Item
                  name={['address', 'street']}
                  rules={[
                    {
                      required: true, message: 'Street is required'
                    }
                  ]}
                >
                  <Input
                    placeholder="Input street"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
        </Form.Item>
      </Card>

      <Form.Item>
        <Button type="primary" onClick={() => form.validateFields()}>Submit</Button>
      </Form.Item>
    </Form>
  );
};

export default Forms;
