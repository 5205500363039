import PropTypes from "prop-types";
import { DatePicker } from "antd";

import disablePast from "../../utils/dateTime/disableDatesPast";
import disableFuture from "../../utils/dateTime/disableDatesFuture";
import { useTranslation } from "react-i18next";
import defaultDates from "../../utils/dateTime/defaultDates";

const DateInput = (props: any) => {
  const isForm = props.type === "form";
  const placeholder = props.placeholder || [" ", " "];
  const styleClass = isForm
    ? `DateInput DateInput--form d-block h-100 w-100 ${props.className}`
    : `DateInput DateInput--chart`;

  const { t } = useTranslation();

  const last7Days = t("dateRanges.last7Days");
  const last14Days = t("dateRanges.last14Days");
  const last21Days = t("dateRanges.last21Days");
  const lastMonth = t("dateRanges.lastMonth");
  const last3Months = t("dateRanges.last3Months");
  const allTime = t("dateRanges.allTime");

  const presetDates = {
    allTime: [defaultDates.start_of_time, defaultDates.today],
    default: [defaultDates.seven_days_ago, defaultDates.today],
    ranges: {
      [last7Days]: [defaultDates.seven_days_ago, defaultDates.today],
      [last14Days]: [defaultDates.fourteen_days_ago, defaultDates.today],
      [last21Days]: [defaultDates.twentyone_days_ago, defaultDates.today],
      [lastMonth]: [defaultDates.thirtyone_days_ago, defaultDates.today],
      [last3Months]: [defaultDates.ninetyone_days_ago, defaultDates.today],
      [allTime]: [defaultDates.start_of_time, defaultDates.today],
    },
  };

  return (
    <DatePicker.RangePicker
      disabledDate={isForm ? disablePast : disableFuture}
      className={styleClass}
      allowClear={false}
      ranges={!isForm ? presetDates.ranges : false}
      format="YYYY-MM-DD"
      placeholder={placeholder}
      {...props}
      suffixIcon={null}
    />
  );
};

DateInput.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  allowClear: PropTypes.bool,
  value: PropTypes.array,
  ranges: PropTypes.object,
  format: PropTypes.string,
  disabledDate: PropTypes.func,
  onChange: PropTypes.func,
  placeHolder: PropTypes.string,
};

export default DateInput;
