import { FC, useState } from "react";
import { Button, Divider, Form, Input, Space } from "antd";
import styles from "./AddUser.module.scss";

import SectionHeader from "../../../components/Blocks/SectionHeader/SectionHeader";
import Card from "../../../components/Blocks/Card/Card";
import { useStores } from "../../../hooks/useStores";
import { useAddNewUser } from "../../../hooks/userHooks";
import { handleSuccessModal } from "../../../utils/modals/simpleModals";

const AddUser: FC = () => {
  const { interfaceStore } = useStores();
  const [form] = Form.useForm();

  const [currentValues, setCurrentValues] = useState<any>();

  const addUser = useAddNewUser();

  const onFinish = async () => {
    form.validateFields();

    addUser.mutate(currentValues, {
      onSuccess: () => {
        handleSuccessModal(
          "Success!",
          "A password generation link has been sent to the new user"
        );
      },
      onError: (e) => {
        console.log(e);
      },
    });
  };

  const messageContClass =
    interfaceStore.theme === "light"
      ? styles.messageContainerLight
      : styles.messageContainer;
  const messageClass =
    interfaceStore.theme === "light"
      ? styles.messageContainerLight_msg
      : styles.messageContainer_msg;

  return (
    <Space direction="vertical">
      <header className={styles.header}>
        <SectionHeader>
          Add an additional user to this business account
        </SectionHeader>
      </header>

      <Divider />

      <Form layout="vertical" onFinish={onFinish}>
        <Card>
          <Form.Item
            name="emailAddress"
            label="Email Address"
            rules={[
              {
                required: true,
                message: "Email Address is required",
              },
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
            ]}
          >
            <Input
              placeholder="Required"
              onChange={(e) =>
                setCurrentValues({
                  ...currentValues,
                  email: e.target.value,
                })
              }
            />
          </Form.Item>
        </Card>
        <Card>
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[
              {
                required: true,
                message: "First Name is required",
              },
            ]}
          >
            <Input
              placeholder="Required"
              onChange={(e) =>
                setCurrentValues({
                  ...currentValues,
                  firstName: e.target.value,
                })
              }
            />
          </Form.Item>
        </Card>
        <Card>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[
              {
                required: true,
                message: "Last Name is required",
              },
            ]}
          >
            <Input
              placeholder="Required"
              onChange={(e) =>
                setCurrentValues({
                  ...currentValues,
                  lastName: e.target.value,
                })
              }
            />
          </Form.Item>
        </Card>
        <Card>
          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: "Phone Number is required",
              },
            ]}
          >
            <Input
              placeholder="Required"
              onChange={(e) =>
                setCurrentValues({
                  ...currentValues,
                  phoneNumber: e.target.value,
                })
              }
            />
          </Form.Item>
        </Card>

        <div className={messageContClass}>
          <p className={messageClass}>
            Once you submit, a password generation link will be sent to the new
            users email address as entered above. Your new user can then follow
            the link and create their password, giving them access to the
            dashboard!
          </p>
        </div>

        <Button
          type="primary"
          htmlType="submit"
          style={{ paddingLeft: "10px", paddingRight: "10px", width: "100%" }}
        >
          SUBMIT
        </Button>
      </Form>
    </Space>
  );
};

export default AddUser;
