import { FC } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { Menu } from "antd";
import { QrcodeOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";

const ManageOffersSubmenu: FC = (props) => {
  const { t } = useTranslation();

  return (
    <Menu.SubMenu
      title={
        <span>
          <QrcodeOutlined />

          <span>{t("sideMenu.offers")}</span>
        </span>
      }
      {...props}
    >
      <Menu.Item key="/app/manage/offer/create">
        <Link to="/app/manage/offer/create">
          <span className="nav-text">{t("sideMenu.createOffer")}</span>
        </Link>
      </Menu.Item>

      <Menu.Item key="/app/manage/offers/current">
        <Link to="/app/manage/offers/current">
          <span className="nav-text">{t("sideMenu.currentOffers")}</span>
        </Link>
      </Menu.Item>

      <Menu.Item key="/app/manage/offer/send">
        <Link to="/app/manage/offer/send">
          <span className="nav-text">{t("sideMenu.sendOffers")}</span>
        </Link>
      </Menu.Item>

      <Menu.Item key="/app/manage/offer/instant">
        <Link to="/app/manage/offer/instant">
          <span className="nav-text">{t("sideMenu.instantOffer")}</span>
        </Link>
      </Menu.Item>

      <Menu.Item key="/app/manage/offer/archive">
        <Link to="/app/manage/offer/archive">
          <span className="nav-text">{t("sideMenu.archivedOffers")}</span>
        </Link>
      </Menu.Item>
    </Menu.SubMenu>
  );
};

export default observer(ManageOffersSubmenu);
