/* eslint-disable lines-between-class-members */
import { makeAutoObservable } from "mobx";
import { MainRootStore } from "..";
import { OfferTimeOptions } from "../../components/Forms/QcCampaignWizard/QcCampaignPages/QcCampaignOfferExpiry/utils/types";
import { PreviewOfferStoreDefaultValues } from "./defaultValues";

class PreviewOfferStore {
  campaignName: string;
  campaignImage: string;
  previewOffer: string;
  notificationHeader: string;
  notificationMessage: string;
  terms: string;
  expiryDate: string;
  offerExpiryDate: OfferTimeOptions;
  rootStore: MainRootStore;

  constructor(rootStore: MainRootStore) {
    const {
      campaignName,
      campaignImage,
      previewOffer,
      notificationHeader,
      notificationMessage,
      terms,
      expiryDate,
      offerExpiryDate,
    } = PreviewOfferStoreDefaultValues;

    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.campaignName = campaignName;
    this.campaignImage = campaignImage;
    this.previewOffer = previewOffer;
    this.notificationHeader = notificationHeader;
    this.notificationMessage = notificationMessage;
    this.terms = terms;
    this.expiryDate = expiryDate;
    this.offerExpiryDate = offerExpiryDate;
  }

  setCampaignName = (
    campaignName = PreviewOfferStoreDefaultValues.campaignName
  ): void => {
    this.campaignName = campaignName;
  };

  setNotificationHeader = (
    notificationHeader = PreviewOfferStoreDefaultValues.notificationHeader
  ): void => {
    this.notificationHeader = notificationHeader;
  };

  setNotificationMessage = (
    notificationMessage = PreviewOfferStoreDefaultValues.notificationMessage
  ): void => {
    this.notificationMessage = notificationMessage;
  };

  setTerms = (terms = PreviewOfferStoreDefaultValues.terms): void => {
    this.terms = terms;
  };

  setExpiryDate = (
    expiryDate = PreviewOfferStoreDefaultValues.expiryDate
  ): void => {
    this.expiryDate = expiryDate;
  };

  setOfferExpiryDate = (name: string, timeUnit: string) => {
    this.offerExpiryDate[name] = timeUnit;
  };

  setPreviewOffer = (
    previewOffer = PreviewOfferStoreDefaultValues.previewOffer
  ): void => {
    this.previewOffer = previewOffer;
  };

  setCampaignImage = (
    campaignImage = PreviewOfferStoreDefaultValues.campaignImage
  ): void => {
    this.campaignImage = campaignImage;
  };

  clearOfferPreview = () => {
    this.campaignName = PreviewOfferStoreDefaultValues.campaignName;
    this.campaignImage = PreviewOfferStoreDefaultValues.campaignImage;
    this.previewOffer = PreviewOfferStoreDefaultValues.previewOffer;
    this.notificationHeader = PreviewOfferStoreDefaultValues.notificationHeader;
    this.notificationMessage =
      PreviewOfferStoreDefaultValues.notificationMessage;
    this.terms = PreviewOfferStoreDefaultValues.terms;
    this.expiryDate = PreviewOfferStoreDefaultValues.expiryDate;
    this.offerExpiryDate = PreviewOfferStoreDefaultValues.offerExpiryDate;
  };
}

export default PreviewOfferStore;
