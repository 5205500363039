import loadable from '@loadable/component';

const SalesHome = loadable(() => import('../pages/sales/SalesHome/SalesHome'));
const SalesCreatePromo = loadable(() => import('../pages/sales/SalesCreatePromo/SalesCreatePromo'));
const SalesAddBusiness = loadable(() => import('../pages/sales/SalesAddBusiness/SalesAddBusiness'))

const LiveMap = loadable(() => import('../pages/sales/SalesAnalytics/LiveMap/LiveMap'));

const BusinessAnalytics = loadable(() => import("../pages/sales/SalesAnalytics/BusinessAnalytics/BusinessAnalytics"));
const ActiveStores = loadable(() => import("../pages/sales/SalesAnalytics/BusinessStatPages/ActiveStores"));
const OffersSent = loadable(() => import("../pages/sales/SalesAnalytics/BusinessStatPages/OffersSent"));
const NewAccounts = loadable(() => import("../pages/sales/SalesAnalytics/BusinessStatPages/NewAccounts"));
const ActiveCampaigns = loadable(() => import("../pages/sales/SalesAnalytics/BusinessStatPages/ActiveCampaigns"));
const RegisteredAccounts = loadable(() => import("../pages/sales/SalesAnalytics/BusinessStatPages/RegisteredAccounts"));
const InactiveCampaigns = loadable(() => import("../pages/sales/SalesAnalytics/BusinessStatPages/InactiveCampaigns"));


export const salesRoutes = [
  { path: '/app', component: SalesHome },

  { path: '/app/tools/create-promo', component: SalesCreatePromo },
  { path: '/app/tools/add-business', component: SalesAddBusiness },

  { path: "/app/analytics/business/business-stats", component: BusinessAnalytics },
  { path: "/app/analytics/business/business-stats/active-stores", component: ActiveStores },
  { path: "/app/analytics/business/business-stats/offers-sent", component: OffersSent },
  { path: "/app/analytics/business/business-stats/new-accounts", component: NewAccounts },
  { path: "/app/analytics/business/business-stats/active-campaigns", component: ActiveCampaigns },
  { path: "/app/analytics/business/business-stats/registered-accounts", component: RegisteredAccounts },
  { path: "/app/analytics/business/business-stats/inactive-campaigns", component: InactiveCampaigns },
  { path: '/app/analytics/business/store-map', component: LiveMap },


];