/* eslint-disable no-shadow */
import { useMutation, useQuery, useQueryClient } from 'react-query';
import _ from 'lodash';

import { addNewUser, confirmNewPass, getEmailStatus, postLogin, resetPassRequest } from '../../utils/API/user/user';


export enum EStoreKey {
    USER_LOGIN = 'user-login',
    CHECK_EMAIL = 'check-email',
    REQUEST_RESET = 'reset-pass',
    CONFIRM_PASS = 'confirm-pass',
    NEW_USER = 'new-user'
}

export const useGetEmailStatus = (
  accountEmail: any,
  runEndpoint: boolean
) => useQuery([EStoreKey.CHECK_EMAIL, accountEmail], () => getEmailStatus(accountEmail), {
  keepPreviousData: true,
  enabled: runEndpoint === true
}, );


// ----------- Queries ------------- //

export const usePostLogin = () => {
  const queryClient = useQueryClient();

  return useMutation(postLogin, {
    onSuccess: () => {
      queryClient.invalidateQueries(EStoreKey.USER_LOGIN);
    }
 
  });
};
export const useResetPassRequest = () => {
  const queryClient = useQueryClient();

  return useMutation(resetPassRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(EStoreKey.REQUEST_RESET);
    }
 
  });
};
export const useConfirmNewPass = () => {
  const queryClient = useQueryClient();

  return useMutation(confirmNewPass, {
    onSuccess: () => {
      queryClient.invalidateQueries(EStoreKey.CONFIRM_PASS);
    }
 
  });
};
export const useAddNewUser = () => {
  const queryClient = useQueryClient();

  return useMutation(addNewUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(EStoreKey.NEW_USER);
    }
 
  });
};




