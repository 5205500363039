export const transformCampaignConversionData = data => {
  const transformedData = data.map(campaign => ({
    ...campaign,
    name: (campaign.campaignName.charAt(0).toUpperCase() + campaign.campaignName.slice(1))
      .split('_')
      .join(' '),
    type: (campaign.campaignType.charAt(0).toUpperCase() + campaign.campaignType.slice(1))
      .split('_')
      .join(' '),
    percentage: `${(campaign.conversion * 100).toFixed(2)}%`,
    key: campaign.campaignId
  }));

  return transformedData;
};
