import { FC } from 'react';
import { Space, Switch } from 'antd';

const Switches: FC = () => (
  <Space size={16}>
    <Switch />

    <Switch disabled />

    <Switch loading />

    <Switch loading defaultChecked />
  </Space>
);

export default Switches;
