import { FC, ReactNode} from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/useStores';
import { adminRoutes } from '../adminRoutes';
import { qcRoutes } from '../qcRoutes';
import { mapRoutes } from './RouteSwitchHelpers';
// import { qcUnpaidRoutes } from '../qcUnpaidRoutes';
// import { useGetBusinessUserStatus } from '../../hooks/registrationHooks/registration.hooks';
import { salesRoutes } from '../salesRoutes';
import { qcTempRoutes } from '../qcTempRoutes';

const RouteSwitch: FC = () => {
  const { userStore } = useStores();
  // const [isBusinessUser, setIsBusinessUser] = useState<boolean>(false);

  // const userId = userStore.user?.userId;

  // const { data } = useGetBusinessUserStatus(
  //   userId, isBusinessUser
  // );


  // useEffect(() => {
  //   if (userStore.user?.roleId === 3) {
  //     setIsBusinessUser(true);
  //   } else {
  //     setIsBusinessUser(false);
  //   }
  // }, [userStore.user?.roleId]);

    if (userStore.user?.username === 'undefined') {
    localStorage.clear();
    location.reload();
  }


  let routes: ReactNode[] | null = null;


    switch (userStore.roleId) {
      case 1:
        routes = mapRoutes(adminRoutes);
        break;
      case 3:
        if (userStore.legitBusinessAcc === false) {
          routes = mapRoutes(qcTempRoutes);
          break;
        } else  {
          routes = mapRoutes(qcRoutes);
          break;
        }
      case 4:
        routes = mapRoutes(adminRoutes);
        break;
      case 5:
        routes = mapRoutes(salesRoutes);
        break;

      default:
        break;
    }


  return (
    <>
      {routes}
    </>
  );
};

export default observer(RouteSwitch);
