import { FC } from 'react';
import { Radio } from 'antd';

import Card from '../../../../components/Blocks/Card/Card';

const Radios: FC = () => (

  <Card>
    <Radio.Group defaultValue="c" buttonStyle="solid">
      <Radio.Button value="a">Riga</Radio.Button>
      <Radio.Button value="b" disabled>Shanghai</Radio.Button>
      <Radio.Button value="c" disabled>Beijing</Radio.Button>
      <Radio.Button value="d">Chengdu</Radio.Button>
    </Radio.Group>
  </Card>

);

export default Radios;
