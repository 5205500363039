import { FC, useState } from "react";
import "@ant-design/compatible/assets/index.css";
import { Form, Button, Input, Space, Row, Col } from "antd";
import { observer } from "mobx-react";

import styles from "./ResetPassword.module.scss";
import Logo from "../../../assets/logos/pebble-logo_colour+white.png";

import { useStores } from "../../../hooks/useStores";
import { useConfirmNewPass } from "../../../hooks/userHooks";
import { handleSuccessModal } from "../../../utils/modals/simpleModals";
import { useTranslation } from "react-i18next";

const ResetPassword: FC = () => {
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState<boolean>(false);
  const [passwordInput, setPasswordInput] = useState<string>();

  const [form] = Form.useForm();
  const { userStore } = useStores();

  const { t } = useTranslation();

  const resetToken = new URLSearchParams(location.search).get("token");

  const confirmPass = useConfirmNewPass();

  const handlePassSubmit = async () => {
    setLoading(true);

    const passConfirmStore = {
      newPassword: passwordInput,
      token: resetToken,
    };
    confirmPass.mutate(passConfirmStore, {
      onSuccess: () => {
        handleSuccessModal(
          `${t("login.success")}`,
          `${t("login.passCreated")}`
        );
        setTimeout(async () => {
          userStore.rootStore.routerStore.push("/app");
          window.location.reload();
        }, 400);
        setErrorState(false);
      },
      onError: (e) => {
        setErrorState(true);
        console.log(e);
        setLoading(false);
      },
    });
  };

  return (
    <Space size={16} direction="vertical">
      <div className={styles.imageContainer}>
        <img className={styles.imageContainer_img} src={Logo} alt="logo" />
      </div>
      <h2 className={styles.title}>{t("login.createPass")}</h2>
      <div className={styles.messageContainer}>
        <p className={styles.messageContainer_message}>
          {t("login.createPassEntry")}
        </p>
      </div>

      <Form onFinish={handlePassSubmit} layout="vertical" form={form}>
        <Form.Item
          name="password"
          label={t("login.password")}
          rules={[
            {
              required: true,
              message: t("login.passRule"),
            },

            {
              pattern: new RegExp(
                /^(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{10,}$/
              ),
              message: t("login.passValidation"),
            },
          ]}
          hasFeedback
        >
          <Input.Password
            style={{
              border: "1px solid #868686",
              color: "black",
              borderRadius: "6px",
            }}
            placeholder={t("login.passPlaceholder")}
            onChange={(e: any) => setPasswordInput(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          name="confirm"
          label={t("login.confirmPass")}
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: t("login.confirmPassRule"),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t("login.passNoMatch")));
              },
            }),
          ]}
        >
          <Input.Password
            style={{
              border: "1px solid #868686",
              color: "black",
              borderRadius: "6px",
            }}
          />
        </Form.Item>

        <Form.Item>
          <Row>
            <Col span={24}>
              <Button
                type="primary"
                style={{ width: "100%" }}
                htmlType="submit"
                loading={loading}
                block
              >
                {t("login.createPassword")}
              </Button>
              {errorState && (
                <p className={styles.errorMessage}>
                  {t("login.createPassProblem")}
                </p>
              )}
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Space>
  );
};

export default observer(ResetPassword);
