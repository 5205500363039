/* eslint-disable operator-linebreak */
import { useEffect, FC } from "react";
import { observer } from "mobx-react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { useStores } from "./hooks/useStores";
import Login from "./pages/core/Login";
import LayoutContainer from "./layouts/LayoutContainer/LayoutContainer";
import RouteSwitch from "./routes/RouteSwitch/RouteSwitch";
import Styleguide from "./pages/core/Styleguide/Styleguide";
import { usePostLoginTime } from "./hooks/registrationHooks/registration.hooks";
import UpdatePassword from "./pages/core/UpdatePassword/UpdatePassword";
import UpdateDetails from "./pages/core/UpdateDetails/UpdateDetails";
import AddUser from "./pages/core/AddUser/AddUser";
import i18n from "./i18n";
import { ConfigProvider } from "antd";
import enUS from "antd/es/locale/en_US";
import deDE from "antd/es/locale/de_DE";
import i18next from "i18next";
import Loader from "./components/Blocks/LoaderFull";
import { jwtDecode } from "jwt-decode";

const App: FC = () => {
  const { userStore, routerStore, interfaceStore, configStore } = useStores();
  const { hasSession } = userStore;
  const {
    history: {
      location: { pathname },
    },
    push,
  } = routerStore;

  const postLoginSession = usePostLoginTime();

  const decodeJwt = async () => {
    const token = userStore.accessToken;

    if (token !== null) {
      try {
        const decodedJwt = jwtDecode(token as any);
        //@ts-ignore
        const lba = decodedJwt?.lba;
        //@ts-ignore
        const roleId = decodedJwt?.rid;

        userStore.setRoleId(roleId);

        if (lba === null) {
          userStore.setLegitBusinessAcc(false);
        } else if (lba !== null) {
          userStore.setLegitBusinessAcc(true);
        }
      } catch (error) {
        console.error("JWT verification failed:", error);
      }
    }
  };

  useEffect(() => {
    const userLocale = navigator.language || "en";

    i18next.init({
      lng: userLocale,
    });
  }, []);

  useEffect(() => {
    interfaceStore.setTheme();

    if (hasSession && !userStore.client) {
      userStore.me();
      postLoginSession.mutate();

      decodeJwt();
    }
  }, [hasSession, userStore, interfaceStore]);

  useEffect(() => {
    if (userStore.client) {
      configStore.setLocation();
    }
  }, [userStore.client, configStore]);

  useEffect(() => {
    if (pathname === "/") {
      push(!hasSession ? "/login" : "/app");
    }
  }, [pathname, hasSession, push]);

  const LoginGuard = hasSession ? <Redirect to="/app" /> : <Login />;

  if (
    configStore.loading ||
    userStore.loading ||
    (hasSession && !userStore.user?.roleId)
  ) {
    return <Loader theme={interfaceStore.theme} />;
  }

  // ---------- Catching an ignorable runtime error triggered by language switching --------
  window.onerror = function (err) {
    if (
      err === "ResizeObserver loop completed with undelivered notifications."
    ) {
      console.warn(
        "Ignored: ResizeObserver loop completed with undelivered notifications"
      );
      const resizeObserverErrDiv = document.getElementById(
        "webpack-dev-server-client-overlay-div"
      );
      const resizeObserverErr = document.getElementById(
        "webpack-dev-server-client-overlay"
      );
      if (resizeObserverErr) {
        resizeObserverErr.setAttribute("style", "display: none");
      }
      if (resizeObserverErrDiv) {
        resizeObserverErrDiv.setAttribute("style", "display: none");
      }
    }
  };
  // ---------------------------------------------------------------------------------------

  return (
    <ConfigProvider locale={i18n.language === "de" ? deDE : enUS}>
      <Router history={routerStore.history}>
        <LayoutContainer isLoggedIn={!!hasSession}>
          <Switch>
            <Route path="/login" exact component={() => LoginGuard} />
            <Route path="/styleguide" exact component={Styleguide} />
            <Route path="/update-password" exact component={UpdatePassword} />
            <Route path="/edit-details" exact component={UpdateDetails} />
            <Route path="/add-user" exact component={AddUser} />

            <RouteSwitch />

            <Route component={() => <Redirect to="/app" />} />
          </Switch>
        </LayoutContainer>
      </Router>
    </ConfigProvider>
  );
};

export default observer(App);
