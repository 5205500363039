import { FC } from "react";
import { observer } from "mobx-react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";

import AnalyticsAppSubmenu from "../Submenus/Analytics/App/AnalyticsAppSubmenu";
import AnalyticsCampaignSubmenu from "../Submenus/Analytics/Campaign/AnalyticsCampaignSubmenu";
import AnalyticsPoiSubmenu from "../Submenus/Analytics/POI/AnalyticsPoiSubmenu";

import ManageOffersSubmenu from "../Submenus/Manage/Offers/ManageOffersSubmenu";
import ManageCampaignsSubmenu from "../Submenus/Manage/Campaigns/ManageCampaignsSubmenu";
// import ManageLocationsSubmenu from "../Submenus/Manage/Locations/ManageLocationsSubmenu";
// import ManageEvSubmenu from "../Submenus/Manage/EV/ManageEvSubmenu";
import { useStores } from "../../../hooks/useStores";
import styles from "./QsrSidebar.module.scss";
import AnalyticsEvSubmenu from "../Submenus/Analytics/EV/AnalyticsEvSubmenu";
import AnalyticsBusinessSubMenu from "../Submenus/Analytics/Business/AnalyticsBusinessSubMenu";
import { useTranslation } from "react-i18next";
import WifiSubmenu from "../Submenus/Wifi/WifiSubmenu";

const QsrSidebar: FC = () => {
  const { interfaceStore } = useStores();

  const { t } = useTranslation();

  return (
    <Menu mode="inline">
      <Menu.Item key="/app">
        <Link to="/app">
          <HomeOutlined />

          <span className="nav-text">{t("sideMenu.home")}</span>
        </Link>
      </Menu.Item>

      <Menu.ItemGroup
        title={
          interfaceStore.navActive ? (
            t("sideMenu.analytics")
          ) : (
            <div className={styles.sidebarTitle}>A</div>
          )
        }
      >
        <AnalyticsAppSubmenu key="app-data-submenu" />

        <WifiSubmenu key="wifi-submenu" />

        <AnalyticsCampaignSubmenu key="campaign-data-submenu" />

        <AnalyticsPoiSubmenu key="poi-data-submenu" />

        <AnalyticsEvSubmenu key="ev-submenu" />

        <AnalyticsBusinessSubMenu key="business-submenu" />
      </Menu.ItemGroup>

      <Menu.ItemGroup
        title={
          interfaceStore.navActive ? (
            t("sideMenu.manageBrand")
          ) : (
            <div className={styles.sidebarTitle}>M</div>
          )
        }
      >
        <ManageOffersSubmenu key="offer-management-submenu" />

        <ManageCampaignsSubmenu key="campaign-management-submenu" />

        {/* <ManageLocationsSubmenu key="location-management-submenu" /> */}

        {/* <ManageEvSubmenu key="ev-management-submenu" /> */}
      </Menu.ItemGroup>
    </Menu>
  );
};

export default observer(QsrSidebar);
